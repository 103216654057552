import { frontendURL } from '../../../../helper/URLHelper';

const messageTemplates = (accountId) => ({
  parentNav: 'messageTemplates',
  routes: [
    'whatsapp_message_templates',
    'sms_message_templates',
    'all_scheduled_message_templates',
  ],
  menuItems: [
    {
      icon: 'mail-read',
      label: 'CHATLYN_MESSAGE_TEMPLATE.TITLE.WHATSAPP',
      key: 'all_message_templates',
      toState: frontendURL(`accounts/${accountId}/message-templates/whatsapp`),
      toStateName: 'whatsapp_message_templates',
    },
    {
      icon: 'mail-read',
      label: 'CHATLYN_MESSAGE_TEMPLATE.TITLE.SMS',
      key: 'sms_message_templates',
      toState: frontendURL(`accounts/${accountId}/message-templates/sms`),
      toStateName: 'sms_message_templates',
    },
    {
      icon: 'send-clock',
      label: 'CHATLYN_MESSAGE_TEMPLATE.SCHEDULED_TITLE',
      key: 'all_scheduled_message_templates',
      toState: frontendURL(`accounts/${accountId}/scheduled-message-templates`),
      toStateName: 'all_scheduled_message_templates',
    },
  ],
});

export default messageTemplates;
