<template>
  <formulate-form #default="{hasErrors}" @submit="handleFormSubmit">
    <div class="we-chat-inbox-body flex flex-col gap-3">
      <formulate-input
        v-if="!editMode"
        v-model="newInbox.name"
        :label="$t('CHATLYN_INBOXES.WE_CHAT.SETUP.INBOX_NAME')"
        validation="required"
        type="text"
      />

      <formulate-input
        v-model="newInbox.token"
        :label="$t('CHATLYN_INBOXES.WE_CHAT.SETUP.TOKEN')"
        validation="required"
        type="text"
      />

      <formulate-input
        v-model="newInbox.weixinId"
        :label="$t('CHATLYN_INBOXES.WE_CHAT.SETUP.WEIXIN_ID')"
        validation="required"
        type="text"
      />

      <formulate-input
        v-model="newInbox.appId"
        :label="$t('CHATLYN_INBOXES.WE_CHAT.SETUP.APP_ID')"
        validation="required"
        type="text"
      />

      <formulate-input
        v-model="newInbox.appSecret"
        :label="$t('CHATLYN_INBOXES.WE_CHAT.SETUP.APP_SECRET_KEY')"
        validation="required"
        type="password"
        autocomplete="new-password"
      />
    </div>

    <div class="we-chat-inbox-footer mt-5">
      <woot-button :disabled="hasErrors">
        {{
          $t(
            `CHATLYN_INBOXES.WE_CHAT.SETUP.${
              editMode ? 'UPDATE_INBOX' : 'CREATE_INBOX'
            }`
          )
        }}
      </woot-button>
    </div>
  </formulate-form>
</template>

<script>
export default {
  props: {
    inbox: {
      type: [Object, null],
      default: null,
    },

    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newInbox: {
        token: '',
        appId: '',
        appSecret: '',
        weixinId: '',
        name: '',
      },
    };
  },
  watch: {
    inbox: {
      handler(newValue) {
        if (!newValue || !Object.keys(newValue).length) {
          return;
        }

        this.newInbox = JSON.parse(JSON.stringify(newValue));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFormSubmit() {
      this.$emit('handle-form-submit', this.newInbox);
    },
  },
};
</script>

<style></style>
