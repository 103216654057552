<template>
  <div class="flex flex-row flex-nowrap items-start gap-2">
    <input-counter class="flex-1" :value="name" :max-length="11">
      <formulate-input
        v-model="name"
        :label="$t('CHATLYN_SMS_SENDING.FORM.SENDER_NAME.LABEL')"
        :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.NAME')"
        type="text"
        :validation="`required|max:${NAME_MAX_LENGTH}|matches:/^(?!\\s*$)[a-zA-Z0-9 ]*$/`"
        :validation-messages="{
          matches: $t('CHATLYN_SMS_SENDING.FORM.SENDER_NAME.ERROR.NOT_MATCHED'),
          max: $t('CHATLYN_GENERAL.MESSAGES.MAX_TEXT', {
            MAX: NAME_MAX_LENGTH,
          }),
        }"
        :maxlength="NAME_MAX_LENGTH"
      />
    </input-counter>

    <woot-button
      :is-disabled="isRemoveButtonDisabled"
      type="button"
      icon="dismiss"
      variant="clear"
      color-scheme="secondary"
      class="mt-6"
      @click="removeShortName(shortName.id)"
    />
  </div>
</template>

<script>
import InputCounter from '../../../../../components/ui/InputCounter.vue';

const NAME_MAX_LENGTH = 11;

export default {
  name: 'ShortNameEditor',

  components: {
    InputCounter,
  },

  props: {
    shortName: {
      type: Object,
      required: true,
    },

    isRemoveButtonDisabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return { NAME_MAX_LENGTH };
  },

  computed: {
    name: {
      get() {
        return this.shortName.name;
      },

      set(newValue) {
        this.changeShortName(newValue);
      },
    },
  },

  methods: {
    changeShortName(newValue) {
      this.$emit('change-short-name', { ...this.shortName, name: newValue });
    },

    removeShortName(id) {
      this.$emit('remove-short-name', id);
    },
  },
};
</script>

<style></style>
