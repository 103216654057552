<template>
  <div class="flex h-full min-h-0 flex-grow flex-col">
    <div
      v-if="isEdit"
      class="edit-credential-title flex flex-shrink-0 items-center gap-3 px-8 py-4"
    >
      <div class="status-indicator h-4 w-4" :class="credentialStatusColor" />
      <span class="text-2xl">{{ credentialTitle }}</span>
    </div>
    <woot-loading-state
      v-if="uiFlags.isFetching"
      class="mx-auto"
      :message="$t('CHATLYN_CREDENTIALS.LOADING')"
    />
    <div
      v-else
      class="edit-credential-content flex flex-grow overflow-y-auto bg-white dark:bg-gray-800"
    >
      <div class="chatlyn-container grid w-full gap-8 md:grid-cols-3">
        <div class="col-span-1 flex flex-col">
          <div class="edit-credential-subtitle text-woot-500">
            {{ pageSubtitle }}
          </div>
          <p>{{ pageDescription }}</p>
        </div>
        <formulate-form
          v-slot="{ hasErrors }"
          name="credentials"
          class="edit-credential-form col-span-2 flex flex-col gap-2"
          @submit="onFormSubmit"
        >
          <formulate-input
            v-model="credentialName"
            :label="$t('CHATLYN_CREDENTIALS.LABELS.NAME')"
            validation="required"
          />
          <formulate-input
            v-model="credentialType"
            :label="$t('CHATLYN_CREDENTIALS.LABELS.AUTH_TYPE')"
            :options="authTypes"
            type="select"
            validation="required"
          />
          <credential-edit-o-auth
            v-if="isOAuth"
            :config="oauth2"
            @update-oauth="updateOAuth"
          />
          <credential-edit-api-key
            v-if="isApiKey || isMews"
            :config="apiKeyConfig"
            :is-mews="isMews"
            @update-api-key="updateAPIKey"
          />
          <div v-if="isPasswordName" class="edit-credential-form-block">
            <formulate-input
              v-model="username"
              :label="$t('CHATLYN_CREDENTIALS.LABELS.USERNAME')"
              name="username"
              validation="required"
            />
            <formulate-input
              v-model="password"
              :label="$t('CHATLYN_CREDENTIALS.LABELS.PASSWORD')"
              name="password"
              type="password"
              validation="required|min:10,length"
            />
          </div>
          <div v-if="isBearer" class="edit-credential-form-block">
            <formulate-input
              v-model="bearer.token"
              :label="$t('CHATLYN_CREDENTIALS.LABELS.TOKEN')"
              autocomplete="new-password"
              type="password"
              name="token"
              validation="required"
            />
            <formulate-input
              v-model="bearer.prefix"
              :label="$t('CHATLYN_CREDENTIALS.LABELS.PREFIX')"
              :placeholder="$t('CHATLYN_CREDENTIALS.PLACEHOLDERS.PREFIX')"
              name="token-prefix"
            />
          </div>
          <div class="mt-3 flex gap-3 pb-8">
            <woot-button
              v-if="isConnectedButton"
              color-scheme="success"
              icon="rocket"
              type="button"
            >
              {{ $t('CHATLYN_CREDENTIALS.BUTTONS.CONNECTED') }}
            </woot-button>
            <woot-button
              v-else
              :disabled="hasErrors || !isDirty"
              :is-loading="uiFlags.isCreating || uiFlags.isUpdating"
              icon="rocket"
            >
              {{ saveButtonText }}
            </woot-button>
            <woot-button
              v-if="isEdit"
              color-scheme="alert"
              icon="delete"
              type="button"
              variant="smooth"
              @click="openDeleteConfirmation(credentialId)"
            >
              {{ $t('CHATLYN_GENERAL.BUTTON.DELETE') }}
            </woot-button>
          </div>
        </formulate-form>
      </div>
    </div>
    <woot-delete-modal
      :show.sync="showDeleteConfirmation"
      :title="$t('CHATLYN_CREDENTIALS.DELETE_CONFIRMATION.TITLE')"
      :message="$t('CHATLYN_CREDENTIALS.DELETE_CONFIRMATION.MESSAGE')"
      :confirm-text="$t('CHATLYN_GENERAL.BUTTON.DELETE')"
      :reject-text="$t('CHATLYN_GENERAL.BUTTON.CANCEL')"
      :on-close="closeDeleteConfirmation"
      :on-confirm="onConfirmDeletion"
    />
  </div>
</template>

<script>
import CredentialEditOAuth from './CredentialEditOAuth.vue';
import CredentialEditApiKey from './CredentialEditApiKey.vue';
import credentialActions from '../mixins/credentialActions';
import {
  API_KEY_INIT,
  PASSWORD_NAME_INIT,
  BEARER_INIT,
  OAUTH2_INIT,
  RESET_TO_INIT,
  MEWS_INIT,
} from '../utils/constants';
import WootButton from '../../../../../components/ui/WootButton.vue';

export default {
  name: 'CredentialEdit',
  components: { CredentialEditOAuth, CredentialEditApiKey, WootButton },
  mixins: [credentialActions],
  beforeRouteLeave(_, __, next) {
    this.clearForm();
    next();
  },
  data() {
    return {
      credentialName: '',
      credentialType: '',
      apiKey: { ...API_KEY_INIT },
      basic: { ...PASSWORD_NAME_INIT },
      bearer: { ...BEARER_INIT },
      digest: { ...PASSWORD_NAME_INIT },
      oauth2: { ...OAUTH2_INIT },
      mews: { ...MEWS_INIT },
    };
  },
  computed: {
    apiKeyConfig() {
      return this.isMews ? this.mews : this.apiKey;
    },
    authTypes() {
      return [
        {
          label: this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.API_KEY'),
          value: 'apiKey',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.BASIC'),
          value: 'basic',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.BEARER'),
          value: 'bearer',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.DIGEST'),
          value: 'digest',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.OAUTH_2'),
          value: 'oauth2',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.PMS'),
          disabled: true,
          value: 'pms',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.APALEO'),
          value: 'apaleo',
        },
        {
          label: 'MEWS',
          value: 'mews',
        },
      ];
    },
    saveButtonText() {
      return this.isConnectCred
        ? this.$t('CHATLYN_CREDENTIALS.BUTTONS.CONNECT')
        : this.$t('CHATLYN_GENERAL.BUTTON.SAVE');
    },
    credentialId() {
      return this.$route.params?.credentialId || null;
    },
    credentialStatusColor() {
      const initialStatus = this.credential?.status || 'broken';
      const status = this.isEdit ? initialStatus : 'broken';
      return this.statusMap[status].color;
    },
    credentialTitle() {
      return this.isEdit && !this.uiFlags.isFetching
        ? this.credential?.name
        : this.$t('CHATLYN_CREDENTIALS.CONFIG_PAGE.CREATE_TITLE');
    },
    isApiKey() {
      return this.credentialType === 'apiKey';
    },
    isPasswordName() {
      return (
        this.credentialType === 'basic' || this.credentialType === 'digest'
      );
    },
    isBearer() {
      return this.credentialType === 'bearer';
    },
    isOAuth() {
      return this.credentialType === 'oauth2';
    },
    isApaleo() {
      return this.credentialType === 'apaleo';
    },
    isMews() {
      return this.credentialType === 'mews';
    },
    isConnectCred() {
      return this.isOAuth || this.isApaleo;
    },
    isConnectedButton() {
      return (
        this.isConnectCred && this.credential.status === 'live' && !this.isDirty
      );
    },
    isDirty() {
      return (
        JSON.stringify(this.credential.credential) !==
          JSON.stringify(this[this.credentialType]) ||
        this.credentialName !== this.credential.name ||
        this.credentialType !== this.credential.type
      );
    },
    isEdit() {
      return !!this.credentialId;
    },
    pageSubtitle() {
      return this.isEdit
        ? this.$t('CHATLYN_CREDENTIALS.CONFIG_PAGE.EDIT')
        : this.$t('CHATLYN_CREDENTIALS.CONFIG_PAGE.CREATE');
    },
    pageDescription() {
      return this.isEdit
        ? this.$t('CHATLYN_CREDENTIALS.CONFIG_PAGE.EDIT_DESCRIPTION')
        : this.$t('CHATLYN_CREDENTIALS.CONFIG_PAGE.CREATE_DESCRIPTION');
    },
    password: {
      get() {
        return this[this.credentialType].password;
      },
      set(val) {
        if (this.credentialType) {
          this[this.credentialType].password = val;
        }
      },
    },
    username: {
      get() {
        return this[this.credentialType].username;
      },
      set(val) {
        if (this.credentialType) {
          this[this.credentialType].username = val;
        }
      },
    },
  },
  watch: {
    credentialId: {
      async handler(val) {
        if (val && this.isEdit) {
          await this.getCredential(this.credentialId);
          this.setCredentialToEdit();
        }
      },
      immediate: true,
    },
  },
  methods: {
    clearForm() {
      if (this.credentialType) {
        this[this.credentialType] = { ...RESET_TO_INIT[this.credentialType] };
      }
      this.$formulate.reset('credentials');
    },
    getUpdatedFields() {
      const updatedFields = {};
      Object.entries(this[this.credentialType]).forEach(([key, value]) => {
        if (this.credential.credential[key] !== value) {
          updatedFields[key] = value;
        }
      });
      return updatedFields;
    },
    getCredentialPayload() {
      const credential = this.isEdit
        ? this.getUpdatedFields()
        : this[this.credentialType];
      return {
        name: this.credentialName,
        type: this.credentialType,
        credential: { ...credential },
      };
    },
    setCredentialToEdit() {
      const { name, type, credential } = this.credential;
      this.credentialName = name;
      this.credentialType = type;
      this[this.credentialType] = { ...credential };
    },
    updateOAuth(newConfig) {
      this.oauth2 = { ...newConfig };
    },
    updateAPIKey(newConfig) {
      if (this.isMews) {
        this.mews = { ...newConfig };
      } else {
        this.apiKey = { ...newConfig };
      }
    },
    async onFormSubmit() {
      const credential = this.getCredentialPayload();
      if (this.isEdit) {
        await this.updateCredential(credential, this.credentialId);
      } else {
        await this.createCredential(credential);
      }
    },
  },
};
</script>

<style lang="scss">
.edit-credential-form-block {
  @apply mt-5 flex flex-col gap-2 rounded-lg border p-4 dark:border-gray-600;
}
</style>
