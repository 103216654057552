import { LocalStorage } from 'shared/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';

export const state = {
  colorTheme: 'auto',
  isDark: false,
};

export const getters = {
  isDark({ isDark }) {
    return isDark;
  },
  colorTheme({ colorTheme }) {
    return colorTheme;
  },
};

export const actions = {
  getColorTheme: ({ commit }) => {
    const theme = LocalStorage.get(LOCAL_STORAGE_KEYS.COLOR_SCHEME) || 'auto';
    commit('updateColorTheme', theme);
    commit('updateIsDark');
  },
  setColorTheme: ({ commit }, { theme }) => {
    LocalStorage.set(LOCAL_STORAGE_KEYS.COLOR_SCHEME, theme);
    commit('updateColorTheme', theme);
    commit('updateIsDark');
  },
};

export const mutations = {
  updateColorTheme(_state, theme) {
    _state.colorTheme = theme;
  },
  updateIsDark(_state) {
    const isOSDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    _state.isDark =
      _state.colorTheme === 'dark' ||
      (_state.colorTheme === 'auto' && isOSDark);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
