export const getters = {
  getLists(state) {
    return state.records;
  },

  getAvailableLists(state) {
    return state.records.filter((record) => !record.isArchived);
  },

  getAvailableStaticLists(state) {
    return state.records.filter(
      (record) => !record.isArchived && record.static
    );
  },

  getExcludedContacts(state) {
    return state.excludedContacts;
  },

  getListNames(state) {
    return state.records.map((list) => ({
      // eslint-disable-next-line no-underscore-dangle
      id: list._id,
      name: list.name,
    }));
  },

  getList(state) {
    return state.record;
  },

  getContacts(state) {
    return state.record.contacts;
  },
  getCurrentContact: ($state) => (id) => {
    return $state.record.contacts.find((contact) => contact.id === id);
  },
  getMeta(state) {
    return state.meta;
  },
  getLinks(state) {
    return state.links;
  },
  getListEntrypoints(state) {
    return state.entrypoints;
  },

  getAppliedListConditions(state) {
    return state.appliedListConditions;
  },

  getAppliedListCondition: (state) => (id) => {
    return state.appliedListConditions[id];
  },

  getUiFlags(state) {
    return state.uiFlags;
  },

  // getShortLists(state) {
  //   return state.lists.map(list => ({
  //     id: list._id,
  //     label: list.name,
  //     count: list.contacts.length || 0,
  //   }));
  // },
};
