import { frontendURL } from '../../../../helper/URLHelper';

const contacts = accountId => ({
  parentNav: 'automations',
  routes: ['automations_dashboard', 'automations_dashboard_entrypoint'],
  hasSecondaryMenu: true,
  menuItems: [
    // {
    //   icon: 'mail-read',
    //   label: 'AUTOMATIONS',
    //   key: 'automations_dashboard',
    //   toState: frontendURL(`accounts/${accountId}/automations`),
    //   toStateName: 'automations_dashboard',
    // },
  ],
});

export default contacts;
