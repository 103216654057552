import types from '../../mutation-types';

export const mutations = {
  [types.SET_ALL_ENTRYPOINT]($state, payload) {
    $state.records = payload;
  },

  [types.SET_ENTRYPOINT]($state, payload) {
    $state.record = payload;
  },

  [types.SET_ENTRYPOINT_UI_FLAGS]($state, payload) {
    $state.uiFlags = { ...$state.uiFlags, ...payload };
  },

  [types.CLEAR_ALL_ENTRYPOINT]($state) {
    $state.records = [];
  },

  [types.CLEAR_ENTRYPOINT]($state) {
    $state.record = {};
  },
};
