<template>
  <div class="flex-grow overflow-y-auto">
    <div class="chatlyn-container flex flex-col gap-8">
      <div class="grid gap-8 md:grid-cols-3">
        <div class="col-span-2">
          <woot-loading-state
            v-if="uiFlags.isFetching"
            :message="$t('CHATLYN_CREDENTIALS.LOADING')"
            class="mx-auto"
          />
          <div
            v-else-if="!credentials.length"
            class="mx-auto text-center text-sm"
          >
            {{ $t('CHATLYN_CREDENTIALS.LIST.404') }}
          </div>
          <div v-else class="flex flex-col gap-3">
            <card-item
              v-for="cred in credentials"
              :key="cred.id"
              :description-pairs="getDescriptionPairs(cred)"
              :title="cred.name"
              @edit="redirectToEditPage(cred.id)"
              @delete="openDeleteConfirmation(cred.id)"
            >
              <template #status>
                <div
                  class="flex items-center gap-1 rounded border p-0.5 dark:border-gray-600 dark:bg-gray-700"
                >
                  <div
                    class="status-indicator"
                    :class="statusMap[cred.status].color"
                  />
                  <span class="text-xs text-gray-700 dark:text-gray-300">
                    {{ statusMap[cred.status].text }}
                  </span>
                </div>
              </template>
            </card-item>
          </div>
        </div>
        <div class="credentials-description col-span-1">
          <span v-dompurify-html="$t('CHATLYN_CREDENTIALS.SIDEBAR_TXT')" />
        </div>
      </div>
    </div>

    <woot-delete-modal
      :show.sync="showDeleteConfirmation"
      :title="$t('CHATLYN_CREDENTIALS.DELETE_CONFIRMATION.TITLE')"
      :message="$t('CHATLYN_CREDENTIALS.DELETE_CONFIRMATION.MESSAGE')"
      :confirm-text="$t('CHATLYN_GENERAL.BUTTON.DELETE')"
      :reject-text="$t('CHATLYN_GENERAL.BUTTON.CANCEL')"
      :on-close="closeDeleteConfirmation"
      :on-confirm="onConfirmDeletion"
    />
  </div>
</template>
<script>
import { format, formatDistanceToNow } from 'date-fns';
import CardItem from 'dashboard/components/ui/CardItem.vue';

import credentialActions from './mixins/credentialActions';

export default {
  components: { CardItem },
  mixins: [credentialActions],
  computed: {
    typeTitles() {
      return {
        apaleo: `${this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.APALEO')} ${this.$t(
          'CHATLYN_CREDENTIALS.AUTH'
        )}`,
        apiKey: `${this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.API_KEY')} ${this.$t(
          'CHATLYN_CREDENTIALS.AUTH'
        )}`,
        basic: `${this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.BASIC')} ${this.$t(
          'CHATLYN_CREDENTIALS.AUTH'
        )}`,
        bearer: `${this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.BEARER')} ${this.$t(
          'CHATLYN_CREDENTIALS.AUTH'
        )}`,
        digest: `${this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.DIGEST')}  ${this.$t(
          'CHATLYN_CREDENTIALS.AUTH'
        )}`,
        oauth2: `${this.$t('CHATLYN_CREDENTIALS.AUTH_TYPES.OAUTH_2')} ${this.$t(
          'CHATLYN_CREDENTIALS.AUTH'
        )}`,
        mews: `MEWS ${this.$t('CHATLYN_CREDENTIALS.AUTH')}`
      };
    },
  },
  async mounted() {
    await this.getCredentials();
  },
  methods: {
    getDescriptionPairs(credential) {
      const { type, createdAt, updatedAt } = credential;
      return [
        {
          title: this.$t('CHATLYN_CREDENTIALS.ITEM.TYPE'),
          value: this.typeTitles[type],
        },
        {
          title: this.$t('CHATLYN_CREDENTIALS.ITEM.UPDATED'),
          value: formatDistanceToNow(new Date(updatedAt), { addSuffix: true }),
        },
        {
          title: this.$t('CHATLYN_CREDENTIALS.ITEM.CREATED'),
          value: format(new Date(createdAt), 'dd MMMM'),
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss"></style>
