/* eslint-disable class-methods-use-this */
/* global axios */
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? 'https://staging.chatlyn.com/smswamw/wa'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/smswamw/wa';

class WhatsAppAPI {
  getWhatsApp24hSendingLimits = async (phoneNumber) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/getLimit`,
      { number: phoneNumber.replace(/\+/g, '') },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getWhatsAppWindowExpiration = async (contactId, inboxId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/windowExpiration?contactId=${contactId}&inboxId=${inboxId}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };
}

export default new WhatsAppAPI();
