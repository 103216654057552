const sortListByOrder = (list) => {
  const sortedList = structuredClone(list);

  sortedList.sort((a, b) => a.order - b.order);

  sortedList.forEach((item) => {
    if (!Array.isArray(item.children)) {
      return;
    }

    item.children.sort((a, b) => a.order - b.order);
  });

  return sortedList;
};

const mapFoldersAndItemsToListStructure = (folders, items) => {
  const clonedFolders = structuredClone(folders);
  const clonedItems = structuredClone(items);

  const result = clonedFolders.map((folder) => ({
    ...folder,
    children: [],
  }));

  clonedItems.forEach((item) => {
    if (!item.folderId) {
      result.push(item);
      return;
    }

    // eslint-disable-next-line no-underscore-dangle
    const matchedFolder = result.find((folder) => folder._id === item.folderId);

    if (!matchedFolder) {
      return;
    }

    matchedFolder.children.push(item);
  });

  const sorted = sortListByOrder(result);

  return {
    active: sorted.filter((item) => !item.isArchived),
    archived: sorted.filter((item) => item.isArchived),
  };
};

const updateItemsAndFoldersKeys = (list) => {
  const clonedList = structuredClone(list);

  const newList = clonedList.map((item, index) => {
    item.order = index;

    if (!Array.isArray(item.children)) {
      item.folderId = '';
      return item;
    }

    item.children = structuredClone(
      item.children.map((child, childIndex) => {
        // eslint-disable-next-line no-underscore-dangle
        child.folderId = item.id || item._id;
        child.order = childIndex;
        return child;
      })
    );

    return item;
  });

  return newList;
};

const mapListStructureToFoldersAndItems = (list) => {
  const clonedList = structuredClone(list);

  const items = [];
  const folders = [];

  clonedList.forEach((item) => {
    if (!Array.isArray(item.children)) {
      delete item.routeParams;
      items.push(item);
      return;
    }

    item.children.forEach((nestedItem) => {
      delete nestedItem.routeParams;
      items.push(nestedItem);
    });

    delete item.children;
    folders.push(item);
  });

  return {
    items,
    folders,
  };
};

export {
  mapFoldersAndItemsToListStructure,
  updateItemsAndFoldersKeys,
  mapListStructureToFoldersAndItems,
};
