<template>
  <div
    class="flex-view relative flex flex-row flex-nowrap items-center gap-1"
    :class="{
      'edit-mode': isEditMode,
    }"
  >
    <span>
      <button
        class="translator-button cursor-pointer p-0 text-xxs"
        :style="{
          color,
        }"
        type="button"
        @click="$emit('button-action')"
      >
        {{ label }}
      </button>
    </span>

    <span class="language-select-container relative h-3 w-3">
      <select
        v-model="selectedTargetLanguage"
        class="language-select mb-0 h-full w-full cursor-pointer border-none bg-transparent bg-none p-0 pl-2 pr-1 focus:bg-transparent"
      >
        <option
          v-for="targetLanguage in languages"
          :key="targetLanguage.value"
          :class="{
            selected: targetLanguage.value === selectedTargetLanguage,
          }"
          :value="targetLanguage.value"
        >
          {{ targetLanguage.label }}
        </option>
      </select>

      <fluent-icon
        class="language-select-icon max-w-3 pointer-events-none absolute left-0 top-0 h-full max-h-3 w-full"
        icon="caret-down"
        size="12"
        :color="color"
        type="solid"
      />
    </span>

    <chatlyn-spinner
      class="language-select__spinner"
      :is-visible="isLoading && !isEditMode"
    />
  </div>
</template>

<script>
import ChatlynSpinner from '../../../../../shared/components/ChatlynSpinner.vue';

export default {
  name: 'LanguageSelect',
  components: { ChatlynSpinner },
  props: {
    label: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    languages: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'var(--woot-500)',
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedTargetLanguage: '',
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.selectedTargetLanguage = newValue;
      },
      immediate: true,
    },

    selectedTargetLanguage: {
      handler(newValue) {
        if (newValue === this.value) {
          return;
        }

        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-view {
  .language-select-container {
    .language-select {
      & > option {
        &.selected {
          @apply bg-woot-400 text-white;
        }
      }
    }

    .language-select-icon {
    }
  }

  ::v-deep .language-select__spinner {
    @apply rounded;

    .chatlyn-spinner__circle {
      @apply h-4 w-4;
    }
  }
}
</style>
