var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.bodyComponent)?_c('div',{staticClass:"message-template-message-container"},[_c('label',{attrs:{"for":"message-template-message"}},[_vm._v("\n    "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.MESSAGE_BODY'))+"\n  ")]),_vm._v(" "),_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hideEmojiPicker),expression:"hideEmojiPicker"}],staticClass:"message-template-message relative"},[_c('div',{staticClass:"relative"},[_c('tags-text-area',{ref:"message-template-tags-text-area",attrs:{"id":`message-template-${_vm.currentTranslation.language}-message`,"is-formulate-form-component":"","is-has-outside-close":false,"tags":_vm.TAGS,"placeholder":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.MESSAGE_BODY_PLACEHOLDER'),"validation":"required|noMultipleNewLines|maxLength","validation-messages":{
          required: _vm.$t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
          noMultipleNewLines: _vm.$t(
            'CHATLYN_GENERAL.MESSAGES.NO_DOUBLE_NEW_LINES'
          ),
          maxLength: _vm.$t('CHATLYN_GENERAL.MESSAGES.TOO_LONG'),
        },"validation-rules":{
          noMultipleNewLines: _vm.noMultipleNewLines,
          maxLength: () => _vm.maxLength(_vm.bodyComponent.text, 1024),
        },"min-height":3,"max-length":1024,"show-emoji-picker":_vm.showEmojiPicker},on:{"hide-emoji-picker":_vm.hideEmojiPicker},model:{value:(_vm.bodyComponent.text),callback:function ($$v) {_vm.$set(_vm.bodyComponent, "text", $$v)},expression:"bodyComponent.text"}}),_vm._v(" "),_c('div',{staticClass:"mb-2"},[_c('input-translator',{attrs:{"is-extended":"","is-hidden":!_vm.showTranslateBox,"max-length":1024},on:{"action-after-translate":_vm.toggleTranslateBox},model:{value:(_vm.bodyComponent.text),callback:function ($$v) {_vm.$set(_vm.bodyComponent, "text", $$v)},expression:"bodyComponent.text"}})],1),_vm._v(" "),_c('reply-bottom-panel',{attrs:{"hide-ai-suggestion-button":"","hide-ai-button":"","hide-send-button":"","hide-ai-assist-button":"","hide-signature-button":"","disable-drag-drop":"","show-text-counter":"","show-merge-tag-button":"","portal-slug":"","message":_vm.bodyComponent.text,"is-immediate-translation-disabled":!_vm.bodyComponent.text,"show-emoji-picker":_vm.showEmojiPicker,"translate-text-immediate-label":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TRANSLATE_TO') +
          ' ' +
          `${_vm.currentTranslation.language.toUpperCase()}`,"conversation-id":-1,"current-length":_vm.bodyComponent.text ? _vm.bodyComponent.text.length : 0,"max-length":1024,"preferred-language":_vm.currentTranslation.language,"show-translate-box":_vm.showTranslateBox,"show-immediate-translation":_vm.isTranslatable},on:{"change-message":_vm.changeText,"toggle-translate-box":() => _vm.toggleTranslateBox(),"add-merge-tag":_vm.addMergeTag,"toggle-emoji-picker":_vm.toggleEmojiPicker,"change-is-translating":_vm.changeIsTranslating}})],1),_vm._v(" "),_c('chatlyn-spinner',{attrs:{"is-visible":_vm.isTranslating}})],1),_vm._v(" "),_c('slide-animation',{attrs:{"is-shown":!!(_vm.linksComponent && _vm.linksComponent.body.length)}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.linksComponent && _vm.linksComponent.body.length)?_c('div',{staticClass:"message-template-form-switch-container mt-3 flex flex-col flex-nowrap gap-4"},[_c('div',{staticClass:"message-template-link-header -mb-2 text-sm text-woot-800 dark:text-woot-50"},[_vm._v("\n          "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.MERGE_TAGS'))+"\n        ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.translationsCount > 1),expression:"translationsCount > 1"}],staticClass:"rounded-lg p-3 transition-colors duration-200",class:{
            'bg-woot-50 dark:bg-woot-800': _vm.isTranslationAttributesSynced,
            'bg-gray-50 dark:bg-gray-700': !_vm.isTranslationAttributesSynced,
          }},[_c('woot-switch',{staticClass:"message-template-link-sync-switch",attrs:{"id":`message-template-link-sync-${_vm.currentTranslation.language}-switch`,"disabled-color":"var(--s-100)","label":_vm.$t(
                'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SYNC_TRANSLATION_ATTRIBUTES'
              )},model:{value:(_vm.isTranslationAttributesSyncedComputed),callback:function ($$v) {_vm.isTranslationAttributesSyncedComputed=$$v},expression:"isTranslationAttributesSyncedComputed"}})],1),_vm._v(" "),_vm._l((_vm.linksComponent.body),function(linkComponent,linkComponentIndex){return _c('div',{key:linkComponent.number},[_c('div',{staticClass:"message-template-link-container"},[_c('span',{staticClass:"message-template-link-number"},[_vm._v("\n              "+_vm._s(_vm.TAGS[linkComponent.number - 1])+"\n            ")]),_vm._v(" "),_c('label',{staticClass:"message-template-link-example"},[_c('formulate-input',{staticClass:"custom-formulate-error-tooltip tooltip__top h-full",attrs:{"type":"text","validation":"required","validation-messages":{
                  required: _vm.$t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
                },"placeholder":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.EXAMPLE_TEXT')},on:{"change":function($event){return _vm.handleSyncTranslationsAttributesExample(linkComponentIndex)}},model:{value:(linkComponent.example),callback:function ($$v) {_vm.$set(linkComponent, "example", $$v)},expression:"linkComponent.example"}})],1),_vm._v(" "),_c('span',{staticClass:"message-template-link-link"},[_c('fluent-icon',{attrs:{"icon":"link"}})],1),_vm._v(" "),_c('label',{staticClass:"message-template-link-attribute"},[_c('formulate-input',{staticClass:"h-full",attrs:{"type":"select","placeholder":_vm.$t(
                    'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ATTRIBUTE_OPTIONAL'
                  ),"options":_vm.attributesOptions},on:{"change":function($event){return _vm.handleSyncTranslationsAttributesValue(linkComponentIndex)}},model:{value:(linkComponent.value),callback:function ($$v) {_vm.$set(linkComponent, "value", $$v)},expression:"linkComponent.value"}})],1)]),_vm._v(" "),(
              _vm.getSelectedAttributeOption(linkComponent) &&
              _vm.getSelectedAttributeOption(linkComponent).type === 'date'
            )?_c('div',{key:linkComponent.number,staticClass:"message-template-link-container mt-2"},[_c('span',{staticClass:"message-template-link-link"},[_c('fluent-icon',{attrs:{"icon":"paint-brush"}})],1),_vm._v(" "),_c('formulate-input',{staticClass:"formulate-input--full-width custom-formulate-error-tooltip tooltip__top h-full",attrs:{"type":"select","validation":"required","validation-messages":{
                required: _vm.$t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
              },"placeholder":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_A_VALUE'),"options":_vm.dateFormatOptions},on:{"change":function($event){return _vm.handleSyncTranslationsAttributesFormat(linkComponentIndex)}},model:{value:(linkComponent.format),callback:function ($$v) {_vm.$set(linkComponent, "format", $$v)},expression:"linkComponent.format"}})],1):_vm._e()])})],2):_vm._e()])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }