<template>
  <div
    class="callout warning flex flex-row items-center gap-2 rounded p-2 text-xs"
  >
    <span class="inline-flex p-0.5">
      <fluent-icon icon="warning" :size="16" />
    </span>

    <span
      v-dompurify-html="
        $t(
          'CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SENDING_LIMIT_WARNING.MESSAGE',
          {
            LIMIT: currentSendingLimits,
            LINK: 'https://academy.chatlyn.com/en/articles/whatsapp-sending-limits/',
          }
        )
      "
      class="inline-flex flex-col gap-0.5"
    />
  </div>
</template>

<script>
export default {
  name: 'SendMessageLimitWarning',
  props: {
    currentSendingLimits: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
