/* global axios */
import Auth from './auth';
import { LocalStorage } from '../helper/localStorage';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { stagingUrl } from './automation.stub';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? `${process.env.AUTH_BACKEND_URL ||
        `${stagingUrl}/authentication`}/jwt-auth`
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/authentication/jwt-auth';

class JwtAuth {
  getUserTokens = async () => {
    let jwtAuthTokens = LocalStorage.get(LOCAL_STORAGE_KEYS.JWT_AUTH_TOKENS);

    if (!jwtAuthTokens) {
      await this.authenticateUser();

      jwtAuthTokens = LocalStorage.get(LOCAL_STORAGE_KEYS.JWT_AUTH_TOKENS);
    }

    if (
      !jwtAuthTokens.access_token ||
      new Date(jwtAuthTokens.access_valid_until).getTime() <= Date.now()
    ) {
      await this.refreshUserAccessToken();

      jwtAuthTokens = LocalStorage.get(LOCAL_STORAGE_KEYS.JWT_AUTH_TOKENS);
    }

    return jwtAuthTokens;
  };

  authenticateUser = async () => {
    try {
      const authData = Auth.getAuthData();

      const authObject = {
        'access-token': authData['access-token'],
        'token-type': authData['token-type'],
        client: authData.client,
        expiry: authData.expiry,
        uid: authData.uid,
      };

      const jwtAuthResponse = await axios.post(
        `${url}/authenticate-user`,
        authObject
      );

      if (!jwtAuthResponse.data) {
        throw new Error('');
      }

      LocalStorage.remove(LOCAL_STORAGE_KEYS.JWT_AUTH_TOKENS);
      LocalStorage.set(
        LOCAL_STORAGE_KEYS.JWT_AUTH_TOKENS,
        jwtAuthResponse.data
      );

      return true;
    } catch (error) {
      return false;
    }
  };

  refreshUserAccessToken = async () => {
    const jwtAuthTokens = LocalStorage.get(LOCAL_STORAGE_KEYS.JWT_AUTH_TOKENS);

    try {
      if (!jwtAuthTokens?.refresh_token) {
        throw new Error('');
      }

      const jwtAuthResponse = await axios.get(
        `${url}/refresh-user-access-token`,
        {
          headers: { Authorization: `Bearer ${jwtAuthTokens.refresh_token}` },
        }
      );

      if (!jwtAuthResponse.data) {
        throw new Error('');
      }

      LocalStorage.remove(LOCAL_STORAGE_KEYS.JWT_AUTH_TOKENS);
      LocalStorage.set(LOCAL_STORAGE_KEYS.JWT_AUTH_TOKENS, {
        ...jwtAuthTokens,
        ...jwtAuthResponse.data,
      });

      return true;
    } catch (error) {
      if (
        jwtAuthTokens?.refresh_valid_until &&
        new Date(jwtAuthTokens.refresh_valid_until).getTime() <= Date.now()
      ) {
        const authResponse = await this.authenticateUser();

        return authResponse;
      }

      return false;
    }
  };
}

export default new JwtAuth();
