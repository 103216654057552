export const stagingUrl = process.env.STAGING_FRONTEND_URL || '';

export const validationData = {
  validation: {},
};

export const formatValidation = (accountId, cookies) => {
  return {
    validation: {
      clientId: accountId,
      'access-token': cookies['access-token'],
      'token-type': 'Bearer',
      client: cookies.client,
      expiry: cookies.expiry,
      uid: cookies.uid,
    },
  };
};
