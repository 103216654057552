<template>
  <formulate-form
    v-slot="{ hasErrors }"
    class="relative"
    @submit="createAutomation"
  >
    <woot-modal-header
      :header-title="$t('CHATLYN_AUTOMATIONS.CREATE_AUTOMATION_MODAL.TITLE')"
    />

    <div class="modal-body">
      <formulate-input
        v-model="newAutomation.name"
        type="text"
        validation="required"
        :label="$t('CHATLYN_AUTOMATIONS.CREATE_AUTOMATION_MODAL.NAME')"
      />
    </div>

    <div class="modal-footer">
      <woot-button type="submit" color-scheme="success" :disabled="hasErrors">
        {{ $t('CHATLYN_GENERAL.BUTTON.ADD') }}
      </woot-button>
    </div>

    <div
      v-if="isAutomationLoading"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>
  </formulate-form>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'CreateAutomationModal',
  props: {
    entrypointId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newAutomation: {
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      isAutomationLoading: 'entrypoint/getIsAutomationLoading',
    }),
  },
  methods: {
    async createAutomation() {
      const result = await this.$store.dispatch(
        'entrypoint/createEntrypointAutomation',
        {
          accountId: this.currentAccountId,
          id: this.entrypointId,
          automation: this.newAutomation,
          callback: this.navigateToBuilder,
        }
      );

      if (!result) {
        // TODO: ERROR MESSAGE
        return;
      }

      await this.$store.dispatch('entrypoint/getEntrypoint', {
        accountId: this.currentAccountId,
        id: this.entrypointId,
      });

      this.$emit('close');
    },

    navigateToBuilder(id) {
      this.$emit('navigate-to-automation-builder', id);
    },
  },
};
</script>
