<template>
  <div v-if="bodyComponent" class="message-template-message-container">
    <label for="message-template-message">
      {{ $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.MESSAGE_BODY') }}
    </label>

    <div
      v-on-clickaway="hideEmojiPicker"
      class="message-template-message relative"
    >
      <div class="relative">
        <tags-text-area
          :id="`message-template-${currentTranslation.language}-message`"
          ref="message-template-tags-text-area"
          v-model="bodyComponent.text"
          is-formulate-form-component
          :is-has-outside-close="false"
          :tags="TAGS"
          :placeholder="
            $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.MESSAGE_BODY_PLACEHOLDER')
          "
          validation="required|noMultipleNewLines|maxLength"
          :validation-messages="{
            required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
            noMultipleNewLines: $t(
              'CHATLYN_GENERAL.MESSAGES.NO_DOUBLE_NEW_LINES'
            ),
            maxLength: $t('CHATLYN_GENERAL.MESSAGES.TOO_LONG'),
          }"
          :validation-rules="{
            noMultipleNewLines,
            maxLength: () => maxLength(bodyComponent.text, 1024),
          }"
          :min-height="3"
          :max-length="1024"
          :show-emoji-picker="showEmojiPicker"
          @hide-emoji-picker="hideEmojiPicker"
        />

        <div class="mb-2">
          <input-translator
            v-model="bodyComponent.text"
            is-extended
            :is-hidden="!showTranslateBox"
            :max-length="1024"
            @action-after-translate="toggleTranslateBox"
          />
        </div>

        <reply-bottom-panel
          hide-ai-suggestion-button
          hide-ai-button
          hide-send-button
          hide-ai-assist-button
          hide-signature-button
          disable-drag-drop
          show-text-counter
          show-merge-tag-button
          portal-slug=""
          :message="bodyComponent.text"
          :is-immediate-translation-disabled="!bodyComponent.text"
          :show-emoji-picker="showEmojiPicker"
          :translate-text-immediate-label="
            $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TRANSLATE_TO') +
            ' ' +
            `${currentTranslation.language.toUpperCase()}`
          "
          :conversation-id="-1"
          :current-length="bodyComponent.text ? bodyComponent.text.length : 0"
          :max-length="1024"
          :preferred-language="currentTranslation.language"
          :show-translate-box="showTranslateBox"
          :show-immediate-translation="isTranslatable"
          @change-message="changeText"
          @toggle-translate-box="() => toggleTranslateBox()"
          @add-merge-tag="addMergeTag"
          @toggle-emoji-picker="toggleEmojiPicker"
          @change-is-translating="changeIsTranslating"
        />
      </div>

      <chatlyn-spinner :is-visible="isTranslating" />
    </div>

    <slide-animation
      :is-shown="!!(linksComponent && linksComponent.body.length)"
    >
      <transition name="fade">
        <div
          v-if="linksComponent && linksComponent.body.length"
          class="message-template-form-switch-container mt-3 flex flex-col flex-nowrap gap-4"
        >
          <div
            class="message-template-link-header -mb-2 text-sm text-woot-800 dark:text-woot-50"
          >
            {{ $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.MERGE_TAGS') }}
          </div>

          <div
            v-show="translationsCount > 1"
            class="rounded-lg p-3 transition-colors duration-200"
            :class="{
              'bg-woot-50 dark:bg-woot-800': isTranslationAttributesSynced,
              'bg-gray-50 dark:bg-gray-700': !isTranslationAttributesSynced,
            }"
          >
            <woot-switch
              :id="`message-template-link-sync-${currentTranslation.language}-switch`"
              v-model="isTranslationAttributesSyncedComputed"
              class="message-template-link-sync-switch"
              disabled-color="var(--s-100)"
              :label="
                $t(
                  'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SYNC_TRANSLATION_ATTRIBUTES'
                )
              "
            />
          </div>

          <div
            v-for="(linkComponent, linkComponentIndex) in linksComponent.body"
            :key="linkComponent.number"
          >
            <div class="message-template-link-container">
              <span class="message-template-link-number">
                {{ TAGS[linkComponent.number - 1] }}
              </span>

              <label class="message-template-link-example">
                <formulate-input
                  v-model="linkComponent.example"
                  class="custom-formulate-error-tooltip tooltip__top h-full"
                  type="text"
                  validation="required"
                  :validation-messages="{
                    required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
                  }"
                  :placeholder="
                    $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.EXAMPLE_TEXT')
                  "
                  @change="
                    handleSyncTranslationsAttributesExample(linkComponentIndex)
                  "
                />
              </label>

              <span class="message-template-link-link">
                <fluent-icon icon="link" />
              </span>

              <label class="message-template-link-attribute">
                <formulate-input
                  v-model="linkComponent.value"
                  class="h-full"
                  type="select"
                  :placeholder="
                    $t(
                      'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ATTRIBUTE_OPTIONAL'
                    )
                  "
                  :options="attributesOptions"
                  @change="
                    handleSyncTranslationsAttributesValue(linkComponentIndex)
                  "
                />
              </label>
            </div>

            <div
              v-if="
                getSelectedAttributeOption(linkComponent) &&
                getSelectedAttributeOption(linkComponent).type === 'date'
              "
              :key="linkComponent.number"
              class="message-template-link-container mt-2"
            >
              <span class="message-template-link-link">
                <fluent-icon icon="paint-brush" />
              </span>

              <formulate-input
                v-model="linkComponent.format"
                class="formulate-input--full-width custom-formulate-error-tooltip tooltip__top h-full"
                type="select"
                validation="required"
                :validation-messages="{
                  required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
                }"
                :placeholder="
                  $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_A_VALUE')
                "
                :options="dateFormatOptions"
                @change="
                  handleSyncTranslationsAttributesFormat(linkComponentIndex)
                "
              />
            </div>
          </div>
        </div>
      </transition>
    </slide-animation>
  </div>
</template>

<script>
import ReplyBottomPanel from 'dashboard/components/widgets/WootWriter/ReplyBottomPanel.vue';
import attributeMixin from 'dashboard/mixins/attributeMixin';
import { mixin as clickaway } from 'vue-clickaway';

import TagsTextArea from '../../../../../../../components/tags/TagsTextArea.vue';
import { TAGS } from 'dashboard/constants/common';
import {
  noMultipleNewLines,
  maxLength,
} from '../../../../../../../helper/formulateValidation';
import {
  DATE_FORMAT,
  GET_DATE_FORMAT_EXAMPLE,
  GET_TIME_FORMAT_EXAMPLE,
  TIME_FORMAT,
} from '../../../../constants';
import WootSwitch from 'shared/components/ui/Switch.vue';
import InputTranslator from '../../../../../../../components/widgets/conversation/translator/InputTranslator.vue';
import { CH_I18N_TO_DEEPL_LANGUAGES_KEYS } from '../../../../../../../components/widgets/conversation/translator/constants';
import ChatlynSpinner from '../../../../../../../../shared/components/ChatlynSpinner.vue';
import SlideAnimation from '../../../../../../../components/animations/SlideAnimation.vue';

export default {
  name: 'LanguageFormBodyEditor',
  components: {
    ReplyBottomPanel,
    TagsTextArea,
    WootSwitch,
    InputTranslator,
    ChatlynSpinner,
    SlideAnimation,
  },
  mixins: [attributeMixin, clickaway],
  props: {
    currentTranslation: {
      type: Object,
      required: true,
    },

    linksComponent: {
      type: Object,
      default: () => ({
        body: [],
        buttons: [],
      }),
    },

    isTranslationAttributesSynced: {
      type: Boolean,
      default: false,
    },

    messageTemplate: {
      type: Object,
      required: true,
    },

    isTranslatable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTranslating: false,
      showTranslateBox: false,
      showEmojiPicker: false,
      rawHtml: '',
      attributeType: 'contact_attribute',
      tagsCount: 0,
      TAGS,
      CH_I18N_TO_DEEPL_LANGUAGES_KEYS,
    };
  },
  computed: {
    translationsCount() {
      return this.messageTemplate?.translations?.length || 0;
    },

    attributesOptions() {
      return this.getAttributesList({
        filterByKey: 'type',
        excludedValues: ['checkbox'],
      });
    },

    bodyComponentIndex() {
      return this.currentTranslation.components.findIndex(
        (component) => component.type === 'BODY'
      );
    },

    bodyComponent: {
      get() {
        return this.currentTranslation.components.find(
          (component) => component.type === 'BODY'
        );
      },
      set(newValue) {
        this.changeCurrentTranslation({
          ...this.currentTranslation,
          components: this.currentTranslation.components.map((component) =>
            component.type === 'BODY' ? newValue : component
          ),
        });
      },
    },

    contOfTags() {
      if (!this.bodyComponent?.text) {
        return 0;
      }

      return this.bodyComponent.text.match(/{{([1-9]{1}|10)}}/gm)?.length || 0;
    },

    dateFormatExample() {
      return GET_DATE_FORMAT_EXAMPLE(new Date());
    },

    timeFormatExample() {
      return GET_TIME_FORMAT_EXAMPLE(new Date());
    },

    dateFormatOptions() {
      const options = [];

      options.push({
        label: this.$t('CHATLYN_MESSAGE_TEMPLATE.DATE_FORMAT.DATE'),
        value: '',
        disabled: true,
      });

      DATE_FORMAT.forEach((dateFormat) => {
        options.push({
          label: `${this.dateFormatExample[dateFormat]} (${dateFormat})`,
          value: dateFormat,
        });
      });

      options.push({
        label: this.$t('CHATLYN_MESSAGE_TEMPLATE.DATE_FORMAT.TIME'),
        value: '',
        disabled: true,
      });

      TIME_FORMAT.forEach((timeFormat) => {
        options.push({
          label: `${this.timeFormatExample[timeFormat]} (${timeFormat})`,
          value: timeFormat,
        });
      });

      return options;
    },

    isTranslationAttributesSyncedComputed: {
      get() {
        return this.isTranslationAttributesSynced;
      },

      set() {
        this.$emit('toggle-is-translation-attributes-synced');
      },
    },
  },
  watch: {
    contOfTags: {
      handler(newValue, prevValue) {
        if (!newValue) {
          this.$emit('change-links-component', {
            ...this.linksComponent,
            body: [],
          });
          return;
        }

        let oldValues = {
          buttons: [],
          body: [],
        };

        if (this.linksComponent) {
          oldValues = JSON.parse(JSON.stringify(this.linksComponent));
        }

        if (newValue > prevValue) {
          const different = newValue - prevValue;

          if (this.linksComponent.body.length === newValue) {
            return;
          }

          for (let index = 0; index < different; index += 1) {
            oldValues.body.push({
              number: oldValues.body.length + 1,
              value: '',
              example: '',
            });
          }

          this.$emit('change-links-component', oldValues);

          return;
        }

        this.$emit('change-links-component', {
          ...oldValues,
          body: oldValues.body.slice(0, newValue),
        });
      },
    },
  },
  mounted() {
    this.$store.dispatch('attributes/get');
  },
  methods: {
    noMultipleNewLines,
    maxLength,

    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },

    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },

    toggleTranslateBox() {
      this.showTranslateBox = !this.showTranslateBox;

      this.$nextTick(() => {
        this.isShowTranslateBoxButtonDisabled = true;
        setTimeout(() => {
          this.isShowTranslateBoxButtonDisabled = false;
        }, 250);
      });
    },

    async changeText(newValue) {
      this.bodyComponent = {
        ...this.bodyComponent,
        text: newValue,
      };
    },

    addMergeTag() {
      const messageTemplateTagsTextArea =
        this.$refs['message-template-tags-text-area'];

      if (
        !messageTemplateTagsTextArea ||
        !messageTemplateTagsTextArea.insertTag
      ) {
        return;
      }

      messageTemplateTagsTextArea.insertTag();
    },

    getSelectedAttributeOption(link) {
      const selectedAttribute = this.attributesOptions.find(
        (attribute) => attribute.value === link.value
      );

      if (selectedAttribute?.type !== 'date') {
        delete link.format;
      }

      return selectedAttribute;
    },

    handleSyncTranslationsAttributesExample(index) {
      if (!this.isTranslationAttributesSynced) {
        return;
      }

      this.$nextTick(() => {
        this.handleSyncAttributes(index, ['example']);
      });
    },

    handleSyncTranslationsAttributesValue(index) {
      if (!this.isTranslationAttributesSynced) {
        return;
      }

      this.$nextTick(() => {
        this.handleSyncAttributes(index, ['value']);
      });
    },

    handleSyncTranslationsAttributesFormat(index) {
      if (!this.isTranslationAttributesSynced) {
        return;
      }

      this.$nextTick(() => {
        this.handleSyncAttributes(index, ['value', 'format']);
      });
    },

    handleSyncAttributes(index, keys) {
      this.changeMessageTemplate({
        ...this.messageTemplate,
        translations: this.messageTemplate.translations.map((translation) => {
          if (
            !translation.links.body.length ||
            translation.language === this.currentTranslation.language ||
            ['pending', 'submitted', 'approved'].includes(translation.status)
          ) {
            return translation;
          }

          translation.links.body = translation.links.body.map(
            (link, linkIndex) => {
              if (linkIndex !== index) {
                return link;
              }

              const values = this.linksComponent.body[index];

              keys.forEach((key) => {
                link[key] = values[key];
              });

              if (!values.format) {
                delete link.format;
              }

              return {
                ...link,
              };
            }
          );

          return { ...translation };
        }),
      });
    },

    changeCurrentTranslation(newValue) {
      this.$emit('change-current-translation', newValue);
    },

    changeMessageTemplate(newValue) {
      this.$emit('change-message-template', newValue);
    },

    changeIsTranslating(newValue) {
      this.isTranslating = newValue;
    },
  },
};
</script>

<style lang="scss">
.message-template-message-container {
  @apply relative;

  .message-template-message {
    @apply rounded border p-4 dark:border-gray-700 dark:bg-gray-900;

    textarea {
      @apply overflow-auto text-sm outline-none dark:bg-transparent;

      border: none !important;
      margin: 0 !important;
      padding: 0 !important;
      counter-reset: section;
    }

    .bottom-box {
      padding: 0 !important;

      .left-wrap {
        @apply flex-row;
      }
    }

    .emoji-dialog {
      @apply -bottom-[13.5rem] left-12 h-64;

      top: unset;

      &:before {
        @apply -left-[1.2rem] top-5 rotate-90;
      }

      & > div {
        @apply h-full;
      }
    }
  }

  .message-template-link-header {
  }

  .message-template-link-container {
    @apply flex flex-nowrap items-center rounded border transition-all duration-200 dark:border-gray-700;

    & > *:first-child {
      @apply rounded-s;
    }

    & > *:last-child {
      @apply rounded-r-md;

      select,
      input {
        @apply rounded-r-md;
      }
    }

    &:hover {
      @apply border-gray-300 dark:border-gray-600;
    }

    &:focus-within {
      @apply border-gray-400 dark:border-gray-500;
    }

    input,
    select {
      @apply m-0 rounded-none border-none;
    }

    .message-template-link-example {
      @apply relative w-full flex-1 self-stretch;

      max-width: 33%;
    }

    .message-template-link-number,
    .message-template-link-link {
      @apply flex aspect-square h-10 items-center justify-center self-stretch bg-gray-100 text-xl leading-none text-gray-500 dark:bg-gray-700 dark:text-gray-200;
    }

    .message-template-link-attribute {
      @apply w-full flex-1 self-stretch;

      .formulate-input-wrapper {
        &,
        & * {
          @apply h-full;
        }
      }
    }
  }
}
</style>
