/* global axios */
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? 'https://staging.chatlyn.com/automation'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/automation';

class CredentialsAPI {
  getAllCredentials = async accountId => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();
    return axios.get(`${url}/credentials/${accountId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getCredential = async (accountId, credentialId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();
    return axios.get(
      `${url}/credentials/${accountId}/credential/${credentialId}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  createCredential = async (accountId, credential) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();
    return axios.post(`${url}/credentials/${accountId}`, credential, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateCredential = async (accountId, credential, credentialId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();
    return axios.patch(
      `${url}/credentials/${accountId}/credential/${credentialId}`,
      credential,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  deleteCredential = async (accountId, credentialId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();
    return axios.delete(
      `${url}/credentials/${accountId}/credential/${credentialId}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };
}

export default new CredentialsAPI();
