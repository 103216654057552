import ExifReader from 'exifreader';

const getImageData = async (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = (event) => {
        const srs = event.target.result;

        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          const imageData = ctx.getImageData(0, 0, img.width, img.height);
          resolve(imageData);
        };

        img.src = srs;
      };

      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
};

const getImageFileInfo = async (file) => {
  return ExifReader.load(file, { expanded: false });
};

const isValidRGB = async (file) => {
  try {
    const info = await getImageFileInfo(file);

    const value =
      info?.ColorMode?.value ||
      info['Color Type']?.value ||
      info['Color Space']?.value ||
      info['Color Components']?.value;

    return !['4', 'CMYK'].includes(`${value}`.trim());
  } catch (error) {
    return false;
  }
};

export { getImageFileInfo, getImageData, isValidRGB };
