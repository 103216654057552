export const getters = {
  getContacts($state) {
    return $state.sortOrder.map((contactId) => $state.records[contactId]);
  },
  getAllContacts($state) {
    return $state.allRecords;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getContact: ($state) => (id) => {
    const contact = $state.records[id];
    return contact || {};
  },
  getCurrentList: ($state) => (id) => {
    return $state.lists.find((list) => list.id === id);
  },
  getLists: ($state) => {
    return $state.lists;
  },
  getContactableInboxes: ($state) => {
    return $state.inboxes;
  },
  getMeta: ($state) => {
    return $state.meta;
  },
  getAppliedContactFilters: (_state) => {
    return _state.appliedFilters;
  },

  getAppliedContactCondition: (_state) => {
    return _state.appliedCondition;
  },
};
