import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import moment from 'moment';

export const downloadCsvFile = (fileName, content) => {
  const contentType = 'data:text/csv;charset=utf-8;';
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  link.setAttribute('href', url);
  link.click();
  return link;
};

export const generateFileName = ({ type, to, businessHours = false }) => {
  let name = `${type}-report-${format(fromUnixTime(to), 'dd-MM-yyyy')}`;
  if (businessHours) {
    name = `${name}-business-hours`;
  }
  return `${name}.csv`;
};

export const generateSentTemplatesFileName = ({ type, from, to }) => {
  let name = `${type}-report ${moment(from).format('DD-MM-YYYY')}-${moment(
    to
  ).format('DD-MM-YYYY')}`;

  return `${name}.csv`;
};

export const downloadURI = (uri, fileName) => {
  const link = document.createElement('a');
  link.setAttribute('href', uri);
  link.setAttribute('download', fileName);
  link.click();
  link.remove();
};
