export const getters = {
  getFolder($state) {
    return $state.record;
  },

  getFoldersState($state) {
    return $state.state;
  },

  getAllFolders($state) {
    return $state.records;
  },

  getAllAvailableFolders($state) {
    return $state.records.filter((record) => !record.isArchived);
  },

  getFolderEntrypoints($state) {
    return $state.entrypoints;
  },

  getFoldersUiFlags($state) {
    return $state.uiFlags;
  },
};
