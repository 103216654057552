<template>
  <div class="message-template-form">
    <!-- Header -->
    <language-form-header-editor
      v-if="!isSmsMessageTemplate"
      :current-translation="currentTranslation"
      :is-translatable="isTranslatable"
      @change-current-translation="changeCurrentTranslation"
    />

    <!-- Body -->
    <language-form-body-editor
      :current-translation="currentTranslation"
      :links-component="linksComponent"
      :message-template="messageTemplate"
      :is-translation-attributes-synced="isTranslationAttributesSynced"
      :is-translatable="isTranslatable"
      @change-links-component="changeLinksComponent"
      @toggle-is-translation-attributes-synced="
        toggleIsTranslationAttributesSynced
      "
      @change-current-translation="changeCurrentTranslation"
      @change-message-template="changeMessageTemplate"
    />

    <!-- Footer -->
    <language-form-footer-editor
      v-if="!isSmsMessageTemplate"
      :current-translation="currentTranslation"
      :is-translatable="isTranslatable"
      @change-current-translation="changeCurrentTranslation"
    />

    <!-- Buttons -->
    <language-form-buttons-editor
      v-if="!isSmsMessageTemplate"
      :current-translation="currentTranslation"
      :links-component="linksComponent"
      :category="category"
      :is-translatable="isTranslatable"
      @change-links-component="changeLinksComponent"
      @change-current-translation="changeCurrentTranslation"
    />
  </div>
</template>

<script>
import LanguageFormHeaderEditor from './LanguageFormHeaderEditor.vue';
import LanguageFormBodyEditor from './LanguageFormBodyEditor.vue';
import LanguageFormFooterEditor from './LanguageFormFooterEditor.vue';
import LanguageFormButtonsEditor from './LanguageFormButtonsEditor.vue';
import { MESSAGE_TEMPLATES_CHANNEL_TYPE } from '../../../../constants';

export default {
  name: 'LanguagesForm',
  components: {
    LanguageFormHeaderEditor,
    LanguageFormBodyEditor,
    LanguageFormFooterEditor,
    LanguageFormButtonsEditor,
  },
  props: {
    isTranslationAttributesSynced: {
      type: Boolean,
      default: false,
    },

    currentTranslation: {
      type: Object,
      required: true,
    },

    category: {
      type: String,
      default: '',
    },

    linksComponent: {
      type: Object,
      default: () => ({
        body: [],
        buttons: [],
      }),
    },

    messageTemplate: {
      type: Object,
      required: true,
    },

    messageTemplateType: {
      type: String,
      default: '',
    },

    isTranslatable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isSmsMessageTemplate() {
      return this.messageTemplateType === MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS;
    },
  },

  methods: {
    changeLinksComponent(newValue) {
      this.$emit('change-links-component', newValue);
    },

    toggleIsTranslationAttributesSynced() {
      this.$emit('toggle-is-translation-attributes-synced');
    },

    changeCurrentTranslation(newValue) {
      this.$emit('change-current-translation', newValue);
    },

    changeMessageTemplate(newValue) {
      this.$emit('change-message-template', newValue);
    },
  },
};
</script>

<style lang="scss">
// TODO: REFACTOR
.message-template-form {
  @apply flex flex-col gap-3;
  width: 100%;
  max-width: 27rem;
  padding: 0 !important;

  .message-template-form-switch-container {
    @apply rounded-lg border p-2 dark:border-gray-700;

    .tabs--container {
      @apply w-full;

      .tabs {
        @apply w-full p-0;

        .tabs-title {
          @apply flex-1;

          &:not(.is-active) {
            a {
              @apply text-gray-700 dark:text-gray-400;
            }
          }

          a {
            @apply justify-center p-2;
          }
        }
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 200ms ease-in-out;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }
  }
}
</style>
