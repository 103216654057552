<template>
  <div class="edit-credential-form-block">
    <formulate-input
      v-model="localConfig.key"
      :label="$t('CHATLYN_CREDENTIALS.LABELS.KEY')"
      :disabled="isMews"
      name="api-key"
      validation="required"
    />
    <formulate-input
      v-model="localConfig.value"
      :label="$t('CHATLYN_CREDENTIALS.LABELS.VALUE')"
      autocomplete="new-password"
      type="password"
      name="api-key-value"
      validation="required"
    />
    <formulate-input
      v-model="localConfig.addTo"
      :disabled="isMews"
      :label="$t('CHATLYN_CREDENTIALS.LABELS.ADD_TO')"
      :options="addToList"
      :placeholder="$t('CHATLYN_CREDENTIALS.PLACEHOLDERS.ADD_TO')"
      name="api-key-add-to"
      type="select"
      validation="required"
    />
  </div>
</template>

<script>
export default {
  name: 'CredentialEditApiKey',
  props: {
    config: {
      type: Object,
      required: true,
    },
    isMews: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localConfig: { ...this.config },
    };
  },
  computed: {
    addToList() {
      return [
        {
          label: this.$t('CHATLYN_CREDENTIALS.ADD_TO_LIST.BODY'),
          value: 'body',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.ADD_TO_LIST.COOKIE'),
          value: 'cookie',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.ADD_TO_LIST.HEADER'),
          value: 'header',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.ADD_TO_LIST.QUERY'),
          value: 'query',
        },
      ];
    },
    isDirty() {
      return JSON.stringify(this.config) !== JSON.stringify(this.localConfig);
    },
  },
  watch: {
    config(val) {
      this.localConfig = { ...val };
    },
    localConfig: {
      handler(val) {
        if (this.isDirty) {
          this.$emit('update-api-key', { ...val });
        }
      },
      deep: true,
    },
  },
};
</script>
