<template>
  <label class="input-with-translation-root grid w-full gap-1">
    <span v-if="label"> {{ label }} </span>

    <div
      class="input-with-translation input-base-styles min-h-[2.625rem] border p-1 pl-2 dark:border-gray-700"
    >
      <div
        class="input-with-translation__container relative flex h-full flex-row flex-nowrap gap-2"
        :class="{
          'input-with-translation__container--default-input':
            !isFormulateFormComponent,
        }"
      >
        <input-counter
          class="flex-1"
          :value="modelValue"
          :max-length="maxLength"
          :is-hidden="!hasCounter || !maxLength"
          :input-class="
            isFormulateFormComponent
              ? undefined
              : 'input-with-translation__container__input'
          "
        >
          <formulate-input
            v-if="isFormulateFormComponent"
            ref="input-with-translation"
            v-model="modelValue"
            :class="inputClass"
            :type="type"
            :placeholder="placeholder"
            :maxlength="maxLength"
            :validation="validation"
            :validation-messages="validationMessages"
            :validation-rules="validationRules"
            @blur="$emit('blur')"
          />

          <input
            v-else
            v-model="modelValue"
            :class="`input-with-translation__container__input ${inputClass}`"
            :type="type"
            :placeholder="placeholder"
            :maxlength="maxLength"
            @blur="$emit('blur')"
          />
        </input-counter>

        <input-translator
          v-if="!disableTranslation"
          v-model="modelValue"
          :max-length="maxLength"
          :preferred-language="preferredLanguage"
        />
      </div>
    </div>
  </label>
</template>

<script>
import InputCounter from '../../../ui/InputCounter.vue';
import InputTranslator from './InputTranslator.vue';

export default {
  name: 'InputWithTranslation',
  components: { InputTranslator, InputCounter },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },

    isFormulateFormComponent: {
      type: Boolean,
      default: false,
    },

    label: {
      type: [String, undefined],
      default: undefined,
    },

    placeholder: {
      type: [String, undefined],
      default: undefined,
    },

    type: {
      type: String,
      default: 'text',
    },

    inputClass: {
      type: String,
      default: '',
    },

    maxLength: {
      type: [Number, undefined],
      default: undefined,
    },

    validation: {
      type: [String, undefined],
      default: undefined,
    },

    validationMessages: {
      type: [Object, undefined],
      default: undefined,
    },

    validationRules: {
      type: [Object, undefined],
      default: undefined,
    },

    hasCounter: {
      type: Boolean,
      default: true,
    },

    preferredLanguage: {
      type: [String, undefined],
      default: undefined,
    },

    disableTranslation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit('change', newValue);
      },
    },
  },
};
</script>

<style lang="scss">
.input-with-translation-root {
  &:has(
      .input-with-translation
        .input-with-translation__container
        .formulate-input[data-is-showing-errors='true']
    ) {
    @apply pb-[1.55rem];
  }

  .input-with-translation {
    &,
    input {
      @apply transition-colors duration-300;
    }

    &:focus-within {
      &,
      input {
        @apply dark:bg-gray-950;
      }
    }

    .input-with-translation__container {
      .formulate-input {
        @apply h-full;

        .formulate-input-wrapper {
          @apply h-full;

          .formulate-input-element {
            @apply h-full;

            input {
              @apply m-0 h-full border-none bg-transparent p-0 focus:bg-transparent;
            }
          }
        }

        .formulate-input-errors {
          @apply absolute top-[calc(100%_+_0.625rem)]  w-full p-0;

          .formulate-input-error {
            @apply w-full overflow-hidden text-ellipsis whitespace-nowrap;
          }
        }
      }

      &.input-with-translation__container--default-input {
        input {
          @apply m-0 h-full border-none p-0;
        }
      }
    }
  }
}
</style>
