/* global axios */
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? 'https://staging.chatlyn.com/automation/automations'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/automation/automations';

class AutomationsAPI {
  getAllEntrypoint = async (accountId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/${accountId}/entrypoint`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getEntrypoint = async (accountId, id) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/${accountId}/entrypoint/${id}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  createEntrypoint = async (accountId, entrypoint) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();
    let entrypointType = 'entrypoint';
    if (entrypoint.list) {
      entrypointType = 'list-entrypoint';
    }
    if (entrypoint.cronString) {
      entrypointType = 'timed-entrypoint';
    }
    return axios.post(`${url}/${accountId}/${entrypointType}`, entrypoint, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateEntrypoint = async (accountId, entrypoint) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(
      // eslint-disable-next-line no-underscore-dangle
      `${url}/${accountId}/entrypoint/${entrypoint._id}`,
      entrypoint,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  deleteEntrypoint = async (accountId, id) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(`${url}/${accountId}/entrypoint/${id}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getEntrypointAutomation = async (accountId, id, automationId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/${accountId}/entrypoint/${id}/automation/${automationId}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  createEntrypointAutomation = async (accountId, id, automation) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/${accountId}/entrypoint/${id}/automation`,
      automation,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  updateEntrypointAutomation = async (accountId, id, automation) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(
      `${url}/${accountId}/entrypoint/${id}/automation/${automation.id}`,
      automation,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  deleteEntrypointAutomation = async (accountId, id, automationId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(
      `${url}/${accountId}/entrypoint/${id}/automation/${automationId}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  duplicateEntrypoint = async (accountId, duplicated) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();
    return axios.post(
      `${url}/${accountId}/entrypoint/${duplicated.id}/duplicate`,
      { name: duplicated.name },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  duplicateJourney = async (accountId, entrypointId, duplicated) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();
    return axios.post(
      `${url}/${accountId}/entrypoint/${entrypointId}/automation/${duplicated.id}/duplicate`,
      { name: duplicated.name },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };
}

export default new AutomationsAPI();
