<template>
  <div
    :ref="copyContainerRefName"
    class="input-copy-container"
    :style="{
      'padding-right': `${inputHeight}px`,
    }"
  >
    <slot />

    <div
      :ref="copyRefName"
      class="input-copy"
      :style="{
        bottom: `${bottom}px`,
        height: `${inputHeight}px`,
      }"
    >
      <woot-button
        variant="smooth"
        color-scheme="secondary"
        :icon="isCopied ? 'clipboard-task' : 'duplicate'"
        @click="copyToClipboard"
      />
    </div>
  </div>
</template>

<script>
import getUuid from 'widget/helpers/uuid';

export default {
  name: 'InputCopy',
  props: {
    value: {
      type: String,
      default: '',
    },

    inputClass: {
      type: String,
      default: 'formulate-input-wrapper',
    },
  },
  data() {
    return {
      resizeObserver: null,
      bottom: 0,
      inputPaddingRight: 0,
      inputHeight: 0,
      isCopied: false,
      copyTimeout: null,
    };
  },
  computed: {
    copyContainerRefName() {
      return `input-copy-container-${getUuid()}`;
    },

    copyRefName() {
      return `input-copy-container-${getUuid()}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      const container = this.$refs[this.copyContainerRefName];
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(container);

      const copy = this.$refs[this.copyRefName];
      this.inputPaddingRight = copy.clientWidth + 8;
    });
  },
  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
  methods: {
    onResize(event) {
      const { contentRect, target } = event[0];
      const inputContainerElement = target.querySelector(`.${this.inputClass}`);
      const inputContainerRect = inputContainerElement.getBoundingClientRect();

      const inputElement =
        inputContainerElement.querySelector('input') ||
        inputContainerElement.querySelector('textarea');

      this.inputHeight = inputElement ? inputElement.offsetHeight : 0;
      this.bottom = contentRect.height - inputContainerRect.height;
    },

    async copyToClipboard() {
      await navigator.clipboard.writeText(this.value);
      this.isCopied = true;

      this.copyTimeout = setTimeout(() => {
        this.isCopied = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.input-copy-container {
  @apply relative;

  input,
  textarea {
    @apply rounded-e-none border-e-0;
  }

  .input-copy {
    @apply absolute bottom-0 right-0 overflow-hidden rounded-e border leading-none text-gray-500 dark:border-gray-600;
    > button {
      @apply aspect-square h-full w-auto rounded-none;
    }
  }
}
</style>
