<template>
  <div ref="codemirrorInput" class="relative">
    <label for="vue-codemirror"> {{ label }} </label>
    <formulate-input
      v-model="codeFormat"
      :options="codeFormats"
      type="select"
      class="w-22 absolute right-1 top-7 z-10"
    />
    <div
      ref="editor"
      :class="{
        focused: codemirrorFocused,
      }"
      :style="{ height: editorHeight + 'px' }"
      class="vue-codemirror"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { basicSetup, EditorView } from 'codemirror';
import { StateEffect } from '@codemirror/state';
import { linter, lintGutter } from '@codemirror/lint';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import { xmlLanguage } from '@codemirror/lang-xml';
import { oneDark } from '@codemirror/theme-one-dark';
import { standardKeymap } from '@codemirror/commands';
import { keymap, placeholder, tooltips } from '@codemirror/view';
import { xmlParseLinter } from 'dashboard/helper/xmlHelpers';
import { isJSONValid } from 'dashboard/helper/commons';

const JSON = 'JSON';
const XML = 'XML';

export default {
  name: 'CodemirrorInput',
  components: {},
  props: {
    editorHeight: {
      type: [String, Number],
      default: '320',
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      view: null,
      codemirrorFocused: false,
      codeFormats: [
        {
          label: JSON,
          value: JSON,
        },
        {
          label: XML,
          value: XML,
        },
      ],
      codeFormat: JSON,
    };
  },
  computed: {
    ...mapGetters({
      isDark: 'theme/isDark',
    }),
    commonExtensions() {
      return [
        basicSetup,
        lintGutter({
          hoverTime: 100,
          markerFilter: (diagnostics) =>
            this.localValue ? [...diagnostics] : [],
        }),
        keymap.of(standardKeymap),
        tooltips({ parent: this.$refs.codemirrorInput, position: 'fixed' }),
        EditorView.domEventHandlers({
          focus: () => this.onCodemirrorFocus(),
          blur: () => this.onCodemirrorBlur(),
        }),
        EditorView.updateListener.of((update) =>
          this.onCodemirrorUpdated(update)
        ),
      ];
    },
    codeMirrorExtensions() {
      const extensions = [...this.commonExtensions];
      const codeLang = this.isJSON ? json() : xmlLanguage;
      const codeLinter = this.isJSON ? jsonParseLinter() : xmlParseLinter();
      const codePlaceholder = this.isJSON
        ? '{"key": "value"}'
        : '<key>value</key>';

      extensions.push(
        codeLang,
        linter(codeLinter),
        placeholder(codePlaceholder)
      );
      if (this.isDark) {
        extensions.push(oneDark);
      }
      return extensions;
    },
    isJSON() {
      return this.codeFormat === JSON;
    },
    label() {
      return this.isJSON
        ? this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.JSON')
        : this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.XML');
    },
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    codeFormat(val) {
      this.view.dispatch({
        effects: StateEffect.reconfigure.of([...this.codeMirrorExtensions]),
      });
      this.$emit('format-changed', val);
    },
  },
  mounted() {
    this.setCodeFormat();
    this.view = new EditorView({
      doc: this.localValue,
      extensions: [...this.codeMirrorExtensions],
      parent: this.$refs.editor,
    });
  },
  methods: {
    getUpdatedValue(updatedDoc) {
      const { children, text } = updatedDoc;
      let updatedValue = '';
      if (children && children.length) {
        children.forEach((child) => {
          if (child?.text.length) {
            updatedValue += child.text.join('');
          }
        });
      }
      if (text && text.length) {
        updatedValue += text.join('');
      }
      return updatedValue;
    },
    onCodemirrorFocus() {
      this.codemirrorFocused = true;
    },
    onCodemirrorBlur() {
      this.codemirrorFocused = false;
    },
    onCodemirrorUpdated(updated) {
      const updatedValue = this.getUpdatedValue(updated.state.doc);
      if (updatedValue !== this.localValue) {
        this.localValue = updatedValue;
      }
    },
    setCodeFormat() {
      if (this.value) {
        this.codeFormat = isJSONValid(this.value) ? 'JSON' : 'XML';
      }
    },
  },
};
</script>
