// import axios from 'axios';
import types from '../../mutation-types';
import MessageTemplateAPI from '../../../api/messageTemplates';

export const actions = {
  getMessageTemplates: async ({ commit }, { accountId, requestParams }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_MESSAGE_TEMPLATES);

      const { data } = await MessageTemplateAPI.getTemplates(
        accountId,
        requestParams
      );
      commit(types.SET_MESSAGE_TEMPLATES, data);
      // commit(types.SET_MESSAGE_TEMPLATES_META, data.meta);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: false });
    }
  },

  getAllMessageTemplates: async ({ commit }, { accountId, requestParams }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_MESSAGE_TEMPLATES);

      const { data } = await MessageTemplateAPI.getAllTemplates(
        accountId,
        requestParams
      );
      commit(types.SET_MESSAGE_TEMPLATES, data);
      // commit(types.SET_MESSAGE_TEMPLATES_META, data.meta);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: false });
    }
  },

  getAllMessageTemplatesName: async ({ commit }, { accountId }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_MESSAGE_TEMPLATES);

      const { data } = await MessageTemplateAPI.getAllTemplatesName(accountId);
      commit(types.SET_MESSAGE_TEMPLATES_NAME, data);
      // commit(types.SET_MESSAGE_TEMPLATES_META, data.meta);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: false });
    }
  },

  getTemplatesCount: async ({ commit }, { accountId }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_MESSAGE_TEMPLATE_COUNT);

      const { data } = await MessageTemplateAPI.getTemplatesCount(accountId);
      commit(types.SET_MESSAGE_TEMPLATE_COUNT, data);
      // commit(types.SET_MESSAGE_TEMPLATES_META, data.meta);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: false });
    }
  },

  getMessageTemplate: async ({ commit }, { templateId }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isTemplateFetching: true });
      const { data } = await MessageTemplateAPI.getTemplate(templateId);
      commit(types.CLEAR_MESSAGE_TEMPLATE);
      commit(types.SET_MESSAGE_TEMPLATE, data);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, {
        isTemplateFetching: false,
      });
    }
  },

  // eslint-disable-next-line no-empty-pattern
  createMessageTemplate: async ({ commit }, { accountId, newTemplate }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isCreating: true });

      const response = await MessageTemplateAPI.createTemplate(
        accountId,
        newTemplate
      );

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (error) {
      throw new Error();
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isCreating: false });
    }
  },

  addMessageTemplateTranslation: async (
    { commit },
    { templateId, translation }
  ) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isUpdating: true });

      const response = await MessageTemplateAPI.addTemplateTranslation(
        templateId,
        translation
      );

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (error) {
      return null;
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isUpdating: false });
    }
  },

  // eslint-disable-next-line no-empty-pattern
  updateMessageTemplate: async ({ commit }, { templateId, template }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isUpdating: true });
      await MessageTemplateAPI.updateTemplate(templateId, template);
    } catch (error) {
      throw new Error();
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isUpdating: false });
    }
  },

  // eslint-disable-next-line no-empty-pattern
  deleteMessageTemplate: async ({ commit }, { templateId }) => {
    let isSuccess = false;

    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isDeleting: true });
      await MessageTemplateAPI.deleteTemplate(templateId);

      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isDeleting: false });
    }

    return isSuccess;
  },

  deleteMessageTemplateTranslation: async (
    { commit },
    { templateId, language }
  ) => {
    let isSuccess = false;

    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isDeleting: true });
      await MessageTemplateAPI.deleteTemplateTranslation(templateId, language);

      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isDeleting: false });
    }

    return isSuccess;
  },

  getMessageTemplatesEntrypoints: async (
    { commit },
    { accountId, templateId }
  ) => {
    let isSuccess = false;

    try {
      commit(types.CLEAR_MESSAGE_TEMPLATE_ENTRYPOINTS);

      const { data } = await MessageTemplateAPI.getTemplateEntrypoints(
        accountId,
        templateId
      );

      if (typeof data?.length !== 'number') {
        throw new Error();
      }

      commit(types.SET_MESSAGE_TEMPLATE_ENTRYPOINTS, data);

      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      // TODO some action
    }

    return isSuccess;
  },

  getScheduledMessageTemplates: async (
    { commit },
    { accountId, requestParams }
  ) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, {
        isScheduledFetching: true,
      });

      const { data } = await MessageTemplateAPI.getScheduledTemplates(
        accountId,
        requestParams
      );

      commit(types.CLEAR_SCHEDULED_MESSAGE_TEMPLATES);
      commit(types.SET_SCHEDULED_MESSAGE_TEMPLATES, data);
      // commit(types.SET_MESSAGE_TEMPLATES_META, data.meta);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, {
        isScheduledFetching: false,
      });
    }
  },

  getScheduledMessageTemplate: async ({ commit }, { scheduledId }) => {
    try {
      const { data } =
        await MessageTemplateAPI.getScheduledTemplate(scheduledId);

      commit(types.CLEAR_SCHEDULED_MESSAGE_TEMPLATE);
      commit(types.SET_SCHEDULED_MESSAGE_TEMPLATE, data);
      // commit(types.SET_MESSAGE_TEMPLATES_META, data.meta);
    } catch (error) {
      // TODO some action
    }
  },

  cancelScheduledMessageTemplate: async ({ commit }, { id }) => {
    try {
      const { data } =
        await MessageTemplateAPI.cancelScheduledMessageTemplate(id);
      commit(types.CLEAR_MESSAGE_TEMPLATE);
      commit(types.SET_MESSAGE_TEMPLATE, data);
    } catch (error) {
      // TODO some action
    }
  },

  // SMS message templates
  getSmsMessageTemplates: async ({ commit }, { accountId, requestParams }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_MESSAGE_TEMPLATES);

      const { data } = await MessageTemplateAPI.getSmsTemplates(
        accountId,
        requestParams
      );
      commit(types.SET_MESSAGE_TEMPLATES, data);
      // commit(types.SET_MESSAGE_TEMPLATES_META, data.meta);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: false });
    }
  },

  getSmsTemplatesCount: async ({ commit }, { accountId }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_MESSAGE_TEMPLATE_COUNT);

      const { data } = await MessageTemplateAPI.getSmsTemplatesCount(accountId);
      commit(types.SET_MESSAGE_TEMPLATE_COUNT, data);
      // commit(types.SET_MESSAGE_TEMPLATES_META, data.meta);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isFetching: false });
    }
  },

  getSmsMessageTemplate: async ({ commit }, { templateId }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isTemplateFetching: true });
      const { data } = await MessageTemplateAPI.getSmsTemplate(templateId);
      commit(types.CLEAR_MESSAGE_TEMPLATE);
      commit(types.SET_MESSAGE_TEMPLATE, data);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, {
        isTemplateFetching: false,
      });
    }
  },

  createSmsMessageTemplate: async ({ commit }, { accountId, newTemplate }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isCreating: true });

      const response = await MessageTemplateAPI.createSmsTemplate(
        accountId,
        newTemplate
      );

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (error) {
      throw new Error();
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isCreating: false });
    }
  },

  addSmsMessageTemplateTranslation: async (
    { commit },
    { templateId, translation }
  ) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isUpdating: true });

      const response = await MessageTemplateAPI.addSmsTemplateTranslation(
        templateId,
        translation
      );

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (error) {
      return null;
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isUpdating: false });
    }
  },

  // eslint-disable-next-line no-empty-pattern
  updateSmsMessageTemplate: async ({ commit }, { templateId, template }) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isUpdating: true });
      await MessageTemplateAPI.updateSmsTemplate(templateId, template);
    } catch (error) {
      throw new Error();
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isUpdating: false });
    }
  },

  deleteSmsMessageTemplate: async ({ commit }, { templateId }) => {
    let isSuccess = false;

    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isDeleting: true });
      await MessageTemplateAPI.deleteSmsTemplate(templateId);

      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isDeleting: false });
    }

    return isSuccess;
  },

  deleteSmsMessageTemplateTranslation: async (
    { commit },
    { templateId, language }
  ) => {
    let isSuccess = false;

    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isDeleting: true });
      await MessageTemplateAPI.deleteSmsTemplateTranslation(
        templateId,
        language
      );

      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, { isDeleting: false });
    }

    return isSuccess;
  },

  setUiFlag: ({ commit }, newFlags) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, newFlags);
    } catch (error) {
      // TODO some action
    }
  },
};
