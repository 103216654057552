<template>
  <div class="h-full bg-white p-12 dark:bg-gray-800">
    <div
      class="flex w-4/6 flex-col items-center gap-4 rounded-xl bg-gray-50 p-10 text-center dark:bg-gray-900"
    >
      <img
        :src="authorizingImage"
        :alt="$t('CHATLYN_CREDENTIALS.AUTHORIZING_PAGE.TITLE')"
      />
      <div class="text-3xl">
        {{ $t('CHATLYN_CREDENTIALS.AUTHORIZING_PAGE.TITLE') }}
      </div>
      <p class="m-0">
        {{ $t('CHATLYN_CREDENTIALS.AUTHORIZING_PAGE.DESCRIPTION') }}
      </p>
      <woot-button icon="arrow-clockwise" @click="refreshOauth">
        {{ $t('CHATLYN_GENERAL.BUTTON.REFRESH') }}
      </woot-button>
      <p class="m-0 text-xl text-gray-500">
        {{ $t('CHATLYN_CREDENTIALS.AUTHORIZING_PAGE.FOOTER') }}
      </p>
    </div>
  </div>
</template>

<script>
import authorizingImage from 'dashboard/assets/images/partnerships-image.png';
import credentialActions from '../mixins/credentialActions';

export default {
  name: 'CredentialEditAuthorizing',
  mixins: [credentialActions],
  data() {
    return {
      authorizingImage,
    };
  },
  methods: {
    async refreshOauth() {
      this.authorizing = false;
      await this.redirectAndRefetch();
    },
  },
};
</script>
