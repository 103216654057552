import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  record: null,
  records: {
    templates: [],
    totalCount: 0,
    totalPages: 0,
  },
  templateNames: [],
  templatesCount: null,
  scheduledRecord: null,
  scheduledRecords: [],
  entrypoints: [],
  uiFlags: {
    isCreating: false,
    isFetching: false,
    isTemplateFetching: false,
    isScheduledFetching: false,
    isUpdating: false,
    isMerging: false,
    isDeleting: false,
  },
  // sortOrder: [],
  // appliedFilters: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
