<template>
  <woot-modal :on-close="() => $emit('close')" :show="isVisible">
    <woot-modal-header
      :header-title="$t('CHATLYN_AUTOMATIONS.DUPLICATE_MODAL.TITLE', { name })"
    />
    <div class="modal-body">
      <formulate-input
        v-model="newName"
        :label="$t('CHATLYN_AUTOMATIONS.DUPLICATE_MODAL.LABEL')"
        type="text"
        validation="required"
      />
    </div>
    <div class="modal-footer">
      <woot-button
        type="button"
        variant="smooth"
        color-scheme="secondary"
        @click="$emit('close')"
      >
        {{ $t('CHATLYN_GENERAL.BUTTON.CANCEL') }}
      </woot-button>
      <woot-button :disabled="!isValid" @click="$emit('duplicate', newName)">
        {{ $t('CHATLYN_AUTOMATIONS.DUPLICATE_MODAL.BUTTON') }}
      </woot-button>
    </div>
  </woot-modal>
</template>

<script>
export default {
  name: 'EntrypointDuplicateModal',
  props: {
    name: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newName: this.name,
    };
  },
  computed: {
    isValid() {
      return !!this.newName && this.newName !== this.name;
    },
  },
};
</script>
