import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import CredentialsAPI from '../../api/credentials';
import { MOCKED_SENSITIVE } from '../../routes/dashboard/settings/credentials/utils/constants';

export const state = {
  records: [],
  recordItem: {},
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getCredentialItem(_state) {
    return _state.recordItem;
  },
  getCredentials(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  getAllCredentials: async ({ commit }, { accountId }) => {
    commit(types.SET_CREDENTIAL_UI_FLAG, { isFetching: true });
    try {
      const response = await CredentialsAPI.getAllCredentials(accountId);
      commit(types.SET_CREDENTIALS, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CREDENTIAL_UI_FLAG, { isFetching: false });
    }
  },
  getCredential: async ({ commit, state: _state }, { accountId, credId }) => {
    const credential = _state.records.find(({ _id }) => _id === credId);
    if (credential) {
      commit(types.SET_CREDENTIAL, credential);
    } else {
      commit(types.SET_CREDENTIAL_UI_FLAG, { isFetching: true });
      try {
        const response = await CredentialsAPI.getCredential(accountId, credId);
        commit(types.SET_CREDENTIAL, response.data);
      } catch (error) {
        throw new Error(error);
      } finally {
        commit(types.SET_CREDENTIAL_UI_FLAG, { isFetching: false });
      }
    }
  },
  createCredential: async ({ commit }, { accountId, credential }) => {
    commit(types.SET_CREDENTIAL_UI_FLAG, { isCreating: true });
    try {
      return await CredentialsAPI.createCredential(accountId, credential);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CREDENTIAL_UI_FLAG, { isCreating: false });
    }
  },
  updateCredential: async ({ commit }, { accountId, credential, credId }) => {
    commit(types.SET_CREDENTIAL_UI_FLAG, { isUpdating: true });
    try {
      return await CredentialsAPI.updateCredential(
        accountId,
        credential,
        credId
      );
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CREDENTIAL_UI_FLAG, { isUpdating: false });
    }
  },
  deleteCredential: async ({ commit }, { accountId, credId }) => {
    commit(types.SET_CREDENTIAL_UI_FLAG, { isDeleting: true });
    try {
      await CredentialsAPI.deleteCredential(accountId, credId);
      commit(types.DELETE_CREDENTIAL, credId);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CREDENTIAL_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_CREDENTIAL_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_CREDENTIALS]: MutationHelpers.set,
  [types.SET_CREDENTIAL](_state, data) {
    const { lastRefreshDate, accessTokens, ...rest } = data.credential;
    _state.recordItem = {
      ...data,
      credential: {
        ...rest,
        ...MOCKED_SENSITIVE[data.type],
      },
    };
  },
  [types.ADD_CREDENTIAL]: MutationHelpers.create,
  [types.EDIT_CREDENTIAL]: MutationHelpers.update,
  [types.DELETE_CREDENTIAL]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
