<template>
  <div
    class="mt-2 rounded-lg border bg-gray-50 py-2 dark:border-gray-700 dark:bg-gray-700/30"
    :class="{
      'px-3': !isConsent,
      'px-2': isConsent,
    }"
  >
    <div class="flex flex-row flex-nowrap items-start gap-2">
      <button
        v-if="!isConsent"
        class="draggable-handle button clear secondary justify-self-end p-0"
        type="button"
      >
        <fluent-icon
          icon="re-order-dots-vertical"
          :size="18"
          class="cursor-move text-gray-500"
        />
      </button>

      <span class="w-full self-stretch">
        <template v-if="selectedButtonTabIndex === 0">
          <div class="flex w-full flex-col flex-nowrap items-stretch gap-2">
            <template v-if="buttonComponent.type === 'URL'">
              <input-counter :value="buttonText" :max-length="25">
                <formulate-input
                  v-model="buttonText"
                  class="w-full"
                  name="text"
                  type="text"
                  validation="required|noEmoji|uniqueValue"
                  :placeholder="
                    $t('CHATLYN_MESSAGE_TEMPLATE.PLACEHOLDER.BUTTON_TEXT')
                  "
                  :validation-messages="{
                    noEmoji: $t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
                    uniqueValue: $t(
                      'CHATLYN_GENERAL.MESSAGES.UNIQUE_BUTTON_LABEL'
                    ),
                  }"
                  :validation-rules="{
                    noEmoji: noEmoji,
                    uniqueValue: (event) =>
                      uniqueValue(event, buttonId, buttonsComponentTexts),
                  }"
                  :maxlength="25"
                />
              </input-counter>

              <div class="flex w-full flex-row flex-nowrap items-center gap-2">
                <formulate-input
                  v-model="buttonUrlType"
                  style="width: calc(40% - 0.25rem)"
                  type="select"
                  :options="{
                    static: $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.STATIC'),
                    dynamic: $t(
                      'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.DYNAMIC'
                    ),
                  }"
                  :label="$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.URL_TYPE')"
                  validation="required"
                />

                <input-counter
                  class="flex-1"
                  :value="buttonComponent.url"
                  :max-length="2000"
                >
                  <formulate-input
                    v-model.trim="dynamicUrl"
                    class="border-formulate-error-tooltip"
                    type="url"
                    :validation="`required|url|startsWith${
                      isDynamicButtonUrl ? '|ends_with:/' : ''
                    }`"
                    :label="$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.URL')"
                    :placeholder="
                      $t('CHATLYN_MESSAGE_TEMPLATE.PLACEHOLDER.URL')
                    "
                    :validation-messages="{
                      url: $t('CHATLYN_GENERAL.MESSAGES.URL_INVALID'),
                      startsWith: $t(
                        'CHATLYN_GENERAL.MESSAGES.WHATSAPP_DIRECT_LINK'
                      ),
                      ends_with: $t(
                        'CHATLYN_GENERAL.MESSAGES.DYNAMIC_LINKS_END_WITH'
                      ),
                    }"
                    :validation-rules="{
                      startsWith: (event) =>
                        startsWith(event, 'https://wa.me', true),
                    }"
                    :max-length="2000"
                    :tags="TAGS"
                  />
                </input-counter>

                <span v-if="currentTagIndex" class="mt-8 w-6 self-start">
                  {{ TAGS[tagIndex] }}
                </span>
              </div>

              <div
                v-if="linksComponent && linksComponent.buttons.length"
                class="button-link-tag-container w-full"
              >
                <formulate-input
                  v-if="
                    buttonComponent.example && buttonComponent.example.length
                  "
                  v-model="buttonUrlExample"
                  :placeholder="`${TAGS[tagIndex]}`"
                  :label="
                    $t(
                      'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TAG_COMPLETE_EXAMPLE',
                      {
                        TAG: TAGS[tagIndex],
                      }
                    )
                  "
                  type="url"
                  validation="required|noEmoji"
                  :validation-messages="{
                    noEmoji: $t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
                  }"
                  :validation-rules="{
                    noEmoji: noEmoji,
                  }"
                />
              </div>
            </template>

            <template v-else>
              <input-counter :value="buttonText" :max-length="25">
                <formulate-input
                  v-model="buttonText"
                  class="w-full"
                  name="text"
                  type="text"
                  validation="required|noEmoji|uniqueValue"
                  :placeholder="
                    $t('CHATLYN_MESSAGE_TEMPLATE.PLACEHOLDER.BUTTON_TEXT')
                  "
                  :validation-messages="{
                    noEmoji: $t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
                    uniqueValue: $t(
                      'CHATLYN_GENERAL.MESSAGES.UNIQUE_BUTTON_LABEL'
                    ),
                  }"
                  :validation-rules="{
                    noEmoji: noEmoji,
                    uniqueValue: (event) =>
                      uniqueValue(event, buttonId, buttonsComponentTexts),
                  }"
                  :maxlength="25"
                />
              </input-counter>

              <input-counter :value="buttonPhoneNumber" :max-length="20">
                <formulate-input
                  v-model.trim="buttonPhoneNumber"
                  class="w-full"
                  name="phone_number"
                  :label="$t('CHATLYN_MESSAGE_TEMPLATE.LABEL.PHONE_NUMBER')"
                  type="tel"
                  validation="required|noEmoji|starts_with:+"
                  :placeholder="
                    $t('CHATLYN_MESSAGE_TEMPLATE.PLACEHOLDER.PHONE_NUMBER')
                  "
                  :validation-messages="{
                    required: $t('CHATLYN_GENERAL.MESSAGES.PHONE_INVALID'),
                    noEmoji: $t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
                    starts_with: $t(
                      'CHATLYN_GENERAL.MESSAGES.PHONE_NUMBER_STARTS_WITH_PLUS'
                    ),
                  }"
                  :validation-rules="{
                    noEmoji: noEmoji,
                  }"
                  :maxlength="20"
                />
              </input-counter>
            </template>
          </div>
        </template>

        <template v-else>
          <input-with-translation
            v-model="buttonText"
            is-formulate-form-component
            type="text"
            validation="required|noEmoji|uniqueValue"
            :placeholder="
              $t('CHATLYN_MESSAGE_TEMPLATE.PLACEHOLDER.BUTTON_TEXT')
            "
            :validation-messages="{
              noEmoji: $t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
              uniqueValue: $t('CHATLYN_GENERAL.MESSAGES.UNIQUE_BUTTON_LABEL'),
            }"
            :validation-rules="{
              noEmoji: noEmoji,
              uniqueValue: (event) =>
                uniqueValue(event, buttonId, buttonsComponentTexts),
            }"
            :max-length="25"
            :preferred-language="preferredLanguage"
            :disable-translation="!isTranslatable"
          />
        </template>
      </span>

      <button
        v-if="!isConsent"
        class="button clear secondary justify-self-end p-0"
        type="button"
        :disabled="isRemoveButtonDisabled"
        :class="{
          'cursor-pointer': !isRemoveButtonDisabled,
          'cursor-not-allowed opacity-75': isRemoveButtonDisabled,
        }"
        :style="{
          filter: isRemoveButtonDisabled ? 'grayscale(1)' : '',
        }"
        @click="
          isRemoveButtonDisabled
            ? () => {}
            : $emit('remove-button', [buttonComponent.id])
        "
      >
        <fluent-icon icon="dismiss" :size="18" />
      </button>
    </div>
  </div>
</template>

<script>
import InputCounter from '../../../../../../../components/ui/InputCounter.vue';
import { TAGS } from 'dashboard/constants/common';
import {
  noEmoji,
  startsWith,
  uniqueValue,
} from '../../../../../../../helper/formulateValidation';
import InputWithTranslation from '../../../../../../../components/widgets/conversation/translator/InputWithTranslation.vue';

export default {
  name: 'LanguageFormButtonEditor',

  components: { InputCounter, InputWithTranslation },

  props: {
    preferredLanguage: {
      type: [String, undefined],
      default: undefined,
    },

    buttonComponent: {
      type: Object,
      required: true,
    },

    buttonComponentIndex: {
      type: Number,
      required: true,
    },

    selectedButtonTabIndex: {
      type: Number,
      required: true,
    },

    isConsent: {
      type: Boolean,
      default: false,
    },

    linksComponent: {
      type: Object,
      default: () => ({
        body: [],
        buttons: [],
      }),
    },

    buttonsComponentTexts: {
      type: Object,
      default: () => ({}),
    },

    isTranslatable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      TAGS,
    };
  },

  computed: {
    buttonId() {
      return this.buttonComponent?.id;
    },

    buttonText: {
      get() {
        return this.buttonComponent.text;
      },

      set(newValue) {
        this.$emit('change-button-component', {
          ...this.buttonComponent,
          text: newValue,
        });
      },
    },

    buttonPhoneNumber: {
      get() {
        return this.buttonComponent.phone_number;
      },

      set(newValue) {
        this.$emit('change-button-component', {
          ...this.buttonComponent,
          phone_number: newValue,
        });
      },
    },

    buttonUrl: {
      get() {
        return this.buttonComponent.url;
      },

      set(newValue) {
        let newButtonComponent = structuredClone(this.buttonComponent);

        newButtonComponent.url = newValue;

        this.$emit('change-button-component', newButtonComponent);
      },
    },

    buttonUrlType: {
      get() {
        return this.currentTagIndex > 0 ? 'dynamic' : 'static';
      },

      set(newValue) {
        let newButtonComponent = structuredClone(this.buttonComponent);

        switch (newValue) {
          case 'static':
            delete newButtonComponent.example;

            newButtonComponent.url = this.buttonUrl.replaceAll(
              /{{([1-9]{1}|10)}}/gm,
              ''
            );
            break;

          case 'dynamic':
            newButtonComponent.url = `${this.buttonUrl}{{1}}`;

            newButtonComponent.example = [''];

            break;

          default:
            break;
        }

        this.$emit('change-button-component', newButtonComponent);
      },
    },

    isDynamicButtonUrl() {
      return this.buttonUrlType === 'dynamic';
    },

    dynamicUrl: {
      get() {
        return this.buttonUrl.replaceAll(/{{([1-9]{1}|10)}}/gm, '');
      },

      set(newValue) {
        const value = this.isDynamicButtonUrl ? `${newValue}{{1}}` : newValue;

        this.buttonUrl = value;
      },
    },

    buttonUrlExample: {
      get() {
        return this.buttonComponent?.example[0];
      },

      set(newValue) {
        let newButtonComponent = structuredClone(this.buttonComponent);

        newButtonComponent.example[0] = newValue;

        this.$emit('change-button-component', newButtonComponent);
      },
    },

    currentTagIndex() {
      const index = this.buttonUrl.split(/{{([1-9]{1}|10)}}/gm)[1];
      return index || null;
    },

    tagIndex() {
      return this.currentTagIndex ? this.currentTagIndex - 1 : 0;
    },

    buttonLinksLength() {
      return this.linksComponent.buttons?.length || 0;
    },

    isRemoveButtonDisabled() {
      return this.isConsent && this.buttonComponentIndex === 0;
    },
  },

  methods: {
    noEmoji,
    startsWith,
    uniqueValue,

    isValidPhoneNumber(phoneNumber) {
      // Define the regex pattern for E.164 phone numbers
      const pattern = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      // Use the test() method of the RegExp object to match the phone number against the pattern
      return pattern.test(phoneNumber);
    },
  },
};
</script>
<!-- todo: matthias apply globally in the right css file -->
<style>
select:disabled {
  background-color: var(--s-100);
}
</style>
