<template>
  <div class="flex flex-col items-start modal-header">
    <img v-if="headerImage" :src="headerImage" alt="No image" />
    <div class="flex items-center gap-1 modal-header-title">
      <div
        v-if="headerTitle"
      >
        {{ headerTitle }}
      </div>
      <fluent-icon
        v-if="toolTipText"
        v-tooltip.top-end="toolTipText"
        class="select-none m-1"
        size="16"
        icon="info"
      />
    </div>
    <p
      v-if="headerContent"
      ref="modalHeaderContent"
    >
      {{ headerContent }}
      <span
        v-if="headerContentValue"
        class="font-medium text-sm text-gray-600 dark:text-gray-300"
      >
        {{ headerContentValue }}
      </span>
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    toolTipText: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    headerContentValue: {
      type: String,
      default: '',
    },
    headerImage: {
      type: String,
      default: '',
    },
  },
};
</script>
