<template>
  <div class="message-template-form-switch-container relative">
    <woot-switch
      :id="`message-template-form-show-HEADER-${currentTranslation.language}-switch`"
      v-model="showHeader"
      class="message-template-form-switch"
      :label="$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.HEADER')"
      optional-text="(optional)"
    />

    <div>
      <slide-animation :is-shown="showHeader">
        <div v-if="showHeader">
          <woot-tabs
            :index="selectedHeaderTabIndex"
            :border="false"
            @change="selectHeaderTab"
          >
            <woot-tabs-item
              v-for="headerTab in headerTabs"
              :key="headerTab.key"
              :name="headerTab.name"
              :icon="headerTab.icon"
              :show-badge="false"
            />
          </woot-tabs>

          <transition name="fade">
            <div v-if="selectedHeaderTabIndex === 0" class="mt-2">
              <input-with-translation
                v-model="headerValue"
                is-formulate-form-component
                type="text"
                validation="required|noEmoji"
                :validation-messages="{
                  noEmoji: $t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
                }"
                :validation-rules="{
                  noEmoji: noEmoji,
                }"
                :placeholder="
                  $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.HEADER_INFO')
                "
                :max-length="20"
                :preferred-language="currentTranslation.language"
                :disable-translation="!isTranslatable"
              />
            </div>

            <div v-if="selectedHeaderTabIndex === 1" class="mt-2">
              <drag-drop-file-upload
                v-model="headerValue"
                is-formulate-input
                :accept="ALLOWED_IMAGE_TYPES"
                :extensions="ALLOWED_IMAGE_EXTENSIONS"
              />
            </div>

            <div v-if="selectedHeaderTabIndex === 2" class="mt-2">
              <drag-drop-file-upload
                v-model="headerValue"
                is-formulate-input
                is-video
                :accept="ALLOWED_VIDEO_TYPES"
                :extensions="ALLOWED_VIDEO_EXTENSIONS"
              />
            </div>
          </transition>
        </div>
      </slide-animation>
    </div>

    <div
      v-if="isUploading"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>
  </div>
</template>

<script>
import DragDropFileUpload from 'dashboard/components/ui/DragDropFileUpload.vue';
import WootSwitch from 'shared/components/ui/Switch.vue';
import FileUpload from '../../../../../../../api/fileUpload';
import alertMixin from 'shared/mixins/alertMixin';
import InputWithTranslation from '../../../../../../../components/widgets/conversation/translator/InputWithTranslation.vue';
import SlideAnimation from '../../../../../../../components/animations/SlideAnimation.vue';
import { noEmoji } from '../../../../../../../helper/formulateValidation';

const MAX_IMAGE_SIZE = 5; // mb
const MAX_VIDEO_SIZE = 16; // mb
const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png'];
const ALLOWED_VIDEO_TYPES = ['video/mp4', 'video/3gpp'];
const ALLOWED_IMAGE_EXTENSIONS = ['png', 'jpeg'];
const ALLOWED_VIDEO_EXTENSIONS = ['mp4', '3gp'];

export default {
  name: 'LanguageFormHeaderEditor',
  components: {
    DragDropFileUpload,
    WootSwitch,
    InputWithTranslation,
    SlideAnimation,
  },
  mixins: [alertMixin],
  props: {
    currentTranslation: {
      type: Object,
      required: true,
    },

    isTranslatable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      MAX_IMAGE_SIZE,
      MAX_VIDEO_SIZE,
      ALLOWED_VIDEO_TYPES,
      ALLOWED_IMAGE_TYPES,
      ALLOWED_IMAGE_EXTENSIONS,
      ALLOWED_VIDEO_EXTENSIONS,
      isUploading: false,
    };
  },
  computed: {
    headerTabs() {
      return [
        {
          key: 'text',
          name: this.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.HEADER_TEXT'),
          icon: 'text',
        },
        {
          key: 'image',
          name: this.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.HEADER_IMG'),
          icon: 'image',
        },
        {
          key: 'video',
          name: this.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.HEADER_VIDEO'),
          icon: 'video',
        },
      ];
    },

    showHeader: {
      get() {
        return this.currentTranslation.components.some(
          (component) => component.type === 'HEADER'
        );
      },
      set(newValue) {
        let newComponents = structuredClone(this.currentTranslation.components);

        if (newValue) {
          newComponents.push(
            this.getDefaultHeaderValue(this.selectedHeaderTabIndex)
          );
        } else {
          newComponents = newComponents.filter(
            (component) => component.type !== 'HEADER'
          );
        }

        this.changeCurrentTranslation({
          ...this.currentTranslation,
          components: newComponents,
        });
      },
    },

    headerComponent: {
      get() {
        return this.currentTranslation.components.find(
          (component) => component.type === 'HEADER'
        );
      },
      set(newValue) {
        this.changeCurrentTranslation({
          ...this.currentTranslation,
          components: this.currentTranslation.components.map((component) =>
            component.type === 'HEADER' ? newValue : component
          ),
        });
      },
    },

    selectedHeaderTabIndex: {
      get() {
        if (!this.headerComponent || !this.headerComponent.format) {
          return 0;
        }

        switch (this.headerComponent.format) {
          case 'TEXT':
            return 0;

          case 'IMAGE':
            return 1;

          case 'VIDEO':
            return 2;

          default:
            return 0;
        }
      },

      set(newValue) {
        if (!this.headerComponent || !this.headerComponent.format) {
          return;
        }

        this.headerComponent = this.getDefaultHeaderValue(newValue);
      },
    },

    selectedHeaderTab() {
      return this.headerTabs[this.selectedHeaderTabIndex];
    },

    headerValue: {
      get() {
        if (!this.headerComponent || !this.headerComponent.format) {
          return null;
        }

        switch (this.selectedHeaderTabIndex) {
          case 0:
            return this.headerComponent.text;

          case 1:
          case 2:
            return typeof this.headerComponent.example.header_handle[0] ===
              'string'
              ? { name: this.headerComponent.example.header_handle[0] }
              : this.headerComponent.example.header_handle[0];

          default:
            return null;
        }
      },

      async set(newValue) {
        if (!this.headerComponent || !this.headerComponent.format) {
          return;
        }

        if (!['string', 'object'].includes(typeof newValue)) {
          return;
        }

        if (typeof newValue === 'object' && !newValue.name) {
          return;
        }

        let response;

        switch (this.headerComponent.format) {
          case 'TEXT':
            this.headerComponent.text = newValue;
            break;

          case 'IMAGE':
            if (
              (newValue.size / 1024 / 1024).toFixed(4) > this.MAX_IMAGE_SIZE
            ) {
              this.showAlert(
                this.$t(
                  'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ERROR.IMAGE_MAX_SIZE',
                  {
                    MAX_SIZE: `${this.MAX_IMAGE_SIZE} MB`,
                  }
                )
              );
              break;
            }

            // eslint-disable-next-line no-case-declarations
            response = await this.uploadFile(newValue);

            break;

          case 'VIDEO':
            if (
              (newValue.size / 1024 / 1024).toFixed(4) > this.MAX_VIDEO_SIZE
            ) {
              this.showAlert(
                this.$t(
                  'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ERROR.VIDEO_MAX_SIZE',
                  {
                    MAX_SIZE: `${this.MAX_VIDEO_SIZE} MB`,
                  }
                )
              );
              break;
            }

            if (!this.ALLOWED_VIDEO_TYPES.includes(newValue.type)) {
              this.showAlert(
                this.$t(
                  'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ERROR.VIDEO_TYPE',
                  {
                    FILE_TYPES: this.ALLOWED_VIDEO_TYPES.join(', '),
                  }
                )
              );
              break;
            }

            response = await this.uploadFile(newValue);
            break;

          default:
            break;
        }

        if (!response?.data?.fileUrl) {
          return;
        }

        this.headerComponent.example.header_handle = [
          response.data ? response.data.fileUrl : null,
        ];
      },
    },
  },
  methods: {
    noEmoji,

    selectHeaderTab(index) {
      this.selectedHeaderTabIndex = index;
    },

    getDefaultHeaderValue(index) {
      switch (index) {
        case 0:
          return {
            type: 'HEADER',
            format: 'TEXT',
            text: '',
          };

        case 1:
          return {
            type: 'HEADER',
            format: 'IMAGE',
            example: {
              header_handle: [null],
            },
          };

        case 2:
          return {
            type: 'HEADER',
            format: 'VIDEO',
            example: {
              header_handle: [null],
            },
          };

        default:
          return {
            type: 'HEADER',
            format: 'TEXT',
            text: '',
          };
      }
    },

    async uploadFile(file) {
      try {
        this.isUploading = true;
        return await FileUpload.uploadFile(file);
      } catch (error) {
        //
        return null;
      } finally {
        this.isUploading = false;
      }
    },

    changeCurrentTranslation(newValue) {
      this.$emit('change-current-translation', newValue);
    },
  },
};
</script>

<style></style>
