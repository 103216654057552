<template>
  <div class="chatlyn-container mx-auto my-auto text-center lg:max-w-2xl">
    <div class="text-3xl font-light text-gray-400 dark:text-gray-600">
      {{ $t('CHATLYN_AUTOMATIONS.EMPTY_STATE.TITLE') }}
    </div>

    <div class="my-3 text-gray-500 dark:text-gray-400">
      {{ $t('CHATLYN_AUTOMATIONS.EMPTY_STATE.DESC') }}
    </div>

    <woot-button
      type="button"
      color-scheme="success"
      icon="add-circle"
      data-testid="create-entrypoint"
      @click="$emit('toggle-is-create-modal-shown')"
    >
      {{ $t('CHATLYN_AUTOMATIONS.NEW_ENTRYPOINT') }}
    </woot-button>
  </div>
</template>

<script>
export default {
  name: 'EntrypointsEmptyState',
};
</script>
