import types from '../../mutation-types';

export const mutations = {
  [types.SET_FOLDERS]($state, payload) {
    $state.records = payload;
  },

  [types.SET_FOLDER]($state, payload) {
    $state.record = payload;
  },

  [types.SET_FOLDERS_STATE]($state, newState) {
    $state.state = { ...$state.state, ...newState };
  },

  [types.SET_FOLDER_ENTRYPOINTS]($state, payload) {
    $state.entrypoints = payload;
  },

  [types.SET_FOLDERS_UI_FLAGS]($state, payload) {
    $state.uiFlags = { ...$state.uiFlags, ...payload };
  },

  [types.CLEAR_FOLDER]($state) {
    $state.record = {};
  },

  [types.CLEAR_FOLDERS]($state) {
    $state.records = [];
  },

  [types.CLEAR_FOLDER_ENTRYPOINTS]($state) {
    $state.entrypoints = [];
  },
};
