export const JSON_SEPARATOR = /[.[\]]+/g;
export const DATE_YEAR_LAST = /\d{1,2}[-/\s.]\d{1,2}[-/\s.]\d{2,4}/g;
export const DATE_YEAR_FIRST = /\d{4}[-/\s.]\d{1,2}[-/\s.]\d{1,2}/g;

export const UNIX_TIME = 'x';
export const ISO_STRING = 'YYYY-MM-DD HH:mm:ss.SSS';
export const CUSTOM_FORMAT = 'custom';

export const DATES_FORMATS = [
  UNIX_TIME,
  ISO_STRING,
  'DD.MM.YYYY',
  'DD.MM.YYYY HH:mm',
  'MM.DD.YYYY',
  'MM.DD.YYYY HH:mm',
  'DD.MM.YY',
  'DD.MM.YY HH:mm',
  'MM.DD.YY',
  'MM.DD.YY HH:mm',
  'YYYY.MM.DD',
  'YYYY.MM.DD HH:mm',
  'YYYY.DD.MM',
  'YYYY.DD.MM HH:mm',
];
