var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2 rounded-lg border bg-gray-50 py-2 dark:border-gray-700 dark:bg-gray-700/30",class:{
    'px-3': !_vm.isConsent,
    'px-2': _vm.isConsent,
  }},[_c('div',{staticClass:"flex flex-row flex-nowrap items-start gap-2"},[(!_vm.isConsent)?_c('button',{staticClass:"draggable-handle button clear secondary justify-self-end p-0",attrs:{"type":"button"}},[_c('fluent-icon',{staticClass:"cursor-move text-gray-500",attrs:{"icon":"re-order-dots-vertical","size":18}})],1):_vm._e(),_vm._v(" "),_c('span',{staticClass:"w-full self-stretch"},[(_vm.selectedButtonTabIndex === 0)?[_c('div',{staticClass:"flex w-full flex-col flex-nowrap items-stretch gap-2"},[(_vm.buttonComponent.type === 'URL')?[_c('input-counter',{attrs:{"value":_vm.buttonText,"max-length":25}},[_c('formulate-input',{staticClass:"w-full",attrs:{"name":"text","type":"text","validation":"required|noEmoji|uniqueValue","placeholder":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.PLACEHOLDER.BUTTON_TEXT'),"validation-messages":{
                  noEmoji: _vm.$t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
                  uniqueValue: _vm.$t(
                    'CHATLYN_GENERAL.MESSAGES.UNIQUE_BUTTON_LABEL'
                  ),
                },"validation-rules":{
                  noEmoji: _vm.noEmoji,
                  uniqueValue: (event) =>
                    _vm.uniqueValue(event, _vm.buttonId, _vm.buttonsComponentTexts),
                },"maxlength":25},model:{value:(_vm.buttonText),callback:function ($$v) {_vm.buttonText=$$v},expression:"buttonText"}})],1),_vm._v(" "),_c('div',{staticClass:"flex w-full flex-row flex-nowrap items-center gap-2"},[_c('formulate-input',{staticStyle:{"width":"calc(40% - 0.25rem)"},attrs:{"type":"select","options":{
                  static: _vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.STATIC'),
                  dynamic: _vm.$t(
                    'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.DYNAMIC'
                  ),
                },"label":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.URL_TYPE'),"validation":"required"},model:{value:(_vm.buttonUrlType),callback:function ($$v) {_vm.buttonUrlType=$$v},expression:"buttonUrlType"}}),_vm._v(" "),_c('input-counter',{staticClass:"flex-1",attrs:{"value":_vm.buttonComponent.url,"max-length":2000}},[_c('formulate-input',{staticClass:"border-formulate-error-tooltip",attrs:{"type":"url","validation":`required|url|startsWith${
                    _vm.isDynamicButtonUrl ? '|ends_with:/' : ''
                  }`,"label":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.URL'),"placeholder":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.PLACEHOLDER.URL'),"validation-messages":{
                    url: _vm.$t('CHATLYN_GENERAL.MESSAGES.URL_INVALID'),
                    startsWith: _vm.$t(
                      'CHATLYN_GENERAL.MESSAGES.WHATSAPP_DIRECT_LINK'
                    ),
                    ends_with: _vm.$t(
                      'CHATLYN_GENERAL.MESSAGES.DYNAMIC_LINKS_END_WITH'
                    ),
                  },"validation-rules":{
                    startsWith: (event) =>
                      _vm.startsWith(event, 'https://wa.me', true),
                  },"max-length":2000,"tags":_vm.TAGS},model:{value:(_vm.dynamicUrl),callback:function ($$v) {_vm.dynamicUrl=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"dynamicUrl"}})],1),_vm._v(" "),(_vm.currentTagIndex)?_c('span',{staticClass:"mt-8 w-6 self-start"},[_vm._v("\n                "+_vm._s(_vm.TAGS[_vm.tagIndex])+"\n              ")]):_vm._e()],1),_vm._v(" "),(_vm.linksComponent && _vm.linksComponent.buttons.length)?_c('div',{staticClass:"button-link-tag-container w-full"},[(
                  _vm.buttonComponent.example && _vm.buttonComponent.example.length
                )?_c('formulate-input',{attrs:{"placeholder":`${_vm.TAGS[_vm.tagIndex]}`,"label":_vm.$t(
                    'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TAG_COMPLETE_EXAMPLE',
                    {
                      TAG: _vm.TAGS[_vm.tagIndex],
                    }
                  ),"type":"url","validation":"required|noEmoji","validation-messages":{
                  noEmoji: _vm.$t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
                },"validation-rules":{
                  noEmoji: _vm.noEmoji,
                }},model:{value:(_vm.buttonUrlExample),callback:function ($$v) {_vm.buttonUrlExample=$$v},expression:"buttonUrlExample"}}):_vm._e()],1):_vm._e()]:[_c('input-counter',{attrs:{"value":_vm.buttonText,"max-length":25}},[_c('formulate-input',{staticClass:"w-full",attrs:{"name":"text","type":"text","validation":"required|noEmoji|uniqueValue","placeholder":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.PLACEHOLDER.BUTTON_TEXT'),"validation-messages":{
                  noEmoji: _vm.$t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
                  uniqueValue: _vm.$t(
                    'CHATLYN_GENERAL.MESSAGES.UNIQUE_BUTTON_LABEL'
                  ),
                },"validation-rules":{
                  noEmoji: _vm.noEmoji,
                  uniqueValue: (event) =>
                    _vm.uniqueValue(event, _vm.buttonId, _vm.buttonsComponentTexts),
                },"maxlength":25},model:{value:(_vm.buttonText),callback:function ($$v) {_vm.buttonText=$$v},expression:"buttonText"}})],1),_vm._v(" "),_c('input-counter',{attrs:{"value":_vm.buttonPhoneNumber,"max-length":20}},[_c('formulate-input',{staticClass:"w-full",attrs:{"name":"phone_number","label":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.LABEL.PHONE_NUMBER'),"type":"tel","validation":"required|noEmoji|starts_with:+","placeholder":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.PLACEHOLDER.PHONE_NUMBER'),"validation-messages":{
                  required: _vm.$t('CHATLYN_GENERAL.MESSAGES.PHONE_INVALID'),
                  noEmoji: _vm.$t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
                  starts_with: _vm.$t(
                    'CHATLYN_GENERAL.MESSAGES.PHONE_NUMBER_STARTS_WITH_PLUS'
                  ),
                },"validation-rules":{
                  noEmoji: _vm.noEmoji,
                },"maxlength":20},model:{value:(_vm.buttonPhoneNumber),callback:function ($$v) {_vm.buttonPhoneNumber=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"buttonPhoneNumber"}})],1)]],2)]:[_c('input-with-translation',{attrs:{"is-formulate-form-component":"","type":"text","validation":"required|noEmoji|uniqueValue","placeholder":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.PLACEHOLDER.BUTTON_TEXT'),"validation-messages":{
            noEmoji: _vm.$t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
            uniqueValue: _vm.$t('CHATLYN_GENERAL.MESSAGES.UNIQUE_BUTTON_LABEL'),
          },"validation-rules":{
            noEmoji: _vm.noEmoji,
            uniqueValue: (event) =>
              _vm.uniqueValue(event, _vm.buttonId, _vm.buttonsComponentTexts),
          },"max-length":25,"preferred-language":_vm.preferredLanguage,"disable-translation":!_vm.isTranslatable},model:{value:(_vm.buttonText),callback:function ($$v) {_vm.buttonText=$$v},expression:"buttonText"}})]],2),_vm._v(" "),(!_vm.isConsent)?_c('button',{staticClass:"button clear secondary justify-self-end p-0",class:{
        'cursor-pointer': !_vm.isRemoveButtonDisabled,
        'cursor-not-allowed opacity-75': _vm.isRemoveButtonDisabled,
      },style:({
        filter: _vm.isRemoveButtonDisabled ? 'grayscale(1)' : '',
      }),attrs:{"type":"button","disabled":_vm.isRemoveButtonDisabled},on:{"click":function($event){_vm.isRemoveButtonDisabled
          ? () => {}
          : _vm.$emit('remove-button', [_vm.buttonComponent.id])}}},[_c('fluent-icon',{attrs:{"icon":"dismiss","size":18}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }