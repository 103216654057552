export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },

  getIsMessageTemplateLoading($state) {
    return Boolean(
      $state.isCreating ||
        $state.isFetching ||
        $state.isUpdating ||
        $state.isMerging ||
        $state.isDeleting
    );
  },

  getMessageTemplate($state) {
    return $state.record;
  },

  getMessageTemplates($state) {
    return $state.records;
  },

  getMessageTemplateNames($state) {
    return $state.templateNames;
  },

  getMessageTemplatesCount($state) {
    return $state.templatesCount;
  },

  getApprovedMessageTemplates($state) {
    return $state.records.templates.filter((record) =>
      record?.translations?.some((translation) =>
        ['approved', 'APPROVED'].includes(translation.status)
      )
    );
  },

  getMessageTemplatesEntrypoints($state) {
    return $state.entrypoints;
  },

  getScheduledMessageTemplates($state) {
    return $state.scheduledRecords;
  },

  getScheduledMessageTemplate($state) {
    return $state.scheduledRecord;
  },
};
