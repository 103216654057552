import types from '../../mutation-types';
import AutomationsAPI from '../../../api/automations';

export const actions = {
  getAllEntrypoint: async ({ commit }, { accountId }) => {
    try {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_ALL_ENTRYPOINT);
      const { data } = await AutomationsAPI.getAllEntrypoint(accountId);

      if (!data.entrypoints) {
        throw new Error();
      }

      commit(types.SET_ALL_ENTRYPOINT, data.entrypoints);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isFetching: false });
    }
  },

  getEntrypoint: async ({ commit }, { accountId, id }) => {
    try {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_ENTRYPOINT);
      const { data } = await AutomationsAPI.getEntrypoint(accountId, id);

      if (!data) {
        throw new Error();
      }

      commit(types.SET_ENTRYPOINT, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isFetching: false });
    }
  },

  createEntrypoint: async ({ commit }, { accountId, entrypoint }) => {
    let result;

    try {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isCreating: true });
      result = await AutomationsAPI.createEntrypoint(accountId, entrypoint);
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      throw new Error(message);
    } finally {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isCreating: false });
    }

    return result;
  },

  updateEntrypoint: async ({ commit }, { accountId, entrypoint }) => {
    let result;

    try {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isUpdating: true });
      result = await AutomationsAPI.updateEntrypoint(accountId, entrypoint);
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      throw new Error(message);
    } finally {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isUpdating: false });
    }

    return result;
  },

  deleteEntrypoint: async ({ commit }, { accountId, id }) => {
    try {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isDeleting: true });
      await AutomationsAPI.deleteEntrypoint(accountId, id);
      commit(types.CLEAR_ENTRYPOINT);
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      throw new Error(message);
    } finally {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isDeleting: false });
    }
  },

  //
  createEntrypointAutomation: async (
    { commit },
    { accountId, id, automation, callback }
  ) => {
    let isSuccess = true;

    try {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isJourneyLoading: true });
      const { data } = await AutomationsAPI.createEntrypointAutomation(
        accountId,
        id,
        automation
      );

      if (!data.automation) {
        throw new Error();
      }

      if (typeof callback === 'function') {
        // eslint-disable-next-line no-underscore-dangle
        callback(data.automation._id);
      }
    } catch (error) {
      isSuccess = false;
    } finally {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isJourneyLoading: false });
    }

    return isSuccess;
  },

  deleteEntrypointAutomation: async (
    { commit },
    { accountId, id, automationId }
  ) => {
    try {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isJourneyLoading: true });
      await AutomationsAPI.deleteEntrypointAutomation(
        accountId,
        id,
        automationId
      );
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      throw new Error(message);
    } finally {
      commit(types.SET_ENTRYPOINT_UI_FLAGS, { isJourneyLoading: false });
    }
  },

  duplicate: async (_, { accountId, entrypointId, duplicated }) => {
    const isJourneyDuplicated = duplicated.id !== entrypointId;
    try {
      if (isJourneyDuplicated) {
        return await AutomationsAPI.duplicateJourney(
          accountId,
          entrypointId,
          duplicated
        );
      }
      return await AutomationsAPI.duplicateEntrypoint(accountId, duplicated);
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      throw new Error(message);
    }
  },
};
