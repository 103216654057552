var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"input-counter-container",staticClass:"input-counter-container",class:{
    'input-counter-container--visible': !_vm.isHidden,
  },style:({
    '--inputPaddingRight': `${_vm.inputPaddingRight}px`,
  })},[_vm._t("default"),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHidden),expression:"!isHidden"}],ref:"input-counter",staticClass:"input-counter",style:({
      right: `${_vm.right}px`,
      bottom: `${_vm.bottom}px`,
    })},[_vm._v("\n    "+_vm._s(_vm.value ? _vm.value.length : 0)+"/"+_vm._s(_vm.maxLength)+"\n  ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }