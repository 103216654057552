// import Vue from 'vue';
import types from '../../mutation-types';

export const mutations = {
  [types.SET_DEEPL_UI_FLAGS](state, payload) {
    state.uiFlags = { ...state.uiFlags, ...payload };
  },

  [types.SET_DEEPL_LANGUAGES](state, payload) {
    state.languages = payload;
  },
  [types.CLEAR_DEEPL_LANGUAGES](state) {
    state.uiFlags = [];
  },
};
