export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },

  getIsLoading($state) {
    return Boolean(
      $state.isCreating ||
        $state.isFetching ||
        $state.isUpdating ||
        $state.isMerging ||
        $state.isDeleting
    );
  },

  getIsAutomationLoading($state) {
    return $state.uiFlags.isJourneyLoading;
  },

  getAllEntrypoint($state) {
    return $state.records;
  },

  getEntrypoint($state) {
    return $state.record;
  },
};
