var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.messageTemplate)?_c('div',{staticClass:"w-full"},[_c('language-bar',{attrs:{"has-add-button":"","has-right-side":"","has-color-mark":_vm.hasLanguageBarColorMark,"has-remove-button":_vm.hasRemoveButton,"languages":_vm.languages,"main-language":_vm.mainLanguage,"current-language":_vm.currentLanguage,"is-list-view-enabled":_vm.isListViewEnabled,"available-languages":_vm.availableLanguagesOptions,"has-additional-text":false,"translations":_vm.messageTemplate.translations},on:{"change":_vm.selectCurrentLanguage,"handle-add-button-click":_vm.addNewLanguage,"handle-remove-button-click":_vm.toggleShowDeleteLanguageModal}},[_c('template',{slot:"right-side"})],2),_vm._v(" "),(_vm.currentTranslation)?_c('div',{staticClass:"message-template-language-step-form"},[_vm._l((_vm.messageTemplate.translations),function(translation){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isListViewEnabled ||
          _vm.currentTranslationLanguage === translation.language
        ),expression:"\n          isListViewEnabled ||\n          currentTranslationLanguage === translation.language\n        "}],key:translation.language},[(
            translation &&
            translation.language !== _vm.mainLanguage &&
            (!translation.locked || translation.status === 'rejected')
          )?_c('div',{staticClass:"message-template-language-step-form__not-main-panel callout primary"},[_c('span',{staticClass:"message-template-language-step-form__not-main-panel__column"},[_c('fluent-icon',{attrs:{"icon":"document-copy","size":16,"type":"solid"}}),_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(_vm.$t(
                  'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.MESSAGE',
                  {
                    LANGUAGE: _vm.mainLanguage,
                  }
                ))+"\n            ")])],1),_vm._v(" "),_c('span',{staticClass:"message-template-language-step-form__not-main-panel__column"},[_c('woot-button',{attrs:{"class-names":"message-template-form-copy-button","type":"button","size":"small","variant":"clear","color-scheme":"primary"},on:{"click":function($event){return _vm.copyFromMainToSelectedTranslation(translation.language)}}},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.INSERT'
                ))+"\n            ")]),_vm._v(" "),_c('woot-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTranslatable),expression:"isTranslatable"}],attrs:{"class-names":"message-template-form-copy-button","type":"button","size":"small"},on:{"click":function($event){return _vm.copyFromMainToSelectedTranslation(translation.language, true)}}},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.INSERT_TRANSLATE'
                ))+"\n            ")])],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex w-full flex-nowrap gap-5 pt-4",class:{
            'flex-row': !_vm.isListViewEnabled,
            'flex-col': _vm.isListViewEnabled,
          }},[_c('formulate-form',{directives:[{name:"show",rawName:"v-show",value:(
              translation &&
              (!translation.locked || translation.status === 'rejected')
            ),expression:"\n              translation &&\n              (!translation.locked || translation.status === 'rejected')\n            "}],staticClass:"sticky top-4 w-full flex-1",style:({
              padding: 0,
              alignSelf: 'flex-start',
            }),attrs:{"name":`create-message-template-form-${translation.language}`}},[(_vm.isListViewEnabled)?_c('div',{staticClass:"sticky top-0 mb-2 w-full border-b border-gray-100 bg-white py-1 text-base",style:({
                zIndex: 11,
              })},[_vm._v("\n              "+_vm._s(translation.language)+"\n            ")]):_vm._e(),_vm._v(" "),_c('languages-form',{attrs:{"current-translation":translation,"links-component":translation.links,"category":_vm.messageTemplate.category,"is-translation-attributes-synced":_vm.isTranslationAttributesSynced,"message-template":_vm.messageTemplate,"message-template-type":_vm.messageTemplateType,"is-translatable":_vm.isTranslatable},on:{"change-links-component":_vm.changeLinksComponent,"change-current-translation":_vm.changeCurrentTranslation,"toggle-is-translation-attributes-synced":_vm.toggleIsTranslationAttributesSynced,"change-message-template":_vm.changeMessageTemplate}})],1),_vm._v(" "),(
              translation &&
              translation.locked &&
              translation.status !== 'rejected'
            )?_c('div',{staticClass:"message-template-language-step-form__message callout flex flex-grow flex-col items-center justify-center gap-2"},[_c('fluent-icon',{staticClass:"flex-shrink-0",attrs:{"icon":"warning","size":20}}),_vm._v(" "),_c('span',{staticClass:"text-sm"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ALREADY_SUBMITTED_TRANSLATION'
                ))+"\n            ")])],1):_vm._e(),_vm._v(" "),(!_vm.isListViewEnabled)?_c('message-preview',{attrs:{"current-translation":_vm.currentTranslation,"empty-text":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.FILL_TO_PREVIEW')}}):_vm._e()],1)])]})],2):_vm._e(),_vm._v(" "),(_vm.showDeleteLanguageModal)?_c('woot-delete-modal',{attrs:{"show":_vm.showDeleteLanguageModal,"title":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.TITLE', {
        LANGUAGE: _vm.currentLanguage.toUpperCase(),
      }),"message":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.MESSAGE'),"confirm-text":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.ACCEPT'),"reject-text":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.DECLINE'),"on-confirm":_vm.deleteCurrentLanguage,"on-close":_vm.toggleShowDeleteLanguageModal,"is-loading":_vm.isDeleting},on:{"update:show":function($event){_vm.showDeleteLanguageModal=$event}}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }