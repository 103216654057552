<template>
  <div class="ch-multiselect flex w-full flex-col">
    <div class="ch-multiselect__title flex items-center">
      <label v-if="!$slots.title" class="text-sm">{{ title }}</label>
      <slot name="title" />
    </div>
    <vue-multiselect
      v-model="computedValue"
      class="w-full"
      :options="options"
      :multiple="multiple"
      :max-height="maxHeight"
      :close-on-select="closeOnSelect"
      :clear-on-select="clearOnSelect"
      :preserve-search="preserveSearch"
      :hide-selected="hideSelected"
      :placeholder="placeholder"
      :label="label"
      :track-by="trackBy"
      :internal-search="internalSearch"
      :loading="isLoading"
      :disabled="isDisabled"
      @blur="handleBlur"
      @search-change="handleSearchChange"
    />

    <slot name="help" />
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
  name: 'MultiselectCh',
  components: {
    VueMultiselect,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Number, String, Object, Array, null],
      required: true,
    },
    options: {
      type: [Array],
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    preserveSearch: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'name',
    },
    trackBy: {
      type: String,
      default: 'name',
    },
    maxHeight: {
      type: Number,
      default: 300,
    },
    internalSearch: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value || '';
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
  methods: {
    handleSearchChange(newValue) {
      this.$emit('search-change', newValue);
    },

    handleBlur() {
      this.$emit('blur');
    },
  },
};
</script>

<style scoped></style>
