import types from '../../mutation-types';
import Vue from 'vue';

export const mutations = {
  [types.SET_LISTS](state, payload) {
    state.records = payload;
  },
  [types.SET_EXCLUDED_CONTACTS](state, payload) {
    state.excludedContacts = payload;
  },
  [types.SET_NEW_LISTS](state, payload) {
    state.lists = state.lists.push(payload);
  },
  [types.SET_LIST](state, payload) {
    state.record = payload;
  },
  [types.EDIT_CONTACT_IN_LIST](state, data) {
    const index = state.record.contacts.findIndex(
      (contact) => contact.id === data.id
    );
    if (index !== -1) {
      Vue.set(state.record.contacts, index, data);
    }
  },
  [types.SET_LIST_META](state, payload) {
    state.meta = payload;
  },
  [types.SET_LIST_LINKS](state, payload) {
    state.links = payload;
  },
  [types.SET_LIST_ENTRYPOINTS](state, payload) {
    state.entrypoints = payload;
  },

  [types.CLEAR_LISTS](state) {
    state.records = [];
  },
  [types.CLEAR_LIST](state) {
    state.record = {};
  },
  [types.CLEAR_LISTS_STATE](state) {
    state.records = [];
    state.record = {};
  },
  [types.CLEAR_LIST_META](state) {
    state.meta = {};
  },
  [types.CLEAR_LIST_LINKS](state) {
    state.links = {};
  },
  [types.SET_LISTS_UI_FLAGS]($state, payload) {
    $state.uiFlags = { ...$state.uiFlags, ...payload };
  },
  [types.CLEAR_LIST_ENTRYPOINTS](state) {
    state.entrypoints = [];
  },

  /* CONDITIONS */
  [types.SET_LIST_CONDITIONS]($state, payload) {
    $state.appliedListConditions = payload;
  },

  [types.SET_LIST_CONDITION]($state, { listId, condition }) {
    $state.appliedListConditions = {
      ...$state.appliedListConditions,
      [listId]: condition,
    };
  },

  [types.CLEAR_LIST_CONDITIONS]($state) {
    $state.appliedListConditions = {};
  },

  [types.CLEAR_LIST_CONDITION]($state, listId) {
    const newConditions = structuredClone($state.appliedListConditions);

    delete newConditions[listId];

    $state.appliedListConditions = newConditions;
  },
};
