<template>
  <div class="flex w-full flex-row flex-nowrap gap-2">
    <input-counter
      class="flex-1"
      :value="messageTemplateTitle"
      :max-length="160"
    >
      <formulate-input
        v-model="messageTemplateTitle"
        type="text"
        :label="$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.INTERNAL_TITLE')"
        validation="required"
        :maxlength="160"
      />
    </input-counter>

    <template v-if="!isSmsMessageTemplate">
      <formulate-input
        v-model="messageTemplatePhoneNumber"
        type="select"
        validation="required"
        :label="$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_WHATSAPP_INBOX')"
        :options="whatsAppInboxesOptions"
      />

      <formulate-input
        v-model="messageTemplateCategory"
        class="flex-1"
        :options="messageTemplateCategories"
        type="select"
        :label="$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TEMPLATE_TYPE')"
        validation="required"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputCounter from '../../../../../../components/ui/InputCounter.vue';
import { MESSAGE_TEMPLATES_CHANNEL_TYPE } from '../../../constants';

export default {
  name: 'MainInfoStep',
  components: { InputCounter },
  props: {
    messageTemplate: {
      type: Object,
      required: true,
    },

    messageTemplateType: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      whatsAppInboxes: 'inboxes/getWhatsAppInboxes',
    }),

    isSmsMessageTemplate() {
      return this.messageTemplateType === MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS;
    },

    messageTemplateCategories() {
      return ['MARKETING', 'UTILITY', 'CONSENT'].map((key) => ({
        label: this.$t(`CHATLYN_MESSAGE_TEMPLATE.CATEGORY.${key}`),
        value: key,
      }));
    },

    whatsAppInboxesOptions() {
      return this.whatsAppInboxes.map((whatsAppInbox) =>
        whatsAppInbox.phone_number
          ? {
              label: `${whatsAppInbox.name} (${whatsAppInbox.phone_number})`,
              value: whatsAppInbox.phone_number.replace(/\+/g, ''),
            }
          : {
              label: `${whatsAppInbox.name} (+${whatsAppInbox?.additional_attributes?.number})`,
              value: whatsAppInbox?.additional_attributes?.number,
            }
      );
    },

    messageTemplateTitle: {
      get() {
        return this.messageTemplate.title;
      },

      set(newValue) {
        this.changeMessageTemplate({
          ...this.messageTemplate,
          title: newValue,
        });
      },
    },

    messageTemplatePhoneNumber: {
      get() {
        return this.messageTemplate.phoneNumber;
      },

      set(newValue) {
        this.changeMessageTemplate({
          ...this.messageTemplate,
          phoneNumber: newValue,
        });
      },
    },

    messageTemplateCategory: {
      get() {
        return this.messageTemplate.category;
      },

      set(newValue) {
        this.changeMessageTemplate({
          ...this.messageTemplate,
          category: newValue,
        });
      },
    },
  },
  watch: {
    whatsAppInboxesOptions: {
      handler(newValue) {
        if (
          this.isSmsMessageTemplate ||
          this.messageTemplatePhoneNumber ||
          !Array.isArray(newValue) ||
          !newValue.length ||
          newValue.length > 1
        ) {
          return;
        }

        this.messageTemplatePhoneNumber = newValue[0].value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeMessageTemplate(newValue) {
      this.$emit('change-message-template', newValue);
    },
  },
};
</script>

<style lang="scss"></style>
