export const API_KEY_INIT = {
  key: '',
  value: '',
  addTo: 'header',
};

export const PASSWORD_NAME_INIT = {
  username: '',
  password: '',
};

export const BEARER_INIT = {
  token: '',
  prefix: '',
};

export const OAUTH2_INIT = {
  client_id: '',
  client_secret: '',
  authorizationUrl: '',
  scope: '',
  exchangeUrl: '',
  exchangeGrantType: 'authorization_code',
  refreshAutomatically: false,
  refreshOn: null,
  refreshPeriod: null,
};

export const MEWS_INIT = {
  key: 'AccessToken',
  value: '',
  addTo: 'body',
};

export const RESET_TO_INIT = {
  apiKey: { ...API_KEY_INIT },
  basic: { ...PASSWORD_NAME_INIT },
  digest: { ...PASSWORD_NAME_INIT },
  bearer: { ...BEARER_INIT },
  oauth2: { ...OAUTH2_INIT },
  mews: { ...MEWS_INIT },
};

export const MOCKED_SENSITIVE = {
  apiKey: {
    value: 'value_placeholder',
  },
  basic: {
    password: 'password_placeholder',
  },
  digest: {
    password: 'password_placeholder',
  },
  bearer: {
    token: 'token_placeholder',
  },
  oauth2: {
    client_secret: 'client_secret_placeholder',
  },
  mews: {
    ...MEWS_INIT,
    value: 'value_placeholder',
  },
};
