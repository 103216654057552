/* global axios */
import { stagingUrl } from '../../../api/automation.stub';
import JwtAuth from '../../../api/jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? stagingUrl + '/automation/message'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/automation/message';

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  sendMessage: async ({}, { data }) => {
    try {
      const jwtAuthTokens = await JwtAuth.getUserTokens();

      await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      });
    } catch (error) {
      // TODO do some action
    }
  },
};
