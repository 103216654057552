/* eslint-disable class-methods-use-this */
/* global axios */
import { stagingUrl } from '../api/automation.stub';
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? stagingUrl
    : window.location.protocol + '//' + window.location.hostname;

export const buildMessageTemplatesParams = (
  page,
  sortAttr,
  limit,
  search,
  all = false
) => {
  if (!page && !sortAttr && !limit) {
    return '';
  }

  let query = '?';

  if (all) {
    query += `all=${all}&`;
  }

  if (search) {
    query += `q=${search}&`;
  }

  if (page) {
    query += `page=${page}&`;
  }

  if (sortAttr) {
    query += `sort=${sortAttr}&`;
  }

  if (limit) {
    query += `limit=${limit}`;
  }

  if (query[query.length - 1] === '&') {
    query = query.slice(0, query.length - 1);
  }

  return query;
};

class MessageTemplateAPI {
  getTemplates = async (accountId, requestParams = {}) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/templates/template/all/${accountId}${buildMessageTemplatesParams(
        requestParams.page,
        requestParams.sortAttr,
        requestParams.limit,
        requestParams.search
      )}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getAllTemplates = async (accountId, requestParams = {}) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/templates/template/all/${accountId}${buildMessageTemplatesParams(
        1,
        requestParams.sortAttr,
        requestParams.limit,
        requestParams.search,
        true
      )}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getAllTemplatesName = async (accountId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/templates/template/all/${accountId}/names`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getTemplatesCount = async (accountId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/templates/template/all/${accountId}/count`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getTemplate = async (templateId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/templates/template/${templateId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getTemplateByName = async (name) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/templates/template/name/${name}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  createTemplate = async (accountId, newTemplate) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/templates/template`,
      { clientId: accountId, ...newTemplate },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  addTemplateTranslation = async (templateId, translation) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/templates/template/${templateId}/translation`,
      translation,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  updateTemplate = async (templateId, template) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/templates/template/${templateId}`, template, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  deleteTemplate = async (templateId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(`${url}/templates/template/${templateId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  deleteTemplateTranslation = async (templateId, language) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(
      `${url}/templates/template/${templateId}/translation/${language}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getTemplateEntrypoints = async (accountId, templateId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/automation/automations/${accountId}/template-entrypoints/${templateId}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  // Scheduled templates

  getScheduledTemplates = async (accountId, requestParams = {}) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/automation/scheduled/${accountId}${buildMessageTemplatesParams(
        requestParams.page,
        requestParams.sortAttr,
        requestParams.limit
      )}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getScheduledTemplate = async (scheduledId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/automation/scheduled/details/${scheduledId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  cancelScheduledMessageTemplate = async (id) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(`${url}/automation/scheduled/${id}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  // SMS templates
  getSmsTemplates = async (accountId, requestParams = {}) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/templates/sms/all/${accountId}${buildMessageTemplatesParams(
        requestParams.page,
        requestParams.sortAttr,
        requestParams.limit,
        requestParams.search
      )}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getAllSmsTemplates = async (accountId, requestParams = {}) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/templates/sms/all/${accountId}${buildMessageTemplatesParams(
        1,
        requestParams.sortAttr,
        requestParams.limit,
        requestParams.search,
        true
      )}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getAllSmsTemplatesName = async (accountId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/templates/sms/all/${accountId}/names`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getSmsTemplatesCount = async (accountId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/templates/sms/all/${accountId}/count`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getSmsTemplate = async (templateId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/templates/sms/${templateId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  createSmsTemplate = async (accountId, newTemplate) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/templates/sms`,
      { clientId: accountId, ...newTemplate },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  addSmsTemplateTranslation = async (templateId, translation) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/templates/sms/${templateId}/translation`,
      translation,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  updateSmsTemplate = async (templateId, template) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/templates/sms/${templateId}`, template, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  deleteSmsTemplate = async (templateId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(`${url}/templates/sms/${templateId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  deleteSmsTemplateTranslation = async (templateId, language) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(
      `${url}/templates/sms/${templateId}/translation/${language}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };
}

export default new MessageTemplateAPI();
