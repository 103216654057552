/* global axios */
import { stagingUrl } from '../api/automation.stub';
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? stagingUrl + '/automation/folders'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/automation/folders';

class FoldersAPI {
  getFolder = async (clientId, folderId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/${clientId}/folder/${folderId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getAllFolders = async (clientId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/${clientId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getFolderEntrypoints = async (accountId, folderId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url.replace(
        'folders',
        'automations'
      )}/${accountId}/list-folder-entrypoints/${folderId}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  createFolder = async (clientId, folder) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}/${clientId}`, folder, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateFolder = async (clientId, folderId, folder) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/${clientId}/folder/${folderId}`, folder, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateFolders = async (clientId, folders) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/${clientId}/folder`, folders, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  deleteFolder = async (clientId, folderId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(`${url}/${clientId}/folder/${folderId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };
}

export default new FoldersAPI();
