<template>
  <formulate-form
    v-slot="{ hasErrors }"
    class="chatlyn-container relative h-full"
    name="sms-settings-form"
    @submit="smsFormSubmit"
  >
    <div v-if="sms" class="grid gap-12 md:grid-cols-4">
      <span class="inline-flex flex-col gap-1">
        <div class="text-lg">{{ $t('CHATLYN_SMS_SENDING.TITLE') }}</div>

        <div class="text-sm">{{ $t('CHATLYN_SMS_SENDING.MESSAGE') }}</div>
      </span>

      <span class="col-span-2 inline-flex flex-col gap-5">
        <formulate-input
          v-model="isSmsEnabled"
          :disabled="isSmsEnabled"
          :label="$t('CHATLYN_SMS_SENDING.FORM.CHECKBOX.LABEL')"
          name="isSmsEnabled"
          type="checkbox"
        />

        <div
          v-if="isSmsEnabled"
          class="flex max-h-96 flex-col gap-2 overflow-y-auto overflow-x-clip"
        >
          <template v-if="sms && sms.shortNames && sms.shortNames.length">
            <short-name-editor
              v-for="shortName in sms.shortNames"
              :key="shortName.id"
              :short-name="shortName"
              :is-remove-button-disabled="isRemoveButtonDisabled"
              @change-short-name="changeShortName"
              @remove-short-name="removeShortName"
            />
          </template>

          <woot-button
            class="self-start"
            icon="add"
            color-scheme="secondary"
            size="tiny"
            type="button"
            @click="addShortName"
          >
            {{ $t('CHATLYN_SMS_SENDING.FORM.SENDER_NAME.NEW_BUTTON') }}
          </woot-button>
        </div>

        <div
          class="callout text-sm"
        >
          {{
            $t(
              `CHATLYN_SMS_SENDING.FORM.HELP_TEXT.${
                isSmsEnabled ? 'ENABLED' : 'DISABLED'
              }`
            )
          }}
        </div>

        <woot-button
          v-if="isSmsEnabled"
          :disabled="hasErrors"
          class="w-max"
          color-scheme="primary"
        >
          {{ $t('CHATLYN_GENERAL.BUTTON.UPDATE') }}
        </woot-button>
      </span>
    </div>

    <chatlyn-spinner :is-visible="isLoading" />
  </formulate-form>
</template>

<script>
import { mapGetters } from 'vuex';
import getUuid from 'widget/helpers/uuid';
import alertMixin from 'shared/mixins/alertMixin';

import ChatlynSpinner from '../../../../../shared/components/ChatlynSpinner.vue';
import ShortNameEditor from './components/ShortNameEditor.vue';

export default {
  components: { ChatlynSpinner, ShortNameEditor },

  mixins: [alertMixin],

  data() {
    return {
      sms: null,
      isSubmitting: false,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      managementUiFlags: 'management/getUiFlags',
      managementSms: 'management/getSms',
    }),

    defaultName() {
      return this.$t('CHATLYN_SMS_SENDING.FORM.SENDER_NAME.DEFAULT_VALUE');
    },

    isSmsEnabled: {
      get() {
        return this.sms?.enabled;
      },

      async set(newValue) {
        if (this.sms?.enabled || !newValue) {
          return;
        }

        const newSms = this.convertToApiValue(this.sms);

        try {
          await this.$store.dispatch('management/updateSms', {
            accountId: this.accountId,
            sms: {
              enabled: newValue,
              shortNames: newSms?.shortNames?.length
                ? newSms.shortNames
                : [this.defaultName],
            },
          });

          this.showAlert(this.$t('CHATLYN_SMS_SENDING.FORM.MESSAGE.SUCCESS'));
        } catch (error) {
          this.showAlert(this.$t('CHATLYN_SMS_SENDING.FORM.MESSAGE.ERROR'));
        }
      },
    },

    isLoading() {
      return (
        this.managementUiFlags.isSmsFetching ||
        this.managementUiFlags.isSmsUpdating ||
        this.isSubmitting
      );
    },

    isRemoveButtonDisabled() {
      return this.sms?.shortNames?.length <= 1;
    },
  },

  watch: {
    accountId: {
      async handler(newValue) {
        if (!newValue) {
          return;
        }

        await this.$store.dispatch('management/getSms', {
          accountId: newValue,
        });
      },
      immediate: true,
    },

    managementSms: {
      handler(newValue) {
        if (!newValue) {
          return;
        }

        this.sms = this.convertFromApiValues(newValue);
      },
      deep: true,
    },
  },

  methods: {
    convertFromApiValues(value) {
      const newSms = structuredClone(value);

      newSms.shortNames = newSms.shortNames.map((name) => ({
        id: getUuid(),
        name,
      }));

      return newSms;
    },

    convertToApiValue(value) {
      const newSms = structuredClone(value);

      newSms.shortNames = newSms.shortNames.map((name) => name.name);

      return newSms;
    },

    changeShortName(shortName) {
      const newSms = structuredClone(this.sms);

      newSms.shortNames = newSms.shortNames.map((name) =>
        name.id === shortName.id ? shortName : name
      );

      this.sms = newSms;
    },

    removeShortName(id) {
      const newSms = structuredClone(this.sms);

      newSms.shortNames = newSms.shortNames.filter((name) => name.id !== id);

      this.sms = newSms;
    },

    addShortName() {
      const newSms = structuredClone(this.sms);

      newSms.shortNames = [...newSms.shortNames, { id: getUuid(), name: '' }];
      this.sms = newSms;
    },

    async smsFormSubmit() {
      try {
        this.isSubmitting = true;

        const formElement = this.$formulate.registry.get('sms-settings-form');

        const hasErrors = await formElement.hasValidationErrors();

        if (hasErrors) {
          throw new Error();
        }

        const newSms = this.convertToApiValue(this.sms);

        await this.$store.dispatch('management/updateSms', {
          accountId: this.accountId,
          sms: newSms,
        });

        this.showAlert(this.$t('CHATLYN_SMS_SENDING.FORM.MESSAGE.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('CHATLYN_SMS_SENDING.FORM.MESSAGE.ERROR'));
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style></style>
