<template>
  <div class="merge-field">
    <div class="merge-field__row">
      <div class="merge-field__row__value">
        <label
          :title="formattedKey"
          for="example-value"
          class="merge-field__row__value__label"
        >
          <span class="text-ellipsis"> {{ formattedKey }}</span>
          <fluent-icon
            v-if="isIterableKey"
            v-tooltip.top="
              $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.TOOLTIP_ITERABLE')
            "
            :size="14"
            class="flex-shrink-0 cursor-pointer"
            icon="repeat"
          />
        </label>
        <multiselect
          v-model="localDataType"
          v-bind="multiselectProps"
          :options="dataTypesList"
          :disabled="isRequiredAttribute"
          class="merge-field__row__value__data-select"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <fluent-icon v-if="option.icon" :icon="option.icon" :size="16" />
          </template>
          <template slot="option" slot-scope="{ option }">
            <fluent-icon v-if="option.icon" :icon="option.icon" :size="16" />
            {{ option.label }}
          </template>
        </multiselect>
        <formulate-input
          id="example-value"
          :value="mergeField.exampleValue"
          class="merge-field__row__value__example"
          type="text"
          disabled
        />
      </div>
      <div class="merge-field__row__separator">
        <fluent-icon class="txt-muted mx-auto mt-1" icon="link" />
      </div>
      <div class="merge-field__row__attr-type">
        <label>
          {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.ATTRIBUTE_TYPE') }}
        </label>
        <multiselect
          v-model="localAttributeType"
          v-bind="multiselectProps"
          :options="attributeTypes"
          :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
          class="left-input"
        />
      </div>
      <div class="merge-field__row__attr-key">
        <formulate-input
          v-if="isAutomationAttribute"
          v-model="editableFields.attributeLabel"
          :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
          class="right-input custom-formulate-error-tooltip tooltip__top"
          type="text"
          validation="required"
        >
          <template #label>
            <label class="merge-field__row__attr-key__label">
              {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.NAME') }}
              <fluent-icon
                v-tooltip.top="
                  $t(
                    'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.ATTRIBUTE_NAME_INFO'
                  )
                "
                icon="info"
                :size="12"
              />
            </label>
          </template>
        </formulate-input>
        <template v-else>
          <label>
            {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.ATTRIBUTE') }}
          </label>
          <multiselect
            v-model="localAttributeKey"
            v-bind="multiselectProps"
            :disabled="isRequiredAttribute"
            :options="attributesList"
            :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
            class="right-input"
          />
        </template>
      </div>
      <woot-button
        class="merge-field__row__remove-btn"
        color-scheme="secondary"
        icon="dismiss"
        variant="clear"
        type="button"
        @click="onDeleteAttribute"
      />
    </div>
    <div v-if="isPhone" class="field-helper-row">
      <formulate-input
        id="advanced-parsing"
        v-model="editableFields.advNumPars"
        type="checkbox"
      >
        <template #label>
          <label
            for="advanced-parsing"
            class="merge-field__row__attr-key__label"
          >
            {{
              $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.ADVANCED_PARSING')
            }}
            <fluent-icon
              v-tooltip.top="
                $t(
                  'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.ADVANCED_PARSING_INFO'
                )
              "
              icon="info"
              :size="12"
          /></label>
        </template>
      </formulate-input>
    </div>
    <div v-if="isDate" class="field-helper-row">
      <div :class="isCustomFormat ? 'flex-1' : 'flex-auto'">
        <label class="flex items-center gap-1">
          {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.DATE_FORMAT') }}
          <fluent-icon
            v-tooltip.top="
              $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.DATE_FORMAT_INFO')
            "
            icon="info"
            :size="12"
          />
        </label>
        <multiselect
          :value="localDateFormat"
          v-bind="multiselectProps"
          :options="dateFormatsList"
          :placeholder="
            $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.PLACEHOLDERS.DATE_FORMAT')
          "
          :class="isCustomFormat && 'field-helper-row__format-date left-input'"
          @input="onDateFormatChanged"
        />
      </div>
      <formulate-input
        v-if="isCustomFormat"
        v-model="editableFields.dateFormat"
        class="field-helper-row__custom-date right-input custom-formulate-error-tooltip tooltip__top"
        placeholder="DD.MM.YYYY"
        validation="required"
      >
        <template #label>
          <label class="field-helper-row__custom-date__label">
            {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.DATE_STRING') }}
            <a
              href="https://day.js.org/docs/en/parse/string-format#list-of-all-available-parsing-tokens"
              target="_blank"
            >
              {{
                $t(
                  'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.DATE_STRING_LINK'
                )
              }}
              <fluent-icon icon="new-window" :size="16" />
            </a>
          </label>
        </template>
      </formulate-input>
      <formulate-input
        v-if="isTimeFormat"
        v-model.number="localOffset"
        class="field-helper-row__offset"
        type="number"
      >
        <template #label>
          <label class="merge-field__row__attr-key__label">
            {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.OFFSET') }}
            <fluent-icon
              v-tooltip.top="
                $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.OFFSET_INFO')
              "
              icon="info"
              :size="12"
            />
          </label>
        </template>
      </formulate-input>
    </div>
  </div>
</template>

<script>
import attributeMixin from 'dashboard/mixins/attributeMixin';
import { SYSTEM_ATTRIBUTES_KEYS } from 'dashboard/constants/common';
import {
  DATES_FORMATS,
  UNIX_TIME,
  ISO_STRING,
  CUSTOM_FORMAT,
  JSON_SEPARATOR,
} from '../utils/constants';
import { isFormatWithTime } from '../utils/mergeFieldsUtils';

export default {
  name: 'EntrypointEditMergeField',
  mixins: [attributeMixin],
  props: {
    mergeField: {
      type: Object,
      required: true,
    },
    attributeKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editableFields: {
        dataType: '',
        attributeType: '',
        attributeKey: '',
        attributeLabel: '',
      },
      attributeType: 'contact_attribute',
      localDateFormat: '',
    };
  },
  computed: {
    attributesList() {
      const filteredAttributes = this.availableAttributes.filter((attr) =>
        this.attributesByDataType.some(({ value }) => attr.value === value)
      );
      return filteredAttributes.length
        ? filteredAttributes
        : this.fallbackItems;
    },
    availableAttributes() {
      const filter = this.isRequiredAttribute
        ? {}
        : {
            filterByKey: 'value',
            excludedValues: [
              SYSTEM_ATTRIBUTES_KEYS.EMAIL_ADDRESS,
              SYSTEM_ATTRIBUTES_KEYS.PHONE_NUMBER,
              ...this.usedAttributes,
            ],
          };
      return this.getAttributesList(filter);
    },
    attributesByDataType() {
      return this.getAttributesList({
        filterByKey: 'type',
        includedValues:
          this.mergeField.dataType === 'text'
            ? ['text', 'list', 'link']
            : [this.mergeField.dataType],
      });
    },
    attributeTypes() {
      const types = [
        {
          value: 'automation',
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.ATTRIBUTE_TYPE.AUTOMATION'
          ),
        },
        {
          value: 'contact',
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.ATTRIBUTE_TYPE.CONTACT'
          ),
        },
      ];

      if (this.shouldAddRequiredType(SYSTEM_ATTRIBUTES_KEYS.PHONE_NUMBER)) {
        types.push({
          value: 'phone',
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.ATTRIBUTE_TYPE.PHONE'
          ),
        });
      }

      if (this.shouldAddRequiredType(SYSTEM_ATTRIBUTES_KEYS.EMAIL_ADDRESS)) {
        types.push({
          value: 'email',
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.ATTRIBUTE_TYPE.EMAIL'
          ),
        });
      }

      return types;
    },
    customDateFormat() {
      return {
        label: this.$t(
          'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.DATE_FORMATS.CUSTOM'
        ),
        value: CUSTOM_FORMAT,
      };
    },
    dataTypesList() {
      return [
        {
          value: 'text',
          label: this.$t('CONTACTS_FILTER.ATTRIBUTES.CUSTOM_ATTRIBUTE_TEXT'),
          icon: 'text',
        },
        {
          value: 'number',
          label: this.$t('CONTACTS_FILTER.ATTRIBUTES.CUSTOM_ATTRIBUTE_NUMBER'),
          icon: 'hash',
        },
        {
          value: 'checkbox',
          label: this.$t(
            'CONTACTS_FILTER.ATTRIBUTES.CUSTOM_ATTRIBUTE_CHECKBOX'
          ),
          icon: 'checkmark-square',
        },
        {
          value: 'date',
          label: this.$t('CONTACTS_FILTER.ATTRIBUTES.CUSTOM_ATTRIBUTE_DATE'),
          icon: 'calendar',
        },
      ];
    },
    dateFormatsList() {
      const formats = DATES_FORMATS.map((format) => {
        if (format === UNIX_TIME) {
          const unixLabel = this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.DATE_FORMATS.UNIX'
          );
          return {
            label: `${unixLabel} (${+new Date()})`,
            value: format,
          };
        }
        if (format === ISO_STRING) {
          return {
            label: `ISO 8601 (${format})`,
            value: format,
          };
        }
        return { label: format, value: format };
      });
      formats.push(this.customDateFormat);
      return formats;
    },
    formattedKey() {
      const keys = this.mergeField.key.split(JSON_SEPARATOR).filter(Boolean);
      const formatted = keys
        .join('.')
        .replaceAll('.i.', '[i].')
        .replaceAll('"', '');

      if (formatted.endsWith('.i')) {
        return formatted.substring(0, formatted.length - 2) + '[i]';
      }
      return formatted;
    },
    isIterableKey() {
      return !!this.formattedKey.includes('[i]');
    },
    isAutomationAttribute() {
      return this.localAttributeType?.value === 'automation';
    },
    isCustomFormat() {
      return this.isDate && this.localDateFormat?.value === 'custom';
    },
    isDate() {
      return this.editableFields.dataType === 'date';
    },
    isDirty() {
      const { key, exampleValue, _id, required, ...rest } = this.mergeField;
      return JSON.stringify(rest) !== JSON.stringify(this.editableFields);
    },
    isTimeFormat() {
      return (
        !!this.editableFields.dateFormat &&
        isFormatWithTime(this.editableFields.dateFormat)
      );
    },
    isPhone() {
      return this.localAttributeType?.value === 'phone';
    },
    isRequiredAttribute() {
      const requiredTypes = ['phone', 'email'];
      return requiredTypes.includes(this.localAttributeType?.value);
    },
    localAttributeKey: {
      get() {
        return this.attributesList.find(
          (item) => item.value === this.editableFields.attributeKey
        );
      },
      set({ value }) {
        this.editableFields.attributeKey = value;
      },
    },
    localAttributeType: {
      get() {
        return this.attributeTypes.find(
          (item) => item.value === this.editableFields.attributeType
        );
      },
      set({ value }) {
        this.editableFields.attributeType = value;
      },
    },
    localDataType: {
      get() {
        return this.dataTypesList.find(
          (item) => item.value === this.editableFields.dataType
        );
      },
      set({ value }) {
        this.editableFields.dataType = value;
      },
    },
    localOffset: {
      get() {
        return this.editableFields.dateTimezoneShift;
      },
      set(val) {
        this.editableFields.dateTimezoneShift = Math.round(val);
      },
    },
    multiselectProps() {
      return {
        maxHeight: 200,
        showLabels: false,
        searchable: false,
        trackBy: 'value',
        label: 'label',
        class: 'no-margin',
      };
    },
    usedAttributes() {
      return this.attributeKeys.filter(
        (key) => key !== this.editableFields.attributeKey
      );
    },
  },
  watch: {
    mergeField: {
      handler(val) {
        const { key, exampleValue, _id, required, ...rest } = val;
        this.editableFields = {
          ...rest,
          ...(this.isDate && { dateFormat: rest.dateFormat || '' }),
          ...(this.isDate &&
            this.isTimeFormat && {
              dateTimezoneShift: rest.dateTimezoneShift || 0,
            }),
        };
        if (this.isDate && rest.dateFormat) {
          this.localDateFormat =
            this.dateFormatsList.find(
              (format) => rest.dateFormat === format.value
            ) || this.customDateFormat;
        }
      },
      immediate: true,
    },
    'localAttributeType.value'(attrType) {
      this.editableFields.attributeKey = this.updateKeyOnTypeChange();
      this.editableFields.required = attrType === 'phone';
      this.editableFields.attributeLabel = '';
    },
    'localAttributeKey.value'(attrKey) {
      if (!this.isAutomationAttribute) {
        this.editableFields.attributeLabel = '';
        this.editableFields.dataType =
          this.attributesList.find((attr) => attr.value === attrKey)?.type ||
          this.editableFields.dataType;
      }
    },
    editableFields: {
      handler() {
        this.updateMergeField();
      },
      deep: true,
    },
  },
  methods: {
    getKeyForRequiredType() {
      return this.editableFields.attributeType === 'phone'
        ? SYSTEM_ATTRIBUTES_KEYS.PHONE_NUMBER
        : SYSTEM_ATTRIBUTES_KEYS.EMAIL_ADDRESS;
    },
    shouldAddRequiredType(type) {
      return (
        !this.usedAttributes.includes(type) &&
        this.editableFields.dataType === 'text'
      );
    },
    onDateFormatChanged(format) {
      this.localDateFormat = format;
      this.editableFields.dateFormat = this.isCustomFormat ? '' : format.value;
    },
    onDataTypeChange(dataOption) {
      this.editableFields.dataType = dataOption.value;
    },
    onDeleteAttribute() {
      this.$emit('delete:merge-field', this.mergeField.key);
    },
    updateKeyOnTypeChange() {
      const newKey = this.isAutomationAttribute ? this.mergeField.key : '';
      return this.isRequiredAttribute ? this.getKeyForRequiredType() : newKey;
    },
    updateMergeField() {
      if (this.isDirty) {
        const { advNumPars, dateFormat, dateTimezoneShift, ...rest } = {
          ...this.mergeField,
          ...this.editableFields,
        };
        const updatedMergeField = {
          ...rest,
          ...(this.isPhone && { advNumPars }),
          ...(this.isDate && { dateFormat }),
          ...(this.isDate && this.isTimeFormat && { dateTimezoneShift }),
        };
        this.$emit('update:merge-field', updatedMergeField);
      }
    },
  },
};
</script>

<style lang="scss">
.merge-field .multiselect.multiselect--disabled {
  @apply border-none opacity-60 dark:opacity-80;
  .multiselect__tags {
    @apply border bg-gray-200 text-gray-600 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400;
  }
  .multiselect__single,
  .multiselect__select {
    @apply border-none bg-transparent dark:bg-transparent;
  }
}
</style>

<style lang="scss" scoped>
.merge-field {
  @apply flex flex-col gap-2 rounded-lg border bg-white p-2 dark:border-gray-700 dark:bg-gray-800;
  &__row {
    @apply flex w-full items-center;
    &__value {
      @apply flex w-1/3 shrink-0 flex-wrap gap-x-1;
      &__label {
        @apply flex w-full items-center gap-1 overflow-hidden;
      }
      &__data-select.multiselect {
        max-width: 60px;
        width: 60px;
        ::v-deep .multiselect__single {
          @apply flex items-center;
        }
        ::v-deep .multiselect__content-wrapper {
          @apply w-fit;
        }
      }
      &__example {
        @apply flex-1;
      }
    }
    &__separator {
      @apply mt-3 h-5 w-12 shrink-0;
      svg {
        @apply mx-auto text-gray-400;
      }
    }

    &__attr-type {
      @apply w-1/4 shrink-0;
    }

    &__attr-key {
      @apply flex-auto;
      &__label {
        @apply flex items-center gap-1;
      }
    }

    &__remove-btn {
      @apply ml-1 mt-5 flex h-9 w-9 items-center justify-center;
    }
  }

  .field-helper-row {
    @apply ml-auto flex w-2/3 flex-wrap pe-10 ps-12;
    &__format-date ::v-deep .multiselect__content-wrapper {
      @apply w-fit;
    }
    &__custom-date {
      min-width: 55%;
      &__label {
        @apply flex items-center gap-1 text-sm leading-5 text-woot-800 dark:text-woot-200;
        a {
          @apply flex items-center gap-1 p-0 text-woot-500 hover:underline;
        }
      }
    }
    &__offset {
      @apply w-full flex-auto;
    }
  }

  .left-input ::v-deep .multiselect__tags {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .right-input {
    ::v-deep input,
    ::v-deep .multiselect__tags {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  ::v-deep .formulate-input-errors {
    bottom: 4px;
    right: 8px;
    top: auto;
  }
}
</style>
