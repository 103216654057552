<template>
  <div>
    <formulate-input
      v-model="messageTemplateTypeComputed"
      type="select"
      validation="required"
      :options="messageTemplateTypeOptions"
    />
  </div>
</template>

<script>
import { MESSAGE_TEMPLATES_CHANNEL_TYPE } from '../../../constants';

export default {
  name: 'TypeStep',

  props: {
    messageTemplateType: {
      type: String,
      default: '',
    },
  },

  computed: {
    messageTemplateTypeOptions() {
      const availableMessageTemplateType = [
        MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP,
        MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS,
      ];

      return availableMessageTemplateType.map((templateType) => ({
        label: this.$t(
          `CHATLYN_MESSAGE_TEMPLATE.CHANNEL_TYPES.${templateType.toUpperCase()}`
        ),
        value: templateType,
      }));
    },

    messageTemplateTypeComputed: {
      get() {
        return this.messageTemplateType;
      },

      set(newValue) {
        this.$emit('change-message-template-type', newValue);
      },
    },
  },
};
</script>

<style></style>
