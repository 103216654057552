const timeAfter = (event, minTime) => {
  if (!event.value) {
    return false;
  }

  const eventNumber = Number.parseInt(event.value.replace(/:/gm, ''), 10);

  const minTimeNumber = Number.parseInt(minTime.replace(/:/gm, ''), 10);

  return eventNumber > minTimeNumber;
};

const noEmoji = (event) => {
  const emojiRegex = /\p{Extended_Pictographic}/gu;

  return !emojiRegex.test(event.value);
};

const jsonFormat = (event, keys = []) => {
  try {
    const parsed = JSON.parse(event.value);

    return keys.every((key) => Boolean(parsed[key]));
  } catch (error) {
    return false;
  }
};

const emptyOrJson = (event, keys = []) => {
  if (!event.value) {
    return true;
  }

  return jsonFormat(event, keys);
};

const noMultipleNewLines = (event) => {
  if (!event.value) {
    return true;
  }

  const regExp = /\n\s*\n\s*\n/gm;

  return !regExp.test(event.value);
};

// Only for custom value, use default 'max:1024,length' for casual usage.
const maxLength = (value, max) => {
  if (!value) {
    return true;
  }

  return value.length <= max;
};

const startsWith = (event, text, reversed = false) => {
  if (!event.value) {
    return false;
  }

  return reversed
    ? !event.value.startsWith(text)
    : event.value.startsWith(text);
};

const uniqueValue = (event, id, values) => {
  const eventValue = event.value;

  return !Object.entries(values).some(
    ([key, value]) => key !== id && eventValue === value
  );
};

export {
  timeAfter,
  noEmoji,
  jsonFormat,
  emptyOrJson,
  noMultipleNewLines,
  maxLength,
  startsWith,
  uniqueValue,
};
