<template>
  <transition name="fade">
    <div
      v-if="isVisible"
      class="chatlyn-spinner absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.05)]"
    >
      <span
        class="chatlyn-spinner__circle h-8 max-h-full w-8 max-w-full animate-spin rounded-full border-2 border-l-green-500"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ChatlynSpinner',
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
