<template>
  <div v-if="listValue">
    <draggable
      :list="listValue"
      class="flex flex-col"
      :group="{ name: 'drag-drop-list-group', pull: handlePull, put: true }"
      :animation="250"
      :delay="50"
      :draggable="'.drag-drop-list-main-item:not(.exclude-this-item)'"
    >
      <drag-drop-list-item
        v-for="item in listValue"
        :key="item._id || item.id"
        :item="item"
        :menu-options="menuOptions"
        :drag-disabled="dragDisabled"
        class="drag-drop-list-main-item"
        :class="{
          'exclude-this-item': dragDisabled,
        }"
        :is-opened="isOpened"
      >
        <template #listItem="{ listItem, listItemMenuOptions }">
          <slot
            name="listItem"
            :list-item="listItem"
            :list-item-menu-options="listItemMenuOptions"
          />
        </template>
      </drag-drop-list-item>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import DragDropListItem from './DragDropListItem.vue';

export default {
  name: 'DragDropList',
  components: { draggable, DragDropListItem },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      required: true,
    },

    menuOptions: {
      type: Object,
      default: () => ({}),
    },

    dragDisabled: {
      type: Boolean,
      default: false,
    },

    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listValue: null,
    };
  },
  watch: {
    value: {
      handler(newValue, prevValue) {
        if (
          JSON.stringify(newValue) === JSON.stringify(this.listValue) ||
          JSON.stringify(newValue) === JSON.stringify(prevValue)
        ) {
          return;
        }

        this.listValue = structuredClone(newValue);
      },
      deep: true,
      immediate: true,
    },

    listValue: {
      handler(newValue, prevValue) {
        if (!prevValue) {
          return;
        }

        if (JSON.stringify(newValue) === JSON.stringify(this.value)) {
          return;
        }

        this.$emit('change', newValue);
      },
      deep: true,
    },
  },
  methods: {
    handlePull(_props, _props2, draggedItem) {
      return draggedItem.role === 'item';
    },
  },
};
</script>

<style lang="scss" scoped></style>
