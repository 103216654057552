<!-- eslint-disable vue/no-mutating-props -->
<template>
  <modal
    :show.sync="show"
    :on-close="onClose"
    :close-on-backdrop-click="closeOnBackdropClick"
  >
    <woot-modal-header
      :header-title="title"
      :header-content="message"
      :header-content-value="messageValue"
    />

    <div v-if="$slots.body" class="px-8 py-3">
      <slot name="body" />
    </div>

    <div v-if="hasButtons" class="modal-footer">
      <woot-button
        variant="clear"
        color-scheme="secondary"
        class="action-button margin-right-1"
        type="button"
        @click="onClose"
      >
        {{ rejectText }}
      </woot-button>
      <woot-button
        color-scheme="alert"
        class="action-button"
        variant="smooth"
        type="button"
        @click="onConfirm"
      >
        {{ confirmText }}
      </woot-button>
    </div>

    <chatlyn-spinner :is-visible="isLoading" />
  </modal>
</template>

<script>
import ChatlynSpinner from '../../../../shared/components/ChatlynSpinner.vue';
import Modal from '../../Modal.vue';

export default {
  components: {
    Modal,
    ChatlynSpinner,
  },
  props: {
    show: Boolean,
    size: String,
    onClose: { type: Function, default: () => {} },
    onConfirm: { type: Function, default: () => {} },
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    messageValue: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    rejectText: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    hasButtons: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
