<template>
  <div
    class="ai-suggestion-button relative flex h-8 select-none flex-row flex-nowrap items-stretch gap-0.5 rounded bg-violet-400 p-[3px] dark:bg-violet-500"
  >
    <popover-select
      v-if="hasManyNests"
      v-model="selectedAiNestId"
      class="ai-suggestion-button__nest"
      :options="aiNestsOptions"
      placement="top-end"
      :disabled="isLoading"
      @change-is-popover-select-open="changeIsNestsPopoverOpen"
    >
      <div
        class="inline-flex h-full cursor-pointer items-center gap-0.5 rounded bg-violet-600 px-1.5 py-0.5 text-sm !text-white dark:bg-violet-700"
      >
        <span v-if="selectedNest" class="text-xs">
          {{ getInitial(selectedNest.title) || selectedNest.nestId }}
        </span>

        <span>
          <fluent-icon
            :size="14"
            icon="chevron-down"
            class="transition-transform"
            :class="{
              'rotate-180': isNestsPopoverOpen,
            }"
          />
        </span>
      </div>
    </popover-select>

    <!-- v-if="!message" -->
    <woot-button
      class="ai-suggestion-button__woot-button !h-full !gap-1 !bg-transparent !px-1.5 !py-0.5 !text-xs !leading-none !text-white hover:!bg-violet-600 dark:hover:!bg-violet-700"
      :is-disabled="isSuggestButtonDisabled"
      size="small"
      variant="clear"
      type="button"
      @click="fetchAISuggestions"
    >
      <div class="flex h-full w-full flex-row items-center gap-1">
        <span v-if="!isLoading" class="-mt-0.5">
          <fluent-icon icon="wand" :size="16" />
        </span>

        <span v-else class="relative w-4 self-stretch">
          <chatlyn-spinner :is-visible="isLoading" />
        </span>

        <span> {{ $t('CONVERSATION.AI_SUGGESTION.SUGGEST') }} </span>
      </div>
    </woot-button>

    <!-- TODO: When improve will be implemented -->
    <!-- <popover-select
      v-else
      v-model="selectedImproveOptions"
      :options="improveOptions"
      placement="top-end"
    >
      <woot-button
        class="!h-full !gap-1 !bg-transparent !px-1.5 !py-0.5 !text-xs !leading-none !text-white hover:!bg-violet-600 dark:hover:!bg-violet-700"
        icon="wand"
        size="small"
        variant="clear"
        type="button"
      >
        {{ $t('CONVERSATION.AI_SUGGESTION.IMPROVE') }}
      </woot-button>
    </popover-select> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { MESSAGE_TYPE } from '../../../../../shared/constants/messages';

import ChatlynSpinner from '../../../../../shared/components/ChatlynSpinner.vue';
import PopoverSelect from '../../../../../shared/components/ui/PopoverSelect.vue';

export default {
  name: 'AiSuggestionButton',
  components: { PopoverSelect, ChatlynSpinner },

  props: {
    message: {
      type: String,
      default: '',
    },

    canReply: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isNestsPopoverOpen: false,
      selectedAiNestId: '',
    };
  },

  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      currentChat: 'getSelectedChat',
      aiNests: 'chatbot/getAINests',
      aiSuggestions: 'chatbot/getAISuggestions',
      chatbotUIFlags: 'chatbot/getUIFlags',
    }),

    hasManyNests() {
      return this.aiNests?.length > 1;
    },

    aiNestsOptions() {
      return (
        this.aiNests
          .filter((aiNest) => !!aiNest.title)
          .map((aiNest) => ({
            label: aiNest.title,
            value: `${aiNest.id}`,
          })) || []
      );
    },

    selectedNest() {
      return this.aiNests?.find(
        (nest) => `${nest.id}` === this.selectedAiNestId
      );
    },

    isSuggestButtonDisabled() {
      return (
        !this.canReply ||
        !this.selectedNest ||
        !this.latestIncomingMessagesContent ||
        this.isLoading
      );
    },

    selectedImproveOptions: {
      get() {
        return '';
      },

      set(newValue) {
        console.log(newValue);
      },
    },

    improveOptions() {
      return [
        {
          label: this.$t('CONVERSATION.AI_SUGGESTION.IMPROVE_OPTIONS.FIX'),
          value: 'fix',
        },
        {
          label: this.$t(
            'CONVERSATION.AI_SUGGESTION.IMPROVE_OPTIONS.MORE_FORMAL'
          ),
          value: 'more_formal',
        },
        {
          label: this.$t(
            'CONVERSATION.AI_SUGGESTION.IMPROVE_OPTIONS.LESS_FORMAL'
          ),
          value: 'less_formal',
        },
      ];
    },

    incomingMessages() {
      return (
        this.currentChat?.messages?.filter(
          (message) => message.message_type === MESSAGE_TYPE.INCOMING
        ) || []
      );
    },

    latestIncomingMessages() {
      return (
        this.incomingMessages?.slice(
          Math.max(this.incomingMessages.length - 3, 0)
        ) || []
      );
    },

    latestIncomingMessagesContent() {
      return (
        this.latestIncomingMessages
          ?.map((message) => message?.content?.trim())
          ?.join(' ') || ''
      );
    },

    isLoading() {
      return this.chatbotUIFlags.isAISuggestionLoading;
    },
  },

  watch: {
    aiNestsOptions: {
      handler(newValue) {
        if (!newValue || !newValue?.length) {
          this.selectedAiNestId = '';
          return;
        }

        if (
          this.selectedAiNestId &&
          newValue.some((nest) => nest.value === `${this.selectedAiNestId}`)
        ) {
          return;
        }

        this.selectedAiNestId = newValue[0].value;
      },
      deep: true,
      immediate: true,
    },

    aiSuggestions: {
      handler(newValue) {
        if (!newValue || !newValue.length) {
          return;
        }

        this.$emit('replace-text', newValue[0]);
      },
    },
  },

  methods: {
    getInitial(name = '') {
      const parts = name.split(/[ -]/);
      let initials = parts.reduce((acc, curr) => acc + curr.charAt(0), '');

      if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '');
      }
      initials = initials.substring(0, 2).toUpperCase();

      return initials;
    },

    changeIsNestsPopoverOpen(newValue) {
      this.isNestsPopoverOpen = newValue;
    },

    async fetchAISuggestions() {
      if (
        !this.selectedNest ||
        !this.currentChat ||
        !this.latestIncomingMessagesContent
      ) {
        return;
      }

      this.$store.dispatch('chatbot/getAISuggestions', {
        nestId: this.selectedNest.nestId,
        accountId: this.currentAccountId,
        message: this.latestIncomingMessagesContent,
        guestIdentifier: `${this.currentAccountId}-${this.currentChat.id}`,
        // answerLength,
      });
    },
  },
};
</script>

<style lang="scss">
.ai-suggestion-button {
  .ai-suggestion-button__woot-button {
    .button__content {
      @apply h-full;
    }
  }

  .chatlyn-spinner {
    @apply bg-transparent;

    .chatlyn-spinner__circle {
      @apply h-3 w-3 border border-l-transparent;
    }
  }
}
</style>
