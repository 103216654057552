<template>
  <div class="we-chat-inbox-container wizard-body flex-shrink-0 flex-grow-0 h-auto xl:pe-64">
    <page-header
      class="we-chat-inbox-header"
      :header-title="$t('CHATLYN_INBOXES.WE_CHAT.SETUP.TITLE')"
      :header-content="$t('CHATLYN_INBOXES.WE_CHAT.SETUP.DESC')"
    />

    <we-chat-configuration @handle-form-submit="createInbox" />

    <div
      v-if="isCreating"
      class="absolute h-full w-full top-0 left-0 flex items-center justify-center z-10"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '../../SettingsSubPageHeader.vue';
import WeChatAPI from '../../../../../api/weChat';
import router from '../../../../index';
import WeChatConfiguration from '../components/WeChatConfiguration.vue';

export default {
  name: 'WeChat',
  components: {
    PageHeader,
    WeChatConfiguration,
  },
  data() {
    return {
      isCreating: false,
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    async createInbox(inbox) {
      try {
        this.isCreating = true;

        const { data: createdInbox } = await WeChatAPI.createWeChatInbox({
          ...inbox,
          accountId: this.currentAccountId,
        });

        bus.$emit(
          'newToastMessage',
          this.$t('CHATLYN_INBOXES.WE_CHAT.SETUP.SUCCESS_MESSAGE')
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: createdInbox.chatlynInboxId,
          },
        });
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('CHATLYN_INBOXES.WE_CHAT.SETUP.ERROR_MESSAGE')
        );
      } finally {
        this.isCreating = false;
      }
    },
  },
};
</script>

<style lang="scss">
.we-chat-inbox-container {
  @apply overflow-x-hidden overflow-y-auto relative;

  max-height: 100%;
  height: max-content;
}
</style>
