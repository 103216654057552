<template>
  <div
    class="flex h-16 items-center justify-between px-3 py-1 mt-2 border rounded-md dark:border-gray-700"
  >
    <div class="flex flex-grow min-w-0 items-center">
      <thumbnail
        size="32px"
        :src="thumbnailSrc"
        :username="headerTitle"
        variant="square"
      />
      <div class="flex items-start min-w-0 flex-col ms-2">
        <h4
          class="min-w-0 w-full text-sm h-4 mb-0 leading-4 overflow-hidden whitespace-nowrap text-ellipsis text-gray-800 dark:text-gray-100"
        >
          {{ headerTitle }}
        </h4>
        <span class="h-4 leading-4 text-gray-600 dark:text-gray-200 text-xs">
          {{ subTitle }}
        </span>
      </div>
    </div>
    <div class="flex items-end">
      <woot-button
        variant="clear"
        color-scheme="secondary"
        size="small"
        icon="open"
        @click="popoutHelpCenter"
      />
      <woot-button
        variant="clear"
        size="small"
        color-scheme="secondary"
        icon="arrow-swap"
        @click="openPortalPopover"
      />
    </div>
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
export default {
  components: {
    Thumbnail,
  },
  props: {
    thumbnailSrc: {
      type: String,
      default: '',
    },
    headerTitle: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    portalLink: {
      type: String,
      default: '',
    },
  },
  methods: {
    popoutHelpCenter() {
      window.open(this.portalLink, '_blank');
    },
    openPortalPopover() {
      this.$emit('open-popover');
    },
  },
};
</script>
