import types from '../../mutation-types';
import ContactListAPI from '../../../api/contactLists';
import { downloadCsvFile } from '../../../helper/downloadHelper';

export const actions = {
  getLists: async ({ commit }, { accountId, requestParams = {} }) => {
    try {
      commit(types.SET_LISTS_UI_FLAGS, { isLoading: true });
      const { data } = await ContactListAPI.getLists(accountId, requestParams);
      commit(types.CLEAR_LISTS);
      commit(types.SET_LISTS, data);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_LISTS_UI_FLAGS, { isLoading: false });
    }
  },
  getExcludedContacts: async (
    { commit },
    { listId, page, pageParams, search }
  ) => {
    try {
      const { data } = await ContactListAPI.getExcludedContacts(
        listId,
        page,
        pageParams,
        15,
        search
      );
      commit(types.SET_EXCLUDED_CONTACTS, data);
    } catch (error) {
      // TODO some action
    }
  },

  getList: async (
    { commit },
    {
      listId,
      page,
      pageParams,
      search,
      /*payload*/
      condition,
    }
  ) => {
    try {
      const { data } = await ContactListAPI.getList(
        listId,
        page,
        pageParams,
        15,
        search,
        // payload
        condition
      );

      commit(types.CLEAR_LIST);
      commit(types.CLEAR_LIST_META);
      commit(types.CLEAR_LIST_LINKS);
      commit(types.SET_LIST, data);
      commit(types.SET_LIST_META, data.meta);
      commit(types.SET_LIST_LINKS, data.links);
    } catch (error) {
      // TODO some action
    }
  },

  getListEntrypoints: async (
    // eslint-disable-next-line no-empty-pattern
    { commit },
    { accountId, listId }
  ) => {
    let isSuccess = false;
    try {
      commit(types.CLEAR_LIST_ENTRYPOINTS);

      const { data } = await ContactListAPI.getListEntrypoints(
        accountId,
        listId
      );

      if (typeof data?.length !== 'number') {
        throw new Error();
      }

      commit(types.SET_LIST_ENTRYPOINTS, data);
      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      // TODO some action
    }

    return isSuccess;
  },

  createList: async ({ commit }, { newRecord }) => {
    try {
      commit(types.SET_LISTS_UI_FLAGS, { isCreating: true });
      return ContactListAPI.createList(newRecord);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LISTS_UI_FLAGS, { isCreating: false });
    }
  },

  updateList: async ({ commit }, { listId, newRecord }) => {
    try {
      commit(types.SET_LISTS_UI_FLAGS, { isUpdating: true });
      return await ContactListAPI.updateList(listId, newRecord);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LISTS_UI_FLAGS, { isUpdating: false });
    }
  },

  updateLists: async ({ commit }, { lists }) => {
    try {
      commit(types.SET_LISTS_UI_FLAGS, { isUpdating: true });

      const { data } = await ContactListAPI.updateLists(lists);

      // commit(types.SET_LISTS, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LISTS_UI_FLAGS, { isUpdating: false });
    }
  },

  updateListByCondition: async ({ commit }, { listId, payload }) => {
    try {
      commit(types.SET_LISTS_UI_FLAGS, { isUpdating: true });

      return ContactListAPI.updateListByCondition(listId, payload);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LISTS_UI_FLAGS, { isUpdating: false });
    }
  },

  deleteList: async ({ commit }, { listId }) => {
    try {
      commit(types.SET_LISTS_UI_FLAGS, { isDeleting: true });
      return await ContactListAPI.deleteList(listId);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LISTS_UI_FLAGS, { isDeleting: false });
    }
  },

  duplicateList: async ({ commit }, { listId, copyToList }) => {
    try {
      commit(types.SET_LISTS_UI_FLAGS, { isDuplicating: true });

      await ContactListAPI.duplicateList(listId, copyToList);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LISTS_UI_FLAGS, { isDuplicating: false });
    }
  },

  mergeList: async ({ commit }, { listAId, listBId, name }) => {
    let isSuccess = true;

    try {
      commit(types.SET_LISTS_UI_FLAGS, { isMerging: true });

      await ContactListAPI.mergeList(listAId, listBId, name);
    } catch (error) {
      isSuccess = false;
    } finally {
      commit(types.SET_LISTS_UI_FLAGS, { isMerging: false });
    }

    return isSuccess;
  },

  exportList: async ({ commit }, { listId, listName }) => {
    let isSuccess = true;

    try {
      commit(types.SET_LISTS_UI_FLAGS, { isExporting: true });

      const response = await ContactListAPI.exportList(listId);

      if (!response.data) {
        throw new Error();
      }

      downloadCsvFile(`${listName}.csv`, response.data);
    } catch (error) {
      isSuccess = false;
    } finally {
      commit(types.SET_LISTS_UI_FLAGS, { isExporting: false });
    }

    return isSuccess;
  },

  // eslint-disable-next-line no-empty-pattern
  addContacts: async ({}, { listId, contacts }) => {
    try {
      const { data } = await ContactListAPI.addContacts(listId, contacts);
      return data.contacts;
    } catch (error) {
      // TODO some action
    }
  },
  // eslint-disable-next-line no-empty-pattern
  deleteContacts: async ({}, { listId, contacts }) => {
    try {
      await ContactListAPI.deleteContacts(listId, contacts);
    } catch (error) {
      // TODO some action
    }
  },
  // eslint-disable-next-line no-empty-pattern
  deleteContactFromLists: async ({}, { contactId }) => {
    try {
      await ContactListAPI.deleteContactFromLists(contactId);
    } catch (error) {
      // TODO some action
    }
  },

  deleteContactsFromLists: async (
    // eslint-disable-next-line no-empty-pattern
    {},
    { contacts, listId = undefined }
  ) => {
    try {
      await ContactListAPI.deleteContactsFromLists(contacts, listId);
    } catch (error) {
      // TODO some action
    }
  },

  setAppliedListConditions({ commit }, data) {
    commit(types.SET_LIST_CONDITIONS, data);
  },

  setAppliedListCondition({ commit }, { listId, condition }) {
    commit(types.SET_LIST_CONDITION, { listId, condition });
  },

  clearAppliedListConditions({ commit }) {
    commit(types.CLEAR_LIST_CONDITIONS);
  },

  clearAppliedListCondition({ commit }, { listId }) {
    commit(types.CLEAR_LIST_CONDITION, listId);
  },
};
