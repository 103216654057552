<template>
  <div class="schedule">
    <div class="schedule__calc">
      <span class="schedule__title">{{
        $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.TITLE')
      }}</span>
      <div class="schedule__wrapper">
        <FormulateInput
          v-model="type"
          :options="types"
          type="select"
          class="schedule__type"
          @change="generateExecutions"
        />
        <div v-if="type === 'daily'" class="schedule__container">
          <span class="schedule__sub-title">{{
            $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.CHOOSE_TIME')
          }}</span>
          <FormulateInput
            v-model="hour"
            :options="hours"
            type="select"
            class="flex-1"
            @input="calcHours"
          />
          <span class="mx-2">:</span>
          <FormulateInput
            v-model="min"
            :options="minutes"
            type="select"
            class="flex-1"
            @input="calcMinutes"
          />
        </div>
        <div v-if="type === 'weekly'" class="schedule__container">
          <span class="schedule__sub-title">{{
            $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.CHOOSE_DAYS')
          }}</span>
          <FormulateInput
            v-model="days"
            :options="weekDays"
            type="checkbox"
            class="week-days"
            @input="calcDays"
          />
          <FormulateInput
            v-model="hour"
            :options="hours"
            type="select"
            class="flex-1"
            @input="calcHours"
          />
          <span class="mx-2">:</span>
          <FormulateInput
            v-model="min"
            :options="minutes"
            type="select"
            class="flex-1"
            @input="calcMinutes"
          />
        </div>
        <div v-if="type === 'custom'" class="schedule__container">
          <span class="schedule__sub-title">{{
            $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.CUSTOM_CRON')
          }}</span>
          <FormulateInput
            v-model="custom"
            type="text"
            class="mb-4 w-full"
            @input="calcCustom"
          />
          <div class="callout warning flex text-sm">
            <fluent-icon class="mr-2 shrink-0" icon="code" />
            <span
              v-dompurify-html="
                $t(
                  'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.CUSTOM_INFO'
                )
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="schedule__executions">
      <h3 class="schedule__title">
        {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.STEPS') }}
      </h3>
      <ul class="schedule__steps">
        <li
          v-for="(step, index) in steps"
          :key="'step-' + index"
          class="schedule__step"
          :class="{ 'callout alert text-center': !valid }"
        >
          {{ step }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  generateArray,
  calcExecution,
  condense,
  parseSequence,
} from '../utils/cron';
import { parseExpression } from 'cron-parser';

export default {
  name: 'EntrypointEditScheduled',
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    cronString: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      valid: true,
      cron: '00 10 * * *',
      custom: '00 10 * * *',
      type: 'daily',
      hour: '10',
      min: '00',
      days: [],
      steps: [],
      types: [
        {
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.TYPES.DAILY'
          ),
          value: 'daily',
        },
        {
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.TYPES.WEEKLY'
          ),
          value: 'weekly',
        },
        {
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.TYPES.CUSTOM'
          ),
          value: 'custom',
        },
      ],
      weekDays: [
        {
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.DAYS.SUNDAY'
          ),
          value: 0,
        },
        {
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.DAYS.MONDAY'
          ),
          value: 1,
        },
        {
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.DAYS.TUESDAY'
          ),
          value: 2,
        },
        {
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.DAYS.WEDNESDAY'
          ),
          value: 3,
        },
        {
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.DAYS.THURSDAY'
          ),
          value: 4,
        },
        {
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.DAYS.FRIDAY'
          ),
          value: 5,
        },
        {
          label: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.DAYS.SATURDAY'
          ),
          value: 6,
        },
      ],
      hours: generateArray(24),
      minutes: generateArray(60),
    };
  },
  computed: {
    localCronString() {
      return this.type !== 'custom' ? this.cron : this.custom;
    },
  },
  watch: {
    cron(newVal) {
      this.custom = newVal;
    },
  },
  mounted() {
    if (this.cronString.length) {
      this.cron = this.cronString;
      this.custom = this.cronString;
      this.type = this.types[2].value;
      this.parseCronString(this.cronString);
    }
    this.generateExecutions();
    this.changeCronString();
  },
  methods: {
    parseCronString(cronString) {
      const splitCron = cronString.split(' ');

      this.hour = splitCron[1];
      this.min = splitCron[0];
      this.days = parseSequence(splitCron[4]);
    },
    generateExecutions() {
      try {
        const interval = parseExpression(this.localCronString);
        this.steps = Array.from({ length: 5 }, (_, index) => {
          const { message, date } = calcExecution(interval, index);

          return this.$t(message) + date;
        });
        this.valid = !!this.localCronString;
      } catch (error) {
        this.steps = [
          this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.ERROR'),
        ];
        this.valid = false;
      }
    },
    calcCron(index, value) {
      let splitCron = this.cron.split(' ');

      splitCron[index] = value;
      this.cron = splitCron.join(' ');

      this.generateExecutions();
      this.changeCronString();
    },
    calcDays(days) {
      let day = '*';
      const converted = days.map(Number);

      if (days.length < 7 && days.length > 0) {
        const sortedDays = converted.sort((a, b) => a - b);
        day = condense(sortedDays);
      }

      this.calcCron(4, day);
    },
    calcHours(hour) {
      this.calcCron(1, hour);
    },
    calcMinutes(min) {
      this.calcCron(0, min);
    },
    calcCustom() {
      this.generateExecutions();
      this.changeCronString();
    },
    changeCronString() {
      this.$emit('change-cron-string', {
        cronString: this.localCronString,
        valid: this.valid,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.schedule {
  @apply flex rounded-lg bg-gray-100 p-4 dark:bg-gray-900;

  &__calc,
  &__executions {
    @apply flex w-1/2 flex-col;
  }

  &__wrapper {
    @apply flex flex-col rounded-lg bg-white p-4 dark:bg-gray-800;
  }

  &__calc {
    @apply mr-8;
  }

  &__type {
    @apply mb-4 w-full;
  }

  &__title {
    @apply mb-4 w-full text-lg;
  }

  &__container {
    @apply flex flex-wrap items-center;
  }

  &__sub-title {
    @apply mb-2 w-full;
  }

  &__steps {
    @apply flex w-full flex-col;
  }

  &__step {
    @apply w-full p-1 text-sm;

    &:nth-child(even) {
      @apply bg-gray-50 dark:bg-gray-800;
    }

    &.error {
      @apply rounded bg-red-50 text-red-700 dark:bg-red-400;
    }
  }

  &__info {
    @apply flex w-full items-center rounded bg-yellow-50 p-2 text-xs text-yellow-700 dark:bg-yellow-400;
  }
}

.week-days {
  ::v-deep {
    .formulate-input-wrapper {
      @apply w-full;

      .formulate-input-element.formulate-input-element--group.formulate-input-group {
        @apply flex w-full flex-wrap;

        .formulate-input-group-item.formulate-input {
          @apply mb-2 w-1/3;
        }
      }
    }
  }
}
</style>
