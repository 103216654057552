/* eslint-disable class-methods-use-this */
/* global axios */
import { stagingUrl } from '../api/automation.stub';
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? stagingUrl + '/chatbotmw'
    : window.location.protocol + '//' + window.location.hostname + '/chatbotmw';

class ChatbotAPI {
  getHandover = async (accountId, userId, botId, inboxId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/handover?accountId=${accountId}&userId=${userId}&botId=${botId}&inboxId=${inboxId}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  updateHandover = async (accountId, handover) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/handover/${accountId}`, handover, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  addHandoverTranslations = async (accountId, handoverId, translations) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/handover/${accountId}/${handoverId}/translations`,
      translations,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  deleteHandoverTranslation = async (accountId, translationId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(
      `${url}/handover/${accountId}/translations/${translationId}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getConversationStatus = async (accountId, conversationId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/session/account/${accountId}/conversation/${conversationId}/status`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  updateConversationStatus = async (accountId, conversationId, state) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/session/account/${accountId}/conversation/${conversationId}/status/${state}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getAINests = async (accountId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/nests/by-account-id?accountId=${accountId}&nestNames=true&strict=true`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getAISuggestions = async (payload) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}/nests/get-suggestions`, payload, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };
}

export default new ChatbotAPI();
