var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"role":_vm.isFolder ? 'folder' : 'item'}},[(_vm.isFolder)?_c('div',[_c('woot-button',{staticClass:"mb-1 flex h-7 w-full max-w-full items-center overflow-hidden text-ellipsis whitespace-nowrap rounded px-2 transition-colors duration-200 hover:!bg-gray-100 dark:hover:!bg-gray-800",attrs:{"color-scheme":"secondary","variant":"clear","type":"button"},on:{"click":_vm.toggleIsFolderExpanded}},[_c('div',{staticClass:"group flex flex-row flex-nowrap items-center gap-2"},[_c('span',{staticClass:"inline-flex"},[_c('fluent-icon',{staticClass:"opacity-70 transition-transform dark:opacity-50",attrs:{"icon":_vm.isFolderExpanded || _vm.isOpened ? 'folder-open' : 'folder',"size":14}})],1),_vm._v(" "),_c('span',{staticClass:"text-ellipsis"},[_vm._v("\n          "+_vm._s(_vm.item.label || _vm.item.name)+"\n        ")]),_vm._v(" "),(_vm.hasMenuItems)?_c('span',{staticClass:"ml-auto h-4 w-4 opacity-0 group-focus-within:opacity-100 group-hover:opacity-100"},[_c('popover-select',{attrs:{"placement":"bottom-end","options":_vm.menuOptions.folder},model:{value:(_vm.selectedMenuOption),callback:function ($$v) {_vm.selectedMenuOption=$$v},expression:"selectedMenuOption"}},[_c('button',{staticClass:"flex h-4 w-4 rounded",attrs:{"data-testid":"dot-menu-toggler"}},[_c('fluent-icon',{staticClass:"h-full w-full",attrs:{"icon":"more-horizontal"}})],1)])],1):_vm._e()])]),_vm._v(" "),_c('div',[_c('div',{staticClass:"pl-2.5"},[_c('slide-animation',{attrs:{"is-shown":_vm.isFolderExpanded || _vm.isOpened,"duration":250}},[_c('draggable',{staticClass:"mt-1",attrs:{"list":_vm.children,"group":{
              name: 'drag-drop-list-item-group',
              pull: true,
              put: true,
            },"draggable":".drag-drop-list-item:not(.exclude-this-item)","animation":250,"delay":50}},[(_vm.isFolderHasChildren)?[_vm._l((_vm.children),function(child){return [_c('div',{key:child.id || child._id,staticClass:"drag-drop-list-item grid w-full",class:{
                    'exclude-this-item': _vm.dragDisabled,
                  }},[_vm._t("listItem",null,{"listItem":child,"listItemMenuOptions":_vm.menuOptions.item})],2)]})]:_c('div',{staticClass:"drag-drop-list-item exclude-this-item grid w-full pb-2 pl-5 text-gray-500",attrs:{"draggable":false}},[_vm._v("\n              "+_vm._s(_vm.$t('FOLDERS.EMPTY_MESSAGE'))+"\n            ")])],2)],1)],1)])],1):_c('div',[_vm._t("listItem",null,{"listItem":_vm.item,"listItemMenuOptions":_vm.menuOptions.item})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }