export default {
  computed: {
    textValue() {
      return this.value;
    },

    textTags() {
      return this.tags;
    },

    contOfTags() {
      return this.textValue.match(/{{([1-9]{1}|10)}}/gm)?.length || 0;
    },
  },

  methods: {
    transformTextToTags(text) {
      const splitted = text
        .replace(/{{([1-9]{1}|10)}}/gm, '{{}}')
        .split('{{}}');

      return splitted
        .map((value, index) => {
          if (index === splitted.length - 1) {
            return value;
          }

          return `${value}${this.textTags[index]}`;
        })
        .join('');
    },

    transformTagsToText(text) {
      const tagsRegExp = new RegExp(`(${this.textTags.join('|')})`, 'g');

      const splitted = text
        .replace(/({{|}})/gm, '')
        .replace(tagsRegExp, '{{}}')
        .split('{{}}');

      return splitted
        .map((value, index) => {
          if (index === splitted.length - 1) {
            return value;
          }

          return `${value}{{${index + 1}}}`;
        })
        .join('');
    },
  },
};
