/* global axios */
import { stagingUrl } from '../api/automation.stub';
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? stagingUrl + '/automation/contactList'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/automation/contactList';

export const buildContactListParams = (
  contacts = false,
  page,
  sortAttr,
  limit,
  search
) => {
  if (!page && !sortAttr && !limit && !search) {
    return '';
  }

  let query = '?';

  query += `contacts=${contacts}&`;

  if (search) {
    query += `q=${search}&`;
  }

  if (page) {
    query += `page=${page}&`;
  }

  if (sortAttr) {
    query += `sort=${sortAttr}&`;
  }

  if (limit) {
    query += `limit=${limit}`;
  }

  if (query[query.length - 1] === '&') {
    query = query.slice(0, query.length - 1);
  }

  return query;
};

export const buildContactListExcludeParams = (
  page,
  sortAttr,
  limit,
  search
) => {
  let params = `?page=${page}&sort=${sortAttr}&limit=${limit}`;
  if (search) {
    params = `${params}&q=${search}`;
  }
  return params;
};

class ContactListAPI {
  getLists = async (accountId, requestParams = {}) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/all/${accountId}${buildContactListParams(
        true,
        requestParams.page,
        requestParams.sortAttr,
        requestParams.limit,
        requestParams.search
      )}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getList = async (
    listId,
    page,
    sortAttr,
    limit = 15,
    search,
    condition
    // payload = []
  ) => {
    let requestURL = `${url}/${listId}${buildContactListParams(
      false,
      page,
      sortAttr,
      limit,
      search
    )}`;

    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      requestURL,
      { condition },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getExcludedContacts = async (
    listId,
    page,
    sortAttr = 'name',
    limit = 15,
    search
  ) => {
    let requestURL = `${url}/exclude/${listId}${buildContactListExcludeParams(
      page,
      sortAttr,
      limit,
      search
    )}`;

    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(requestURL, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getListEntrypoints = async (accountId, listId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      url.replace(
        'contactList',
        `automations/${accountId}/list-entrypoints/${listId}`
      ),
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  createList = async (newList) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(url, newList, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateList = async (listId, list) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/${listId}`, list, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateListByCondition = async (listId, payload) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/${listId}`, payload, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateLists = async (lists) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}`, lists, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  deleteList = async (listId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(`${url}/${listId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  duplicateList = async (listId, copyToList) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}/copy/${listId}`, copyToList, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  mergeList = async (listAId, listBId, name) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/merge`,
      {
        listAId,
        listBId,
        newListParams: {
          name,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  exportList = async (listAId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/export/${listAId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  addContacts = async (listId, contacts) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(
      `${url}/${listId}/contact/add`,
      {
        contacts: contacts,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  deleteContacts = async (listId, contacts) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(
      `${url}/${listId}/contact/delete`,
      {
        contacts: contacts,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  deleteContactFromLists = async (contactId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/contact/${contactId}/delete`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  deleteContactsFromLists = async (contacts, listId = undefined) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(
      `${url}/contacts/delete`,
      {
        contacts,
        listId,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };
}

export default new ContactListAPI();
