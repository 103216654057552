<template>
  <div
    ref="input-counter-container"
    class="input-counter-container"
    :class="{
      'input-counter-container--visible': !isHidden,
    }"
    :style="{
      '--inputPaddingRight': `${inputPaddingRight}px`,
    }"
  >
    <slot />

    <div
      v-show="!isHidden"
      ref="input-counter"
      class="input-counter"
      :style="{
        right: `${right}px`,
        bottom: `${bottom}px`,
      }"
    >
      {{ value ? value.length : 0 }}/{{ maxLength }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputCounter',
  props: {
    value: {
      type: String,
      default: '',
    },

    maxLength: {
      type: Number,
      default: 20,
    },

    inputClass: {
      type: String,
      default: 'formulate-input-wrapper',
    },

    additionalBottomMargin: {
      type: Number,
      default: 0,
    },

    isNotInput: {
      type: Boolean,
      default: false,
    },

    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resizeObserver: null,
      bottom: 0,
      right: 8,
      inputPaddingRight: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const container = this.$refs['input-counter-container'];

      if (!container) {
        return;
      }

      this.resizeObserver = new ResizeObserver(this.onResize);

      this.resizeObserver.observe(container);

      const counter = this.$refs['input-counter'];

      this.inputPaddingRight =
        counter.clientWidth +
        8 +
        Math.round(Math.log(this.maxLength) / Math.log(10)) * 5;
    });
  },
  beforeDestroy() {
    if (!this.resizeObserver) {
      return;
    }

    this.resizeObserver.disconnect();
  },
  methods: {
    onResize(event) {
      const containerHeight = event[0].contentRect.height;

      const inputElement = event[0].target.querySelector(`.${this.inputClass}`);

      const spaceBetweenContainerAndInput =
        containerHeight - inputElement.clientHeight;

      if (this.isNotInput) {
        inputElement.style.paddingRight = `${this.inputPaddingRight}px`;
      }

      this.bottom =
        8 + spaceBetweenContainerAndInput + this.additionalBottomMargin;
    },
  },
};
</script>

<style lang="scss">
.input-counter-container {
  @apply relative;

  &.input-counter-container--visible {
    input,
    textarea {
      padding-right: var(--inputPaddingRight) !important;
    }
  }

  .input-counter {
    @apply absolute text-xxs leading-none text-gray-500;

    right: 0.8rem;
    bottom: 2.4rem;
  }
}
</style>
