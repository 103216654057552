<template>
  <div class="wizard-body h-auto flex-shrink-0 flex-grow-0 xl:pe-64">
    <div v-if="currentUser.type !== 'SuperAdmin'">
      <h3 class="page-header">
        {{
          $t('INBOX_MGMT.ADD.ONLY_SUPER_ADMIN_MESSAGE.TITLE', {
            INBOX_NAME: 'SMS',
          })
        }}
      </h3>

      <p>{{ $t('INBOX_MGMT.ADD.ONLY_SUPER_ADMIN_MESSAGE.MESSAGE') }}</p>
    </div>

    <div v-else>
      <page-header
        :header-title="$t('INBOX_MGMT.ADD.SMS.TITLE')"
        :header-content="$t('INBOX_MGMT.ADD.SMS.DESC')"
      />
      <div class="mb-3">
        <label>
          {{ $t('INBOX_MGMT.ADD.SMS.PROVIDERS.LABEL') }}
          <select v-model="provider">
            <option value="twilio">
              {{ $t('INBOX_MGMT.ADD.SMS.PROVIDERS.TWILIO') }}
            </option>
            <option value="360dialog">
              {{ $t('INBOX_MGMT.ADD.SMS.PROVIDERS.BANDWIDTH') }}
            </option>
          </select>
        </label>
      </div>
      <twilio v-if="provider === 'twilio'" type="sms" />
      <bandwidth-sms v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '../../SettingsSubPageHeader.vue';
import BandwidthSms from './BandwidthSms.vue';
import Twilio from './Twilio.vue';

export default {
  components: {
    PageHeader,
    Twilio,
    BandwidthSms,
  },
  data() {
    return {
      provider: 'twilio',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
};
</script>
