<template>
  <router-link v-slot="{  href, isActive, navigate }" :to="to" custom>
    <a
      v-tooltip.right="$t(`${name}`)"
      :href="href"
      class="text-gray-500 dark:text-gray-300 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-100 hover:text-gray-600 relative transition-colors duration-200"
      :class="{
        'bg-woot-50 dark:bg-woot-800/50 hover:dark:bg-woot-800 text-woot-500 hover:bg-woot-50':
          isActive || isChildMenuActive,
      }"
      :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
      :target="openInNewPage ? '_blank' : undefined"
      @click="navigate"
    >
      <img
        v-if="isCustomIcon"
        :src="`/assets/images/dashboard/menu/${icon}.svg`"
      />
        <fluent-icon
                :icon="icon"
                :class="{
          'text-woot-500 dark:text-woot-400': isActive || isChildMenuActive,
        }"
        />
        <span class="sr-only">{{ name }}</span>
        <span
                v-if="count"
                class="text-gray-900 bg-yellow-500 absolute -top-1 -right-1"
        >
        {{ count }}
      </span>
    </a>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: false,
    },
    isCustomIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
