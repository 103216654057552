import types from '../../mutation-types';
import FoldersAPI from '../../../api/folders';

export const actions = {
  getFolder: async ({ commit }, { clientId, folderId }) => {
    try {
      commit(types.SET_FOLDERS_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_FOLDER);

      const { data } = await FoldersAPI.getFolder(clientId, folderId);

      commit(types.SET_FOLDER, data);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_FOLDERS_UI_FLAGS, { isFetching: false });
    }
  },

  getAllFolders: async ({ commit }, { clientId }) => {
    try {
      commit(types.SET_FOLDERS_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_FOLDERS);

      const { data } = await FoldersAPI.getAllFolders(clientId);

      commit(types.SET_FOLDERS, data);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_FOLDERS_UI_FLAGS, { isFetching: false });
    }
  },

  getFolderEntrypoints: async (
    // eslint-disable-next-line no-empty-pattern
    { commit },
    { accountId, folderId }
  ) => {
    let isSuccess = false;
    try {
      commit(types.CLEAR_FOLDER_ENTRYPOINTS);

      const { data } = await FoldersAPI.getFolderEntrypoints(
        accountId,
        folderId
      );

      if (typeof data?.length !== 'number') {
        throw new Error();
      }

      commit(types.SET_FOLDER_ENTRYPOINTS, data);
      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      // TODO some action
    }

    return isSuccess;
  },

  createFolder: async ({ commit }, { clientId, folder }) => {
    try {
      commit(types.SET_FOLDERS_UI_FLAGS, { isCreating: true });

      await FoldersAPI.createFolder(clientId, folder);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_FOLDERS_UI_FLAGS, { isCreating: false });
    }
  },

  updateFolder: async ({ commit }, { clientId, folder }) => {
    try {
      commit(types.SET_FOLDERS_UI_FLAGS, { isUpdating: true });

      // eslint-disable-next-line no-underscore-dangle
      await FoldersAPI.updateFolder(clientId, folder._id, folder);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_FOLDERS_UI_FLAGS, { isUpdating: false });
    }
  },

  updateFolders: async ({ commit }, { clientId, folders }) => {
    try {
      commit(types.SET_FOLDERS_UI_FLAGS, { isUpdating: true });

      const { data } = await FoldersAPI.updateFolders(clientId, folders);

      // commit(types.SET_FOLDERS, data);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_FOLDERS_UI_FLAGS, { isUpdating: false });
    }
  },

  deleteFolder: async ({ commit }, { clientId, folderId }) => {
    try {
      commit(types.SET_FOLDERS_UI_FLAGS, { isDeleting: true });

      await FoldersAPI.deleteFolder(clientId, folderId);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_FOLDERS_UI_FLAGS, { isDeleting: false });
    }
  },

  setFoldersState: ({ commit }, { state }) => {
    try {
      commit(types.SET_FOLDERS_STATE, state);
    } catch (error) {
      // TODO some action
    }
  },
};
