import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state = {
  records: [],
  record: {},
  state: {},
  meta: {},
  entrypoints: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
