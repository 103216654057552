<template>
  <div class="h-44 pb-4">
    <label class="flex items-center gap-2">
      {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.LIST') }}
      <fluent-icon
        v-tooltip.top="
          $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.LIST_INFO')
        "
        icon="info"
        :size="12"
      />
    </label>
    <multiselect-ch
      v-model="contactList"
      :options="contactLists"
      :multiple="false"
      :max-height="180"
      :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiselectCh from 'shared/components/ui/Multiselect';

export default {
  name: 'EntrypointEditList',
  components: {
    MultiselectCh,
  },
  props: {
    selectedList: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      contactLists: 'contactLists/getAvailableLists',
    }),
    contactList: {
      get() {
        return (
          this.contactLists.find(({ _id }) => _id === this.selectedList) || ''
        );
      },
      set({ _id: listId }) {
        if (listId) {
          this.$emit('update:list', listId);
        }
      },
    },
  },
  async mounted() {
    if (!this.contactLists.length) {
      await this.fetchContactLists();
    }
  },
  methods: {
    async fetchContactLists() {
      await this.$store.dispatch('contactLists/getLists', {
        accountId: this.$route.params.accountId,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
