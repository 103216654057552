import {
  JSON_SEPARATOR,
  DATE_YEAR_FIRST,
  DATE_YEAR_LAST,
  UNIX_TIME,
  ISO_STRING,
} from './constants';
import { isJSONValid } from 'dashboard/helper/commons';

const TYPE_MAPPER = {
  string: 'text',
  number: 'number',
  boolean: 'checkbox',
};

export const isDefinedValue = (value) => value !== undefined && value !== null;

export const getAllPossibleKeys = (json, keyString) => {
  const keyParts = keyString.split(JSON_SEPARATOR).filter(Boolean);
  const result = [];

  const generateKeys = (data, parts, currentKey) => {
    const currentPart = parts[0] || '';
    const objKey = currentPart.replaceAll('"', '');

    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i += 1) {
        generateKeys(data[i], parts.slice(1), `${currentKey}[${i}]`);
      }
    } else if (typeof data === 'object' && data !== null) {
      const needBraces =
        currentPart.startsWith('"') && currentPart.endsWith('"');
      const updatedKey = needBraces
        ? `${currentKey}[${currentPart}]`
        : `${currentKey}.${currentPart}`;
      generateKeys(data[objKey], parts.slice(1), updatedKey);
    } else if (isDefinedValue(data)) {
      // Removing the leading dot
      const key = currentKey.startsWith('.') ? currentKey.slice(1) : currentKey;
      result.push(key);
    }
  };

  generateKeys(json, keyParts, '');
  return result;
};

const getValueRecursive = (obj, keys) => {
  const key = keys.shift() || '';

  if (key === 'i' && Array.isArray(obj)) {
    let value = '';
    for (let i = 0; i < obj.length; i += 1) {
      const isObject = typeof obj[i] === 'object';
      const nestedData = isObject ? obj[i][keys[0]] : obj[i];
      if (isDefinedValue(nestedData)) {
        value =
          keys.length <= 1 ? nestedData : getValueRecursive(obj[i], [...keys]);
      }

      if (value) break;
    }
    return value;
  }
  if (obj && Object.keys(obj).includes(key)) {
    return keys.length === 0
      ? obj[key]
      : getValueRecursive(obj[key], [...keys]);
  }
  return '';
};

export const getValueFromJSON = (jsonObj, path) => {
  const keys = path
    .split(JSON_SEPARATOR)
    .filter(Boolean)
    .map((key) => key.replaceAll('"', ''));
  return getValueRecursive(jsonObj, keys);
};

const isValidDateObject = (date) => {
  const maxPastDate = +new Date('1900');
  const unixTime = +new Date(date);
  return !Number.isNaN(unixTime) && unixTime > maxPastDate;
};

const testDate = (val, initialType = 'text') => {
  if (initialType === 'number') {
    const isUnixTime =
      val.toString().length === 10 || val.toString().length === 13;
    return isUnixTime && isValidDateObject(val) ? 'date' : initialType;
  }
  const matchRegex = val.match(DATE_YEAR_FIRST) || val.match(DATE_YEAR_LAST);
  const validDate = isValidDateObject(val) || matchRegex;
  return validDate ? 'date' : initialType;
};

export const getDataType = (value) => {
  const dataType = TYPE_MAPPER[typeof value];
  return dataType === 'checkbox' ? dataType : testDate(value, dataType);
};

const getDuplicationMap = (keys) => {
  const duplicationMap = {};
  keys.forEach((item, index) => {
    if (keys.indexOf(item) !== index) {
      duplicationMap[item] = keys.filter((key) => key === item).length;
    }
  });
  return duplicationMap;
};

const replaceArrayIndex = (keys) =>
  keys.map((key) => key.replace(/\[\d{1,4}\]/g, '[i]'));

export const getMergeFieldsKeys = (currSelected, prevSelected) => {
  let currKeys = replaceArrayIndex(currSelected);
  const duplicationMap = getDuplicationMap(replaceArrayIndex(prevSelected));

  Object.keys(duplicationMap).forEach((duplicateKey) => {
    const currDuplicationCount = currKeys.filter(
      (key) => key === duplicateKey
    ).length;
    if (duplicationMap[duplicateKey] > currDuplicationCount) {
      currKeys = currKeys.filter((key) => key !== duplicateKey);
    }
  });

  return [...new Set(currKeys)];
};

export const parseJsonInObject = (JSONobj) => {
  const parsedObject = { ...JSONobj };
  Object.keys(parsedObject).forEach((key) => {
    const value = parsedObject[key];
    if (Array.isArray(value)) {
      for (let i = 0; i < parsedObject.length; i += 1) {
        parseJsonInObject(value);
      }
    } else if (value && typeof value === 'object') {
      parsedObject[key] = parseJsonInObject(value);
    } else if (typeof value === 'string') {
      const parsed = isJSONValid(value) ? JSON.parse(value) : value;
      parsedObject[key] = typeof parsed === 'object' ? parsed : value;
    }
  });
  return parsedObject;
};

export const isFormatWithTime = (dateFormat) => {
  const withTime = ['HH:mm', UNIX_TIME, ISO_STRING];
  return withTime.some((format) => dateFormat.includes(format));
};
