<template>
  <div class="message-templates-page relative flex h-full w-full flex-col">
    <message-templates-header
      has-search
      :header-title="headerTitle"
      :search-query="searchQuery"
      :message-template-type="messageTemplateType"
      @change-search-query="changeSearchQuery"
      @toggle-show-create-message-template-modal="
        toggleShowCreateMessageTemplateModal
      "
    />

    <div class="relative flex flex-grow overflow-hidden">
      <message-templates-table
        :message-templates="templates"
        :sort-config="sortConfig"
        :class="wrapClass"
        :message-template-type="messageTemplateType"
        @select-message-template="changeSelectedMessageTemplate"
        @on-sort-change="onSortChange"
        @change-selected-translation-language="
          changeSelectedTranslationLanguage
        "
      />

      <message-template-info-panel
        v-if="showInfoPanel"
        :selected-translation-language="selectedTranslationLanguage"
        :message-template-type="messageTemplateType"
        :is-create-modal-shown="isCreateModalShown"
        @close="hideInfoPanel"
        @delete-template="hideInfoPanel"
        @open-message-template-modal="showEditMessageTemplateModal"
        @close-message-template-modal="closeEditMessageTemplateModal"
        @fetch-message-templates="fetchMessageTemplates"
        @change-selected-translation-language="
          changeSelectedTranslationLanguage
        "
      />
    </div>

    <table-footer
      class="mt-auto"
      :current-page="currentPage"
      :total-count="records.totalCount"
      :page-size="pageSize"
      @page-change="onPageChange"
    />

    <div
      v-if="isLoading"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>

    <woot-modal
      :show.sync="isCreateModalShown"
      :on-close="toggleShowCreateMessageTemplateModal"
      :close-on-backdrop-click="false"
      max-width="100%"
      width="50rem"
      overflow="auto"
    >
      <create-message-template-modal
        v-if="isCreateModalShown"
        :edit-mode="editMode"
        :edit-language="editLanguage"
        :fetch-message-templates="fetchMessageTemplates"
        :preselected-message-template-type="messageTemplateType"
        @close="toggleShowCreateMessageTemplateModal"
      />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { MESSAGE_TEMPLATES_CHANNEL_TYPE } from '../constants';

import MessageTemplatesTable from '../components/MessageTemplatesTable.vue';
import MessageTemplatesHeader from '../components/MessageTemplatesHeader.vue';
import CreateMessageTemplateModal from '../components/CreateMessageTemplateModal.vue';
import MessageTemplateInfoPanel from '../components/MessageTemplateInfoPanel.vue';
import TableFooter from '../../../../components/widgets/TableFooter.vue';

const DEFAULT_PAGE = 1;

const typeSearchQueryTimeout = {
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP]: null,
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS]: null,
};

export default {
  name: 'MessageTemplates',

  components: {
    MessageTemplatesTable,
    MessageTemplatesHeader,
    CreateMessageTemplateModal,
    MessageTemplateInfoPanel,
    TableFooter,
  },

  props: {
    messageTemplateType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      DEFAULT_PAGE,
      MESSAGE_TEMPLATES_CHANNEL_TYPE,
      isCreateModalShown: false,
      editMode: false,
      pageSize: 20,
      editLanguage: '',
      //
      typePage: {
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP]: DEFAULT_PAGE,
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS]: DEFAULT_PAGE,
      },
      typeSearchQuery: {
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP]: '',
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS]: '',
      },
      typeSortConfig: {
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP]: { title: 'asc' },
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS]: { title: 'asc' },
      },
      typeSelectedTranslationLanguage: {
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP]: '',
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS]: '',
      },
      typeSelectedMessageTemplateId: {
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP]: '',
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS]: '',
      },
      typeShowInfoPanel: {
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP]: false,
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS]: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      records: 'messageTemplates/getMessageTemplates',
      record: 'messageTemplates/getMessageTemplate',
      currentAccountId: 'getCurrentAccountId',
      uiFlags: 'messageTemplates/getUIFlags',
    }),

    wrapClass() {
      return this.showInfoPanel ? 'me-[var(--contact-panel-width)]' : '';
    },

    headerTitle() {
      return this.$t(
        `CHATLYN_MESSAGE_TEMPLATE.TITLE.${this.messageTemplateType.toUpperCase()}`
      );
    },

    query() {
      return this.$route.query;
    },

    queryPage: {
      get() {
        return Number(this.query?.page);
      },

      set(page) {
        this.$router.replace({ path: this.$route.path, query: { page } });
      },
    },

    currentPage: {
      get() {
        const selectedPageNumber =
          this.queryPage ||
          this.typePage[this.messageTemplateType] ||
          DEFAULT_PAGE;

        return selectedPageNumber;
      },

      set(page) {
        this.typePage = structuredClone({
          ...this.typePage,
          [this.messageTemplateType]: page,
        });

        this.queryPage = page;
      },
    },

    searchQuery: {
      get() {
        return this.typeSearchQuery[this.messageTemplateType];
      },

      set(newValue) {
        this.typeSearchQuery = structuredClone({
          ...this.typeSearchQuery,
          [this.messageTemplateType]: newValue,
        });
      },
    },

    sortConfig: {
      get() {
        return this.typeSortConfig[this.messageTemplateType];
      },

      set(newValue) {
        this.typeSortConfig = structuredClone({
          ...this.typeSortConfig,
          [this.messageTemplateType]: newValue,
        });
      },
    },

    templates() {
      switch (this.messageTemplateType) {
        case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS:
          return this.records.smsTemplates;

        case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP:
          return this.records.templates;

        default:
          return [];
      }
    },

    selectedTranslationLanguage: {
      get() {
        return this.typeSelectedTranslationLanguage[this.messageTemplateType];
      },

      set(newValue) {
        this.typeSelectedTranslationLanguage = structuredClone({
          ...this.typeSelectedTranslationLanguage,
          [this.messageTemplateType]: newValue,
        });
      },
    },

    showInfoPanel: {
      get() {
        return this.typeShowInfoPanel[this.messageTemplateType];
      },

      set(newValue) {
        this.typeShowInfoPanel = structuredClone({
          ...this.typeShowInfoPanel,
          [this.messageTemplateType]: newValue,
        });
      },
    },

    selectedMessageTemplateId: {
      get() {
        return this.typeSelectedMessageTemplateId[this.messageTemplateType];
      },

      set(newValue) {
        this.typeSelectedMessageTemplateId = structuredClone({
          ...this.typeSelectedMessageTemplateId,
          [this.messageTemplateType]: newValue,
        });
      },
    },

    isLoading() {
      return this.uiFlags.isFetching;
    },
  },

  watch: {
    messageTemplateType: {
      async handler() {
        await this.onTemplateTypeChange();
      },
      immediate: true,
    },

    $route: {
      handler(newValue, prevValue) {
        if (
          !newValue?.name ||
          !prevValue?.name ||
          newValue.name !== prevValue.name ||
          !!newValue.query.page
        ) {
          return;
        }

        this.queryPage = this.currentPage;
      },
      immediate: true,
    },
  },

  methods: {
    async onTemplateTypeChange() {
      if (!Number(this.query?.page)) {
        this.currentPage =
          this.typePage[this.messageTemplateType] || DEFAULT_PAGE;
      }

      await this.fetchMessageTemplates();

      if (this.showInfoPanel) {
        await this.fetchMessageTemplate(this.selectedMessageTemplateId);
      }
    },

    hideInfoPanel(status) {
      this.showInfoPanel = false;
      this.selectedMessageTemplateId = '';

      this.changeSelectedTranslationLanguage('');

      if (status) {
        let page = this.currentPage;

        if (this.templates.length < 2) {
          page = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1;
          this.currentPage = page;
        }

        this.fetchMessageTemplates(page);
      }
    },

    handleShowInfoPanel() {
      this.showInfoPanel = true;
    },

    changeSelectedMessageTemplate(messageTemplateId) {
      this.selectedMessageTemplateId = messageTemplateId;
      this.fetchMessageTemplate(messageTemplateId);
      this.handleShowInfoPanel();
    },

    async fetchMessageTemplates(page) {
      const requestParams = {
        page: page || this.currentPage,
        sortAttr: this.getSortAttribute(),
        search: this.searchQuery,
        limit: this.pageSize,
        // label: this.label,
      };

      switch (this.messageTemplateType) {
        case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS:
          await this.$store.dispatch(
            'messageTemplates/getSmsMessageTemplates',
            {
              accountId: this.currentAccountId,
              requestParams,
            }
          );
          break;

        case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP:
          await this.$store.dispatch('messageTemplates/getMessageTemplates', {
            accountId: this.currentAccountId,
            requestParams,
          });
          break;

        default:
          break;
      }
    },

    async fetchMessageTemplate(messageTemplateId) {
      switch (this.messageTemplateType) {
        case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS:
          await this.$store.dispatch('messageTemplates/getSmsMessageTemplate', {
            templateId: messageTemplateId,
          });
          break;

        case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP:
          await this.$store.dispatch('messageTemplates/getMessageTemplate', {
            templateId: messageTemplateId,
          });
          break;

        default:
          break;
      }
    },

    onSortChange(params) {
      this.sortConfig = params;
      this.fetchMessageTemplates();
    },

    getSortAttribute() {
      let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
        const sortOrder = this.sortConfig[sortKey];

        if (sortOrder) {
          const sortOrderSign = sortOrder === 'asc' ? '' : '-';
          return `${sortOrderSign}${sortKey}`;
        }

        return acc;
      }, '');

      if (!sortAttr) {
        this.sortConfig = { title: 'asc' };
        sortAttr = 'title';
      }

      return sortAttr;
    },

    onPageChange(page) {
      this.currentPage = page;
      this.hideInfoPanel();
      this.fetchMessageTemplates(page);
    },

    toggleShowCreateMessageTemplateModal() {
      this.isCreateModalShown = !this.isCreateModalShown;
      if (!this.isCreateModalShown) {
        this.editLanguage = '';
        this.editMode = false;
      }
    },

    showEditMessageTemplateModal(language) {
      this.editMode = true;
      this.isCreateModalShown = true;
      this.editLanguage = language;
      // this.hideInfoPanel();
    },

    async closeEditMessageTemplateModal() {
      this.isCreateModalShown = false;
      this.editLanguage = '';
      this.editMode = false;
    },

    changeSearchQuery(newValue) {
      this.searchQuery = newValue;

      clearInterval(typeSearchQueryTimeout[this.messageTemplateType]);

      typeSearchQueryTimeout[this.messageTemplateType] = setTimeout(
        async () => {
          if (this.currentPage !== 1) {
            this.currentPage = 1;
          }

          await this.fetchMessageTemplates();
        },
        300
      );
    },

    changeSelectedTranslationLanguage(newValue) {
      this.selectedTranslationLanguage = newValue;
    },
  },
};
</script>

<style lang="scss">
.message-templates-page {
  width: 100%;

  .left-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>
