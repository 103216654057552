<template>
  <div
    v-on-clickaway="isHasOutsideClose ? hideEmojiPicker : () => {}"
    class="tags-tex-area"
  >
    <resizable-text-area
      :id="id.trim() || undefined"
      v-model="transformedText"
      :placeholder="placeholder"
      :is-formulate-form-component="isFormulateFormComponent"
      :validation="validation"
      :validation-messages="validationMessages"
      :validation-rules="validationRules"
      :min-height="minHeight"
      :max-length="maxLength || undefined"
    >
      <template #footer>
        <slot name="footer" />
      </template>
    </resizable-text-area>

    <transition name="fade">
      <emoji-input
        v-if="showEmojiPicker"
        :on-click="emojiOnClick"
        class="emoji-dialog--expanded"
      />
    </transition>
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import tagsMixin from './mixins/tagsMixin';

import ResizableTextArea from '../../../shared/components/ResizableTextArea.vue';

const EmojiInput = () => import('shared/components/emoji/EmojiInput');

export default {
  name: 'TagsTextArea',
  components: { ResizableTextArea, EmojiInput },
  mixins: [clickaway, tagsMixin],
  props: {
    id: {
      type: String,
      default: 'tags-text-area',
    },

    value: {
      type: String,
      default: '',
    },

    tags: {
      type: Array,
      required: true,
    },

    placeholder: {
      type: String,
      default: '',
    },

    isFormulateFormComponent: {
      type: Boolean,
      default: false,
    },

    validation: {
      type: String,
      default: '',
    },

    validationMessages: {
      type: [Object, undefined],
      default: undefined,
    },

    validationRules: {
      type: [Object, undefined],
      default: undefined,
    },

    maxLength: {
      type: Number,
      default: 1024,
    },

    emojiPicker: {
      type: Boolean,
      default: false,
    },

    showEmojiPicker: {
      type: Boolean,
      default: false,
    },

    minHeight: {
      type: [Number, undefined],
      default: undefined,
    },

    isHasOutsideClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    transformedText: {
      get() {
        return this.transformTextToTags(this.value);
      },
      set(newValue) {
        this.$emit('input', this.transformTagsToText(newValue));
      },
    },
  },
  methods: {
    emojiOnClick(emoji) {
      const element = document.querySelector(`#${this.id}`);

      if (!element) {
        return;
      }

      this.insertSymbol(
        emoji,
        element.selectionStart,
        element.selectionEnd,
        element
      );
    },

    insertTag() {
      const element = document.querySelector(`#${this.id}`);

      if (!element || this.contOfTags >= this.tags.length) {
        return;
      }

      this.insertSymbol(
        this.tags[this.contOfTags],
        element.selectionStart,
        element.selectionEnd,
        element
      );
    },

    insertSymbol(symbol, selectionStart, selectionEnd, element) {
      const newText =
        this.transformedText.slice(0, selectionStart) +
        symbol +
        this.transformedText.slice(selectionEnd, this.transformedText.length);

      this.transformedText = newText;

      if (element) {
        this.$nextTick(() => {
          element.focus();
          element.setSelectionRange(selectionStart, selectionEnd);
        });
      }
    },

    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.$emit('hide-emoji-picker');
      }
    },
  },
};
</script>

<style lang="scss"></style>
