<template>
  <div class="message-template-form-switch-container">
    <woot-switch
      :id="`message-template-form-show-FOOTER-${currentTranslation.language}-switch`"
      v-model="showFooter"
      disabled-color="var(--gray-200)"
      :label="$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.FOOTER')"
      optional-text="(optional)"
    />

    <slide-animation :is-shown="showFooter">
      <div v-if="showFooter" class="mt-2">
        <input-with-translation
          v-if="footerComponent"
          v-model="footerComponent.text"
          is-formulate-form-component
          type="text"
          validation="required|noEmoji"
          :validation-messages="{
            noEmoji: $t('CHATLYN_GENERAL.MESSAGES.NO_EMOJI_INVALID'),
          }"
          :validation-rules="{
            noEmoji: noEmoji,
          }"
          :placeholder="$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.FOOTER_INFO')"
          :max-length="60"
          :preferred-language="currentTranslation.language"
          :disable-translation="!isTranslatable"
        />
      </div>
    </slide-animation>
  </div>
</template>

<script>
import WootSwitch from 'shared/components/ui/Switch.vue';
import InputWithTranslation from '../../../../../../../components/widgets/conversation/translator/InputWithTranslation.vue';
import SlideAnimation from '../../../../../../../components/animations/SlideAnimation.vue';
import { noEmoji } from '../../../../../../../helper/formulateValidation';

export default {
  name: 'LanguageFormFooterEditor',
  components: {
    WootSwitch,
    InputWithTranslation,
    SlideAnimation,
  },
  props: {
    currentTranslation: {
      type: Object,
      required: true,
    },

    isTranslatable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showFooter: {
      get() {
        return this.currentTranslation.components.some(
          (component) => component.type === 'FOOTER'
        );
      },
      set(newValue) {
        let newComponents = structuredClone(this.currentTranslation.components);

        if (newValue) {
          newComponents.push({
            type: 'FOOTER',
            text: '',
          });
        } else {
          newComponents = newComponents.filter(
            (component) => component.type !== 'FOOTER'
          );
        }

        this.changeCurrentTranslation({
          ...this.currentTranslation,
          components: newComponents,
        });
      },
    },

    footerComponent: {
      get() {
        return this.currentTranslation.components.find(
          (component) => component.type === 'FOOTER'
        );
      },
      set(newValue) {
        this.changeCurrentTranslation({
          ...this.currentTranslation,
          components: this.currentTranslation.components.map((component) =>
            component.type === 'FOOTER' ? newValue : component
          ),
        });
      },
    },
  },
  methods: {
    noEmoji,

    changeCurrentTranslation(newValue) {
      this.$emit('change-current-translation', newValue);
    },
  },
};
</script>

<style></style>
