/* global axios */
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? 'https://staging.chatlyn.com/wechatmw'
    : window.location.protocol + '//' + window.location.hostname + '/wechatmw';

class WeChatAPI {
  getWeChatInbox = async inboxId => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/chatlyn/inbox/${inboxId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  createWeChatInbox = async inbox => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}/chatlyn/inbox`, inbox, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateWeChatInbox = async (inboxId, data) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/chatlyn/inbox/${inboxId}`, data, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  deleteWeChatInbox = async inboxId => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(`${url}/chatlyn/inbox/${inboxId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };
}

export default new WeChatAPI();
