/* global axios */
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? 'https://staging.chatlyn.com/smswamw/files/upload'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/smswamw/files/upload';

class FileUpload {
  uploadFile = async file => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    let formData = new FormData();
    formData.append('file', file);

    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };
}

export default new FileUpload();
