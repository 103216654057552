var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-view relative flex flex-row flex-nowrap items-center gap-1",class:{
    'edit-mode': _vm.isEditMode,
  }},[_c('span',[_c('button',{staticClass:"translator-button cursor-pointer p-0 text-xxs",style:({
        color: _vm.color,
      }),attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('button-action')}}},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")])]),_vm._v(" "),_c('span',{staticClass:"language-select-container relative h-3 w-3"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedTargetLanguage),expression:"selectedTargetLanguage"}],staticClass:"language-select mb-0 h-full w-full cursor-pointer border-none bg-transparent bg-none p-0 pl-2 pr-1 focus:bg-transparent",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedTargetLanguage=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.languages),function(targetLanguage){return _c('option',{key:targetLanguage.value,class:{
          selected: targetLanguage.value === _vm.selectedTargetLanguage,
        },domProps:{"value":targetLanguage.value}},[_vm._v("\n        "+_vm._s(targetLanguage.label)+"\n      ")])}),0),_vm._v(" "),_c('fluent-icon',{staticClass:"language-select-icon max-w-3 pointer-events-none absolute left-0 top-0 h-full max-h-3 w-full",attrs:{"icon":"caret-down","size":"12","color":_vm.color,"type":"solid"}})],1),_vm._v(" "),_c('chatlyn-spinner',{staticClass:"language-select__spinner",attrs:{"is-visible":_vm.isLoading && !_vm.isEditMode}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }