import { format } from 'date-fns';

export const formatSequence = (acc, val, index, array) => {
  if (index === 0 || array[index - 1] !== val - 1) {
    return acc + ',' + val.toString();
  }
  if (index < array.length - 1 && array[index + 1] !== val + 1) {
    return acc + '-' + val.toString();
  }
  if (index === array.length - 1) {
    return acc + '-' + val.toString();
  }
  return acc;
};

export const parseSequence = (sequence) => {
  return sequence.split(',').flatMap((part) => {
    let [start, end] = part.split('-').map(Number);

    if (end) {
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }

    return start;
  });
};

export const condense = (array) => {
  return array.reduce(formatSequence, '').substring(1);
};

export const generateArray = (length) =>
  Array.from({ length }, (_, i) => (i < 10 ? `0${i}` : `${i}`));

export const formatExecution = (execution) => {
  return format(
    execution,
    '\u00A0\u00A0\u00A0\u00A0yyyy-MM-dd\u00A0\u00A0\u00A0\u00A0HH:mm:ss'
  );
};

export const getExecutionMessage = (index) => {
  return index === 0
    ? 'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.NEXT_AT'
    : 'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.SCHEDULE.CALC.THEN_AT';
};

export const calcExecution = (interval, index) => {
  const nextExecution = new Date(interval.next().toString());
  const formattedExecution = formatExecution(nextExecution);
  const executionMessage = getExecutionMessage(index);

  return {
    message: executionMessage,
    date: formattedExecution,
  };
};
