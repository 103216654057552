import { XMLValidator, XMLParser, XMLBuilder } from 'fast-xml-parser';

// custom linter for codemirror
export const xmlParseLinter = () => (view) => {
  const { doc } = view.state;
  const validated = XMLValidator.validate(doc.toString());
  if (!validated?.err || !doc.length) {
    return [];
  }
  const { msg, col, line } = validated.err;
  const message = msg + ` (line ${line} column ${col})`;
  const position = Math.min(doc.line(line).from + col - 1, doc.length);
  return [
    {
      from: position,
      message,
      severity: 'error',
      to: position,
    },
  ];
};
export const isXMLValid = (value) => {
  const validated = XMLValidator.validate(value);
  return !validated?.err;
};

export const parseXML = (value) => {
  const parser = new XMLParser();
  return parser.parse(value);
};

const buildXML = (value) => {
  const builder = new XMLBuilder({ format: true });
  return builder.build(value);
};

export const getMultilineXML = (value) => {
  const parsed = parseXML(value);
  return buildXML(parsed);
};
