<template>
  <div class="flex items-center">
    <input
      :id="id"
      v-model="computedValue"
      class="checkbox-switch"
      type="checkbox"
      role="switch"
      :checked="computedValue"
      :disabled="disabled"
      @change="switchValue"
    />

    <label v-if="label" class="checkbox-switch-label" :for="id">
      {{ label }}

      <span
        v-if="optionalText"
        class="-ms-1 text-gray-500 dark:text-gray-400"
        :for="id"
      >
        {{ optionalText }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ChSwitch',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    optionalText: {
      type: String,
      default: '',
    },

    id: {
      type: String,
      default: 'switch',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    disabledColor: {
      type: String,
      default: 'blue',
    },

    enabledColor: {
      type: String,
      default: 'red',
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
  methods: {
    switchValue() {
      this.$emit('change', !this.computedValue);
    },
  },
};
</script>

<style scoped lang="scss">
input[type='checkbox'].checkbox-switch {
  position: absolute;
  left: -9999px;
  visibility: hidden;
  cursor: pointer;
}

label.checkbox-switch-label {
  @apply relative flex flex-grow cursor-pointer items-center gap-2 text-gray-700 dark:text-gray-100;

  &:before {
    content: '';
    @apply inline-block h-4 w-8 rounded-full border border-gray-300 bg-transparent dark:border-gray-600;
  }

  &:after {
    content: '';
    @apply absolute my-0.5 ms-1 h-2.5 w-2.5 rounded-full bg-gray-400 transition-transform duration-200;
  }
}

input.checkbox-switch {
  visibility: hidden;
  &:checked + label.checkbox-switch-label:after {
    @apply translate-x-3.5 bg-white;
  }
  &:checked + label.checkbox-switch-label:before {
    @apply border border-woot-600 bg-woot-500;
  }
}
</style>
