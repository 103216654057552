/* global axios */
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? 'https://staging.chatlyn.com/gbmmw'
    : window.location.protocol + '//' + window.location.hostname + '/gbmmw';

class GoogleBusinessMessagesAPI {
  getGoogleBusinessMessagesInbox = async (inboxId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/chatlyn/inbox/${inboxId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  createGoogleBusinessMessagesInbox = async (inbox) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}/chatlyn/inbox`, inbox, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateGoogleBusinessMessagesInbox = async (inboxId, data) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/chatlyn/inbox/${inboxId}`, data, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  // DEPRECATED
  // deleteGoogleBusinessMessagesInbox = async (inboxId) => {
  //   const jwtAuthTokens = await JwtAuth.getUserTokens();

  //   return axios.delete(`${url}/chatlyn/inbox/${inboxId}`, {
  //     headers: {
  //       Authorization: `Bearer ${jwtAuthTokens.access_token}`,
  //     },
  //   });
  // };
}

export default new GoogleBusinessMessagesAPI();
