<template>
  <div>
    <formulate-input
      :value="localConfig.exchangeGrantType"
      :label="$t('CHATLYN_CREDENTIALS.LABELS.GRANT_TYPE')"
      :options="grantTypes"
      disabled
      name="exchange-grant-type"
      type="select"
      validation="required"
    />
    <formulate-input
      v-model="localConfig.authorizationUrl"
      :label="$t('CHATLYN_CREDENTIALS.LABELS.AUTHORIZATION_URL')"
      name="authorization-url"
      validation="required"
    />
    <formulate-input
      v-model="localConfig.exchangeUrl"
      :label="$t('CHATLYN_CREDENTIALS.LABELS.EXCHANGE_URL')"
      name="exchange-url"
      validation="required"
    />
    <formulate-input
      v-model="localConfig.scope"
      :label="$t('CHATLYN_CREDENTIALS.LABELS.SCOPE')"
      name="scope"
      validation="required"
    />
    <formulate-input
      v-model="localConfig.client_id"
      :label="$t('CHATLYN_CREDENTIALS.LABELS.CLIENT_ID')"
      name="client-id"
      validation="required"
    />
    <formulate-input
      v-model="localConfig.client_secret"
      :label="$t('CHATLYN_CREDENTIALS.LABELS.CLIENT_SECRET')"
      autocomplete="new-password"
      type="password"
      name="client-secret"
      validation="required"
    />
    <div class="edit-credential-form-block">
      <div
        class="flex cursor-pointer items-center justify-between text-sm"
        @click="toggleShowAdvanced"
      >
        {{ $t('CHATLYN_CREDENTIALS.CONFIG_PAGE.ADVANCED_SUBTITLE') }}
        <fluent-icon
          :icon="showAdvanced ? 'chevron-up' : 'chevron-down'"
          :size="16"
          class="text-gray-500"
        />
      </div>
      <div v-if="showAdvanced" class="mt-4">
        <woot-switch
          id="refresh-auth-switch"
          v-model="localConfig.refreshAutomatically"
          :label="$t('CHATLYN_CREDENTIALS.LABELS.REFRESH_AUTOMATICALLY')"
          class="mb-2.5"
          size="small"
        />
        <template v-if="localConfig.refreshAutomatically">
          <formulate-input
            v-model="localConfig.refreshOn"
            :label="$t('CHATLYN_CREDENTIALS.LABELS.REFRESH_ON')"
            :options="refreshTypes"
            :validation="localConfig.refreshAutomatically ? 'required' : ''"
            name="refresh-on"
            type="select"
          />
          <formulate-input
            v-if="localConfig.refreshOn === 'period'"
            v-model="localConfig.refreshPeriod"
            :label="$t('CHATLYN_CREDENTIALS.LABELS.REFRESH_PERIOD')"
            :placeholder="$t('CHATLYN_CREDENTIALS.PLACEHOLDERS.REFRESH_PERIOD')"
            :validation="
              localConfig.refreshAutomatically ? 'required|min:60' : ''
            "
            min="60"
            name="refresh-period"
            type="number"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import WootSwitch from 'shared/components/ui/Switch';
export default {
  name: 'CredentialEditOAuth',
  components: {
    WootSwitch,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.config },
      showAdvanced: false,
    };
  },
  computed: {
    grantTypes() {
      return [
        {
          label: this.$t('CHATLYN_CREDENTIALS.GRANT_TYPES.AUTH_CODE'),
          value: 'authorization_code',
        },
      ];
    },
    isDirty() {
      return JSON.stringify(this.config) !== JSON.stringify(this.localConfig);
    },
    refreshAutomatically() {
      return this.localConfig.refreshAutomatically;
    },
    refreshTypes() {
      return [
        {
          label: this.$t('CHATLYN_CREDENTIALS.REFRESH_TYPES.TRIGGER'),
          value: 'trigger',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.REFRESH_TYPES.EXPIRY'),
          value: 'expiry',
        },
        {
          label: this.$t('CHATLYN_CREDENTIALS.REFRESH_TYPES.PERIOD'),
          value: 'period',
        },
      ];
    },
  },
  watch: {
    config(val) {
      this.localConfig = { ...val };
    },
    localConfig: {
      handler(val) {
        if (this.isDirty) {
          this.$emit('update-oauth', { ...val });
        }
      },
      deep: true,
    },
    refreshAutomatically(val) {
      if (!val) {
        this.localConfig.refreshOn = null;
        this.localConfig.refreshPeriod = null;
      }
    },
  },
  methods: {
    toggleShowAdvanced() {
      this.showAdvanced = !this.showAdvanced;
    },
  },
};
</script>

<style lang="scss" scoped></style>
