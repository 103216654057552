var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"callout warning flex flex-row items-center gap-2 rounded p-2 text-xs"},[_c('span',{staticClass:"inline-flex p-0.5"},[_c('fluent-icon',{attrs:{"icon":"warning","size":16}})],1),_vm._v(" "),_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
      _vm.$t(
        'CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SENDING_LIMIT_WARNING.MESSAGE',
        {
          LIMIT: _vm.currentSendingLimits,
          LINK: 'https://academy.chatlyn.com/en/articles/whatsapp-sending-limits/',
        }
      )
    ),expression:"\n      $t(\n        'CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SENDING_LIMIT_WARNING.MESSAGE',\n        {\n          LIMIT: currentSendingLimits,\n          LINK: 'https://academy.chatlyn.com/en/articles/whatsapp-sending-limits/',\n        }\n      )\n    "}],staticClass:"inline-flex flex-col gap-0.5"})])
}
var staticRenderFns = []

export { render, staticRenderFns }