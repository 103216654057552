// import Vue from 'vue';
import types from '../../mutation-types';

export const mutations = {
  [types.SET_MESSAGE_TEMPLATES](state, payload) {
    state.records = payload;
  },
  [types.SET_MESSAGE_TEMPLATES_NAME](state, payload) {
    state.templateNames = payload;
  },
  [types.CLEAR_MESSAGE_TEMPLATES](state) {
    state.records = {
      templates: [],
      totalCount: 0,
      totalPages: 0,
    };
  },

  [types.SET_MESSAGE_TEMPLATES_META](state, payload) {
    state.meta = payload;
  },
  [types.CLEAR_MESSAGE_TEMPLATES_META](state) {
    state.meta = {
      count: 0,
      currentPage: 1,
    };
  },

  [types.SET_MESSAGE_TEMPLATE](state, payload) {
    if (state.records.templates) {
      state.records.templates = state.records.templates.map((record) => {
        // eslint-disable-next-line no-underscore-dangle
        if (payload._id === record._id) {
          return payload;
        }

        return record;
      });
    } else {
      state.records.smsTemplates = state.records.smsTemplates.map((record) => {
        // eslint-disable-next-line no-underscore-dangle
        if (payload._id === record._id) {
          return payload;
        }

        return record;
      });
    }

    state.record = payload;
  },
  [types.CLEAR_MESSAGE_TEMPLATE](state) {
    state.record = null;
  },

  [types.SET_MESSAGE_TEMPLATE_COUNT](state, payload) {
    state.templatesCount = payload;
  },
  [types.CLEAR_MESSAGE_TEMPLATE_COUNT](state) {
    state.templatesCount = null;
  },

  [types.SET_MESSAGE_TEMPLATE_ENTRYPOINTS](state, payload) {
    state.entrypoints = payload;
  },
  [types.CLEAR_MESSAGE_TEMPLATE_ENTRYPOINTS](state) {
    state.entrypoints = [];
  },

  [types.SET_MESSAGE_TEMPLATE_UI_FLAGS](state, payload) {
    state.uiFlags = { ...state.uiFlags, ...payload };
  },

  [types.SET_SCHEDULED_MESSAGE_TEMPLATES](state, payload) {
    state.scheduledRecords = payload;
  },
  [types.SET_SCHEDULED_MESSAGE_TEMPLATE](state, payload) {
    state.scheduledRecord = payload;
  },

  [types.CLEAR_SCHEDULED_MESSAGE_TEMPLATES](state) {
    state.scheduledRecords = [];
  },
  [types.CLEAR_SCHEDULED_MESSAGE_TEMPLATE](state) {
    state.scheduledRecord = null;
  },
};
